import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Toggle from "new_components/atoms/Toggle"; // Import the Toggle component
import Text from "new_components/atoms/Text"; // Import the Text component
import colors from "styles/colors"; // Import colors for styling
import typography from "styles/typography";

const ToggleItemWrapper = styled.div`
  display: flex;
  align-items: flex-start; /* Align items to the top for proper positioning */
  gap: 12px; /* Space between toggle and text */
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px; /* Space between title and description */
`;

const ToggleItem = ({ isActive, onToggle, title, description }) => {
  return (
    <ToggleItemWrapper>
      {/* Toggle Switch */}
      <Toggle isActive={isActive} onToggle={onToggle} />

      {/* Text Content */}
      <TextContainer>
        <Text
          typographyStyle={typography.caption.semibold} // Caption/Semibold
          color={colors.text.dark}
        >
          {title}
        </Text>
        {description && (
          <Text
            typographyStyle={typography.caption.regular} // Caption/Regular
            color={colors.text.gray}
          >
            {description}
          </Text>
        )}
      </TextContainer>
    </ToggleItemWrapper>
  );
};

ToggleItem.propTypes = {
  isActive: PropTypes.bool.isRequired, // Whether the toggle is active
  onToggle: PropTypes.func.isRequired, // Callback for toggle change
  title: PropTypes.string.isRequired, // Bolded title text
  description: PropTypes.string, // Description below the title
};

export default ToggleItem;
