import React, { useState, useRef } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block; /* Ensures it wraps around the child element */
`;

const TooltipContent = styled.div`
  position: absolute;
  background: ${colors.text.gray};
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 9999; /* High z-index to ensure it appears above all elements */
  white-space: nowrap;
`;

const Triangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
`;

const Tooltip = ({ children, tooltipText }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [positionStyle, setPositionStyle] = useState({});
  const [triangleStyle, setTriangleStyle] = useState({});
  const [position, setPosition] = useState("top");

  const wrapperRef = useRef(null);

  const handleMouseOver = () => {
    setIsHovered(true);

    const bounding = wrapperRef.current.getBoundingClientRect();
    const tooltipHeight = 40; // Approximate height of the tooltip

    const isTooLow = bounding.bottom + tooltipHeight > window.innerHeight;
    const isTooHigh = bounding.top - tooltipHeight < 0;
    const newPosition = isTooLow ? "top" : isTooHigh ? "bottom" : "top";
    setPosition(newPosition);

    // Compute position
    const top =
      newPosition === "top"
        ? bounding.top + window.scrollY - tooltipHeight - 8
        : bounding.bottom + window.scrollY + 8;
    const left = bounding.left + bounding.width / 2 + window.scrollX;

    setPositionStyle({
      top: `${top}px`,
      left: `${left}px`,
      transform: "translateX(-50%)",
    });

    setTriangleStyle({
      left: "50%",
      transform: "translateX(-50%)",
      ...(newPosition === "top"
        ? {
            bottom: "-8px",
            borderWidth: "8px 8px 0 8px",
            borderColor: `${colors.text.gray} transparent transparent transparent`,
          }
        : {
            top: "-8px",
            borderWidth: "0 8px 8px 8px",
            borderColor: `transparent transparent ${colors.text.gray} transparent`,
          }),
    });
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  return (
    <TooltipWrapper
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      ref={wrapperRef}
    >
      {children}
      {isHovered &&
        ReactDOM.createPortal(
          <TooltipContent style={positionStyle}>
            <Triangle style={triangleStyle} />
            <Text
              typographyStyle={typography.smallText.regular}
              color={colors.background.white}
            >
              {tooltipText}
            </Text>
          </TooltipContent>,
          document.body
        )}
    </TooltipWrapper>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

export default Tooltip;
