import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import Icon from "new_components/atoms/Icon"; // Using your existing Icon atom
import Text from "new_components/atoms/Text"; // Using your existing Text atom
import colors from "styles/colors";

const StyledImageButton = styled.div`
  ${({ textStyle, color, isDropdown }) =>
    textStyle
      ? css`
          display: inline-flex;
          align-items: center;
          justify-content: space-between;
          gap: ${isDropdown ? "8px" : "4px"};
          background-color: transparent;
          border: none;
          color: ${color || colors.primary[500]};
          cursor: pointer;
          padding: ${isDropdown
            ? "0 8px"
            : "0"}; /* Dropdown-specific padding */
          width: ${isDropdown ? "100%" : "auto"};
          &:disabled {
            color: ${colors.neutral[500]};
            cursor: not-allowed;
          }
        `
      : css`
          display: inline-flex;
          align-items: center;
          gap: 12px;
          color: ${color || "inherit"};
        `}
`;

const DropdownTextWrapper = styled.div`
  flex: 1; /* Allow the text to take up remaining space */
  text-align: left; /* Ensure left alignment for dropdowns */
`;

const ImageButton = ({
  text,
  icon,
  iconPosition = "left",
  textStyle = false, // Determines if the button is styled as text
  isDropdown = false, // New prop for dropdown-specific styling
  disabled = false,
  color, // Pass color to text and icon
  onClick,
  iconSize = 20,
  iconProps,
}) => {
  return (
    <StyledImageButton
      as="button"
      textStyle={textStyle}
      isDropdown={isDropdown}
      color={color}
      onClick={onClick}
      disabled={disabled}
    >
      {/* Icon on the left */}
      {icon && iconPosition === "left" && (
        <Icon
          name={icon}
          size={iconSize}
          color={color}
          stroke={color}
          {...iconProps}
        />
      )}

      {/* Text */}
      <DropdownTextWrapper>
        <Text
          color={color}
          typographyStyle="caption.semibold" // Retaining your typography prop usage
        >
          {text}
        </Text>
      </DropdownTextWrapper>

      {/* Icon on the right */}
      {icon && iconPosition === "right" && (
        <Icon
          name={icon}
          size={iconSize}
          color={color}
          stroke={color}
          {...iconProps}
        />
      )}
    </StyledImageButton>
  );
};

ImageButton.propTypes = {
  text: PropTypes.string.isRequired, // Button text
  icon: PropTypes.string, // Icon name
  iconPosition: PropTypes.oneOf(["left", "right"]), // Position of the icon
  textStyle: PropTypes.bool, // Renders as text-style button
  isDropdown: PropTypes.bool, // Dropdown-specific styles
  disabled: PropTypes.bool, // Disabled state
  color: PropTypes.string, // Custom color
  onClick: PropTypes.func, // Click handler
  iconSize: PropTypes.number, // Icon size
  iconProps: PropTypes.object, // Additional props for the icon
};

export default ImageButton;