import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "new_components/atoms/Text";
import colors from "styles/colors";
import typography from "styles/typography";

export const HeaderCell = styled.th`
  text-align: left;
  padding: 0.75rem;
  position: relative;
  cursor: ${({ isSortable }) => (isSortable ? "pointer" : "default")};
  color: ${colors.text.gray};
`;

const HeaderContent = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 15px; /* Space between text and icons */
`;

const SortIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 10px;
    height: 10px;
    color: ${({ active }) =>
      active ? colors.primary[500] : colors.neutral[600]};
  }
`;

const SortArrow = styled.span`
  transform: ${({ active }) => (active ? "scale(1)" : "scale(0.8)")};
  color: ${({ active }) =>
    active ? colors.primary[500] : colors.neutral[600]};
  transition: transform 0.2s ease, color 0.2s ease;
`;

const TableHeader = ({ label, isSortable, sortDirection, onSort }) => {
  return (
    <HeaderCell
      isSortable={isSortable}
      onClick={isSortable ? onSort : undefined}
    >
      <HeaderContent>
        <Text
          typographyStyle={typography.body.semibold}
          color={colors.text.gray}
        >
          {label}
        </Text>
        {isSortable && (
          <SortIcons>
            <SortArrow active={sortDirection === "asc"}>▲</SortArrow>
            <SortArrow active={sortDirection === "desc"}>▼</SortArrow>
          </SortIcons>
        )}
      </HeaderContent>
    </HeaderCell>
  );
};

TableHeader.propTypes = {
  label: PropTypes.string.isRequired,
  isSortable: PropTypes.bool,
  sortDirection: PropTypes.oneOf(["asc", "desc", null]),
  onSort: PropTypes.func,
};

export default TableHeader;
