import { useSelector } from "react-redux";
import { getColorTheme } from "./colorTheme";

// Fallback default (light theme)
let currentColors = getColorTheme(false);

// Dynamic updater for colors based on Redux state
export const useColors = () => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  currentColors = getColorTheme(darkMode); // Update currentColors dynamically
  return currentColors;
};

// Static export of colors (defaults to light theme)
const colors = currentColors;

export default colors;
