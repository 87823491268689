// Desc: UserSearchProvider is a context provider that provides the user list to the search components that need it.
// Since the searchbar is used in multiple components, it is better to have a single source of truth for the user list.
import React, { createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";

const UserSearchContext = createContext();

export const useUserSearchContext = () => useContext(UserSearchContext);

export const UserSearchProvider = ({ children }) => {
  const { get_employee_org_category, get_employees } = useSelector(
    (state) => ({
      get_employee_org_category: state.employee_category.employee_org_category,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  const userMap = (usersList, employeeList) => {
    if (!usersList || !employeeList) return [];
    const list = usersList?.map((user) => {
      const employeeObj = employeeList?.employees?.find(
        (emp) => emp.id === user?.employee_id
      );

      return {
        key: user?.employee_id,
        value: user?.employee_id,
        text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
        id: user?.id,
      };
    });

    const empList = employeeList?.employees;
    const inactiveList = empList?.filter(
      (emp) => emp.status === 99 || emp.status === 98 || emp.status === 108
    );

    const enabledLogins = empList?.filter((emp) => emp.enable_login === true);

    const filteredList = list
      .filter(
        (emp) => !inactiveList.find((inactive) => inactive.id === emp.key)
      )
      ?.filter((emp) =>
        enabledLogins.find((enabled) => enabled.id === emp.key)
      );

    filteredList.unshift({ key: -1, value: -1, text: "Select User" });
    return filteredList;
  };

  const value = useMemo(() => {
    const userList = userMap(get_employee_org_category, get_employees);
    return { userList };
  }, [get_employee_org_category, get_employees]);

  return (
    <UserSearchContext.Provider value={value}>
      {children}
    </UserSearchContext.Provider>
  );
};

UserSearchProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
