import {
    DEBRIEF_RESPONSE_VECTOR_DB_PENDING,
    GET_DEBRIEF_RESPONSE_VECTOR_DB,
    FETCH_DEBRIEF_RESPONSE_VECTOR_DB,
    CLEAR_DEBRIEF_RESPONSE_VECTOR_DB,
  } from "constants/actions";
  
  const initialState = {
    debrief_response_vector: [],
    pending: false,
  };
  
  export default function DebriefResponseVector(state = initialState, action) {
    switch (action.type) {
      case DEBRIEF_RESPONSE_VECTOR_DB_PENDING:
        return {
          ...state,
          pending: true,
        };
  
      case GET_DEBRIEF_RESPONSE_VECTOR_DB:
        return {
          ...state,
          debrief_response_vector: action.payload,
          pending: false,
        };
  
      case FETCH_DEBRIEF_RESPONSE_VECTOR_DB:
        return {
          ...state,
          [action.payload.id]: action.payload,
          pending: false,
        };
  
      case CLEAR_DEBRIEF_RESPONSE_VECTOR_DB:
        return {
          ...state,
          debrief_response_vector: [],
        };
  
      default:
        return state;
    }
  }