import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import TextInput from "new_components/molecules/TextInput";
import ToggleItem from "new_components/molecules/ToggleItem";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const NameAndDescription = ({
  data,
  onUpdate,
  draftData,
  toggleAnonymity,
  errors,
}) => {
  const [surveyName, setSurveyName] = useState(data.name);
  const [surveyDescription, setSurveyDescription] = useState(data.description);

  const [anonymizeData, setAnonymizeData] = useState(data.survey_is_anonymous);

  useEffect(() => {
    onUpdate({
      name: surveyName,
      description: surveyDescription,
    });

    toggleAnonymity(anonymizeData);
  }, [surveyName, surveyDescription, anonymizeData]);

  useEffect(() => {
    if (draftData && draftData?.name) {
      setSurveyName(draftData?.name);
      setSurveyDescription(draftData?.description);

      setAnonymizeData(draftData?.survey_is_anonymous);

      onUpdate({
        name: draftData?.name,
        description: draftData?.description,
      });

      toggleAnonymity(draftData?.survey_is_anonymous);
    }
  }, [draftData]);

  const handleChange = (field, value) => {
    onUpdate({ ...data, [field]: value });
  };

  const handleAnonymize = (value) => {
    setAnonymizeData(value);
    toggleAnonymity(value);
  };

  return (
    <SectionWrapper>
      {/* Name Field */}
      <FieldWrapper>
        <TextInput
          label="Name of the survey"
          value={surveyName}
          onChange={(value) => handleChange("name", value)} // Accepting value directly
          placeholder="Enter the name of the survey"
          error={errors?.surveyNameValidation}
        />
      </FieldWrapper>

      {/* Description Field */}
      <FieldWrapper>
        <TextInput
          label="Description"
          value={surveyDescription}
          error={errors?.surveyDescriptionValidation}
          onChange={(value) => handleChange("description", value)} // Accepting value directly
          placeholder="Enter a description"
        />
      </FieldWrapper>
      {/* Anonymization toggle */}
      <ToggleItem
        title={
          !anonymizeData
            ? "This survey is not anonymous"
            : "This survey is anonymous"
        }
        isActive={anonymizeData}
        onToggle={(value) => {
          handleAnonymize(value);
        }}
      />
    </SectionWrapper>
  );
};

NameAndDescription.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    survey_is_anonymous: PropTypes.bool,
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default NameAndDescription;
