// Template used for things like the empty state & builders
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import BaseTemplate from "./BaseTemplate";

// Wrapper for the centered content
// Should center the content horizontally and vertically
const CenteredPageContentWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

const CenterPageTemplate = ({ children, heading, pageTitle }) => {
  return (
    <BaseTemplate heading={heading} pageTitle={pageTitle}>
      <CenteredPageContentWrapper>{children}</CenteredPageContentWrapper>
    </BaseTemplate>
  );
};

CenterPageTemplate.propTypes = {
  children: PropTypes.node, // Content passed into the layout
  heading: PropTypes.string, // Heading text for the base layout
  pageTitle: PropTypes.string, // Page title for the base layout
};

export default CenterPageTemplate;
