import React from "react";
import styled from "styled-components";
import NoData from 'assets/images/UI/no_data.svg'



const Empty = () => {

    return (
        <Container>
        <IMG src={NoData} />
        <Text>No data for the selected options</Text>
        <P>Select a different option to generate slide chart</P>
        </Container>
    );
    }

export default Empty;

const Container = styled.div`

width: 100%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
margin-top: 100px;
font-family: 'Raleway', sans-serif;
`;

const IMG = styled.img`
width: 200px;
`;

const Text = styled.div`
font-weight: bold;
font-size: 20px;
margin-top: 20px;
`;

const P = styled.div`
font-weight: 400;
font-size: 14px;
max-width: 600px;
margin-top: 10px;
line-height: 1.5;
text-align: center;
`;
