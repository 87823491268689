import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import DropdownContent from "new_components/atoms/DropdownContent";
import Text from "new_components/atoms/Text";
import SearchBar from "new_components/molecules/SearchBar";

// Constants
import typography from "styles/typography";
import colors from "styles/colors";

const StyledListItem = styled.div`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? colors.background.lightBlue : "transparent"};
  border-radius: 4px;

  &:hover {
    background-color: ${colors.background.lightBlue};
  }
`;

const HighlightedText = styled(Text)`
  ${({ highlight }) =>
    highlight
      ? `
    font-weight: ${typography.caption.semibold.fontWeight};
    font-size: ${typography.caption.semibold.fontSize};
    line-height: ${typography.caption.semibold.lineHeight};
  `
      : `
    font-weight: ${typography.caption.regular.fontWeight};
    font-size: ${typography.caption.regular.fontSize};
    line-height: ${typography.caption.regular.lineHeight};
  `}
`;

const SearchDropdown = ({
  data,
  onSearch,
  renderItem,
  placeholder,
  InputComponent,
  onSelect,
  maxScrollHeight = "200px",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(-1);

  // Filter data based on the search term
  const filteredData = data?.filter((item) => onSearch(item, searchTerm));

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setActiveIndex(-1); // Reset active index when the search changes
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      // Select the first available item if filteredData is not empty
      if (filteredData.length > 0) {
        onSelect(filteredData[activeIndex >= 0 ? activeIndex : 0]);
        setSearchTerm(""); // Clear search after selection
      }
    } else if (e.key === "ArrowDown") {
      // Navigate to the next item
      setActiveIndex((prev) =>
        prev < filteredData.length - 1 ? prev + 1 : prev
      );
    } else if (e.key === "ArrowUp") {
      // Navigate to the previous item
      setActiveIndex((prev) => (prev > 0 ? prev - 1 : 0));
    }
  };

  const defaultRenderItem = (item) => {
    const parts = item.text.split(new RegExp(`(${searchTerm})`, "gi"));

    return (
      <HighlightedText>
        {parts.map((part, index) => {
          const trimmedPart = part.trim();
          return trimmedPart.toLowerCase() === searchTerm.toLowerCase() ? (
            <HighlightedText key={index} highlight>
              {part}
            </HighlightedText>
          ) : (
            part
          );
        })}
      </HighlightedText>
    );
  };

  const handleItemClick = (item) => {
    onSelect(item); // Trigger the onSelect callback with the selected item
    setSearchTerm(""); // Clear search after selection
  };

  return (
    <>
      {InputComponent ? (
        <InputComponent
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <SearchBar
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
        />
      )}
      {searchTerm && filteredData.length > 0 && (
        <DropdownContent scrollable maxHeight={maxScrollHeight}>
          {/* Filtered Results */}
          {filteredData.map((item, index) => (
            <StyledListItem
              key={item.key}
              isActive={index === activeIndex}
              onMouseEnter={() => setActiveIndex(index)}
              onClick={() => handleItemClick(item)}
            >
              {renderItem ? renderItem(item) : defaultRenderItem(item)}
            </StyledListItem>
          ))}
        </DropdownContent>
      )}
      {searchTerm && filteredData.length === 0 && (
        <DropdownContent>
          <Text color={colors.text.gray}>No results found</Text>
        </DropdownContent>
      )}
    </>
  );
};

SearchDropdown.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Unique identifier for the item
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired, // Value of the selected item
      text: PropTypes.string.isRequired, // Text displayed in the dropdown
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Another identifier
    })
  ).isRequired, // The data to search
  onSearch: PropTypes.func.isRequired, // Function to filter data based on the search term
  renderItem: PropTypes.func, // Optional function to render each dropdown item
  placeholder: PropTypes.string, // Placeholder for the search input
  InputComponent: PropTypes.func.isRequired, // Custom input component
  onSelect: PropTypes.func.isRequired, // Callback triggered when an item is selected
  maxScrollHeight: PropTypes.string, // Maximum height of the dropdown content
};

export default SearchDropdown;
