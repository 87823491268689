// Pagination.js
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Icon from "new_components/atoms/Icon";
import Text from "new_components/atoms/Text";
import colors from "styles/colors";
import typography from "styles/typography";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

/**
 * A utility function to generate page numbers with ellipses.
 * For instance, if totalPages is large, we show [1, 2, 3, "...", totalPages].
 */
const getPagesArray = (currentPage, totalPages) => {
  const pages = [];

  // If totalPages is small, just show them all
  if (totalPages <= 7) {
    for (let i = 1; i <= totalPages; i += 1) {
      pages.push(i);
    }
    return pages;
  }

  // Always show first page, second page
  pages.push(1, 2);

  // If currentPage is near the start, we won't show more ellipses
  if (currentPage > 4) {
    pages.push("...");
  }

  // Show neighbors around currentPage
  const start = Math.max(3, currentPage - 1);
  const end = Math.min(totalPages - 2, currentPage + 1);
  for (let i = start; i <= end; i += 1) {
    if (i > 2 && i < totalPages - 1) {
      pages.push(i);
    }
  }

  // If currentPage is near the end, we won't show more ellipses
  if (currentPage < totalPages - 3) {
    pages.push("...");
  }

  // Always show the last two pages
  pages.push(totalPages - 1, totalPages);

  return pages;
};

const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  background: ${colors.neutral[100]};
`;

const PaginationList = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 8px;
`;

const PaginationListItem = styled.li`
  display: flex;
`;

const PageButton = styled.button`
  background: ${({ active }) => (active ? colors.primary[50] : "transparent")};
  border: none;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  padding: 6px 10px;
  border-radius: 4px;

  &:hover {
    background: ${({ active }) =>
      active ? colors.primary[50] : colors.neutral[200]};
  }
`;

/**
 * Main Pagination component
 */
const Pagination = ({
  totalPages = 1,
  currentPage = 1,
  onPageChange = () => {},
  className,
}) => {
  // Validate currentPage and totalPages
  const clampedCurrent = Math.max(1, Math.min(currentPage, totalPages));
  const pagesToShow = getPagesArray(clampedCurrent, totalPages);

  const goToPage = (page) => {
    if (typeof page === "number" && page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  const goToPrevious = () => {
    if (clampedCurrent > 1) {
      onPageChange(clampedCurrent - 1);
    }
  };

  const goToNext = () => {
    if (clampedCurrent < totalPages) {
      onPageChange(clampedCurrent + 1);
    }
  };

  return (
    <PaginationContainer className={className}>
      {/* Left Arrow */}
      <Icon
        name={ICON_SET.chevronLeft}
        size={24}
        color={clampedCurrent === 1 ? colors.neutral[600] : colors.text.dark}
        onClick={clampedCurrent === 1 ? undefined : goToPrevious}
        style={{ cursor: clampedCurrent === 1 ? "default" : "pointer" }}
      />

      <PaginationList>
        {pagesToShow.map((page, index) => (
          <PaginationListItem key={`${page}-${index}`}>
            {page === "..." ? (
              <Text
                typographyStyle={typography.body.regular}
                color={colors.text.gray}
              >
                ...
              </Text>
            ) : (
              <PageButton
                onClick={() => goToPage(page)}
                active={clampedCurrent === page}
              >
                <Text
                  typographyStyle={
                    clampedCurrent === page
                      ? typography.body.semibold
                      : typography.body.regular
                  }
                  color={
                    clampedCurrent === page
                      ? colors.primary[600]
                      : colors.text.dark
                  }
                >
                  {page}
                </Text>
              </PageButton>
            )}
          </PaginationListItem>
        ))}
      </PaginationList>

      {/* Right Arrow */}
      <Icon
        size={24}
        name={ICON_SET.chevronRight}
        color={
          clampedCurrent === totalPages ? colors.neutral[600] : colors.text.dark
        }
        onClick={clampedCurrent === totalPages ? undefined : goToNext}
        style={{
          cursor: clampedCurrent === totalPages ? "default" : "pointer",
        }}
      />
    </PaginationContainer>
  );
};

Pagination.propTypes = {
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  onPageChange: PropTypes.func,
  className: PropTypes.string,
};

export default Pagination;
