import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "new_components/atoms/Text";
import colors from "styles/colors";
import SearchBar from "new_components/molecules/SearchBar"; // Adjust the import path accordingly

const SubMenuContainer = styled.nav`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 8px 16px 0px;
`;

const SubMenuItem = styled.div`
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? colors.primary[50] : "transparent"};
  border-radius: 4px;
  padding: 8px;
  padding-left: 16px;
  transition: background-color 0.2s, color 0.2s;
`;

const GroupTitle = styled.div`
  padding-left: 8px;
  padding-top: 16px;
  padding-bottom: 4px;
  font-weight: 600;
  color: ${colors.neutral[800]};
`;

const HEADER_HEIGHT = 230; // Account for the fixed header height

const SubMenu = ({
  categories,
  contentRef,
  searchable,
  type = "multi",
  activeItemId,
  onMenuItemClick,
}) => {
  const [activeCategory, setActiveCategory] = useState(
    activeItemId || categories[0]?.id || categories[0]?.items[0]?.id
  );
  const [searchTerm, setSearchTerm] = useState("");

  const isGrouped = categories?.[0]?.groupTitle !== undefined;

  // Flatten categories to a list of all items
  const getAllItems = () => {
    if (isGrouped) {
      return categories.reduce((acc, group) => {
        const groupItems = group.items.map((item) => ({
          ...item,
          groupTitle: group.groupTitle,
        }));
        return acc.concat(groupItems);
      }, []);
    } else {
      return categories;
    }
  };

  const allItems = getAllItems();

  // Filter categories based on search term
  const filteredCategories = isGrouped
    ? categories
        .map((group) => {
          const filteredItems = group.items.filter((item) => {
            const searchLower = searchTerm.toLowerCase();
            return (
              item.categoryTitle.toLowerCase().includes(searchLower) ||
              group.groupTitle.toLowerCase().includes(searchLower)
            );
          });
          if (filteredItems.length > 0) {
            return { ...group, items: filteredItems };
          }
          return null;
        })
        .filter(Boolean)
    : allItems.filter((item) =>
        item.categoryTitle.toLowerCase().includes(searchTerm.toLowerCase())
      );

  useEffect(() => {
    if (type === "multi") {
      const handleScroll = () => {
        const sections = allItems.map(({ id }) => document.getElementById(id));
        let active = null;

        sections.forEach((section) => {
          if (section) {
            const rect = section.getBoundingClientRect();
            // Adjust for the header height
            if (rect.top <= HEADER_HEIGHT && rect.bottom >= HEADER_HEIGHT) {
              active = section.id;
            }
          }
        });

        if (active) setActiveCategory(active);
      };

      const contentWrapper = contentRef.current;
      contentWrapper.addEventListener("scroll", handleScroll);

      return () => {
        contentWrapper.removeEventListener("scroll", handleScroll);
      };
    } else {
      // In "single" mode, set active category from props
      setActiveCategory(activeItemId);
    }
  }, [allItems, contentRef, type, activeItemId]);

  const handleClick = (id, itemOnClick) => {
    if (itemOnClick) {
      itemOnClick();
    } else if (type === "multi") {
      const section = document.getElementById(id);
      if (section) {
        // Scroll to the section and account for the fixed header height
        const yOffset = -HEADER_HEIGHT + 16; // Add some padding for better alignment
        const y =
          section.getBoundingClientRect().top + contentRef.current.scrollTop;
        contentRef.current.scrollTo({
          top: y + yOffset,
          behavior: "smooth",
        });
        setActiveCategory(id);
      }
    } else if (type === "single") {
      setActiveCategory(id);
      if (onMenuItemClick) {
        onMenuItemClick(id);
      }
    }
  };

  return (
    <SubMenuContainer>
      {searchable && (
        <SearchBar
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onClear={() => setSearchTerm("")}
          placeholder="Search"
        />
      )}
      {isGrouped
        ? filteredCategories.map((group) => (
            <div key={group.groupTitle}>
              <GroupTitle>{group.groupTitle}</GroupTitle>
              {group.items.map((item) => (
                <SubMenuItem
                  key={item.id}
                  isActive={activeCategory === item.id}
                  onClick={() => handleClick(item.id, item.onClick)}
                >
                  <Text
                    typographyStyle={{
                      fontSize: "14px",
                      fontWeight: activeCategory === item.id ? "600" : "400",
                      lineHeight: "140%",
                    }}
                    color={
                      activeCategory === item.id
                        ? colors.primary[500]
                        : colors.text.dark
                    }
                  >
                    {item.categoryTitle}
                  </Text>
                </SubMenuItem>
              ))}
            </div>
          ))
        : filteredCategories.map((item) => (
            <SubMenuItem
              key={item.id}
              isActive={activeCategory === item.id}
              onClick={() => handleClick(item.id, item.onClick)}
            >
              <Text
                typographyStyle={{
                  fontSize: "14px",
                  fontWeight: activeCategory === item.id ? "600" : "400",
                  lineHeight: "140%",
                }}
                color={
                  activeCategory === item.id
                    ? colors.primary[500]
                    : colors.text.dark
                }
              >
                {item.categoryTitle}
              </Text>
            </SubMenuItem>
          ))}
    </SubMenuContainer>
  );
};

SubMenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // For flat list of categories
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        categoryTitle: PropTypes.string.isRequired,
        onClick: PropTypes.func, // Optional onClick override
      }),
      // For grouped categories
      PropTypes.shape({
        groupTitle: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            categoryTitle: PropTypes.string.isRequired,
            onClick: PropTypes.func, // Optional onClick override
          })
        ).isRequired,
      }),
    ])
  ).isRequired,
  contentRef: PropTypes.object.isRequired, // Reference to the scrollable content
  searchable: PropTypes.bool, // Whether the menu is searchable
  type: PropTypes.oneOf(["multi", "single"]), // Layout type
  activeItemId: PropTypes.string, // Active item ID for "single" type
  onMenuItemClick: PropTypes.func, // Callback when a menu item is clicked in "single" type
};

export default SubMenu;