import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseTemplate from "./BaseTemplate";
import colors from "styles/colors";

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px; /* Spacing between child elements */
  background-color: ${colors.background
    .white}; /* Use white background from colors.js */
  border-radius: 8px; /* Add slight rounding if needed */
  overflow: hidden; /* Hide any overflowing content */
`;

const FullPageContent = ({
  children,
  heading,
  pageTitle,
  subTitle,
  pagination,
}) => {
  return (
    <BaseTemplate
      heading={heading}
      pageTitle={pageTitle}
      subTitle={subTitle}
      scrollable={true}
      pagination={pagination}
    >
      <Content>{children}</Content>
    </BaseTemplate>
  );
};

FullPageContent.propTypes = {
  children: PropTypes.node.isRequired, // The page content to render
};

export default FullPageContent;
