import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// --------------------
// Styled Components
// --------------------
const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Raleway";
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2`
  margin: 0;
`;

const StyledButton = styled.button`
  padding: 8px 14px;
  border: none;
  border-radius: 4px;
  background-color: #2d70e2;
  color: #fff;
  cursor: pointer;
  &:hover {
    background-color: #1c5ec4;
  }
  &:disabled {
    background-color: #a7b4cf;
    cursor: not-allowed;
  }
`;

const TableWrapper = styled.div`
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-size: 14px;

  th,
  td {
    padding: 10px;
    border: 1px solid #e0e0e0;
    font-family: "Raleway";
  }
  th {
    background-color: #f4f6f9;
    text-align: left;
    font-weight: 600;
  }
`;

const ActionButton = styled.button`
  margin-right: 8px;
  padding: 4px 10px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  &:nth-child(1) {
    background-color: #6c757d;
    color: #fff;
  }
  &:nth-child(1):hover {
    background-color: #5a6268;
  }
  &:nth-child(2) {
    background-color: #dc3545;
    color: #fff;
  }
  &:nth-child(2):hover {
    background-color: #c82333;
  }
  &:disabled {
    background-color: #a7b4cf;
    cursor: not-allowed;
  }
`;

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 50px;
`;

const ModalContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  padding: 20px;
  position: relative;
`;

const ModalHeader = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 18px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 22px;
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
`;

const InputRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  label {
    margin-bottom: 4px;
    font-weight: 500;
  }
`;

const StyledInput = styled.input`
  padding: 7px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  font-family: "Raleway";
`;

const OptionListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
`;

const OptionName = styled.span`
  font-size: 14px;
`;

const RemoveOptionButton = styled.button`
  border: none;
  background-color: #f8d7da;
  color: #721c24;
  border-radius: 4px;
  padding: 3px 8px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f5c6cb;
  }
`;

// --------------
// Main Component
// --------------
function CustomCategoryRepository({
  existingMappings = {},
  onCategoryCreate,
  onCategoryDelete,
  onCategoryUpdate
}) {
  // Example local state for categories:
  // [
  //   {
  //     id: "...",
  //     name: "Category A",
  //     options: [{ id: "...", name: "Option 1" }, ...]
  //   }
  // ]
  const [categories, setCategories] = useState([]);

  // Modal States
  const [showModal, setShowModal] = useState(false);
  const [editingCategory, setEditingCategory] = useState(null); // null => creating new

  // Delete Confirmation
  const [catToDelete, setCatToDelete] = useState(null);

  // ------------------
  // CRUD Handlers
  // ------------------
  const handleOpenModal = (cat = null) => {
    setEditingCategory(cat);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditingCategory(null);
    setShowModal(false);
  };

  const handleSaveCategory = (catData) => {
    // catData => { id (maybe), name, options: [...] }
    if (catData.id) {
      // Edit existing
      setCategories((prev) =>
        prev.map((c) => (c.id === catData.id ? catData : c))
      );
      if (onCategoryUpdate) onCategoryUpdate(catData);
    } else {
      // Create new
      const newId = uuidv4();
      const newCat = {
        ...catData,
        id: newId
      };
      setCategories((prev) => [...prev, newCat]);
      if (onCategoryCreate) onCategoryCreate(newCat);
    }
    handleCloseModal();
  };

  const confirmDeleteCategory = (catId) => {
    setCatToDelete(catId);
  };

  const handleDeleteCategory = () => {
    if (!catToDelete) return;

    // Check usage
    if (existingMappings[catToDelete]) {
      alert("Cannot delete a category that’s in use!");
      setCatToDelete(null);
      return;
    }

    setCategories((prev) => prev.filter((cat) => cat.id !== catToDelete));
    if (onCategoryDelete) onCategoryDelete(catToDelete);

    setCatToDelete(null);
  };

  return (
    <Container>
      <HeaderRow>
        <Title>Custom Category Repository</Title>
        <StyledButton onClick={() => handleOpenModal(null)}>+ Add Category</StyledButton>
      </HeaderRow>

      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th style={{ width: "40%" }}>Name</th>
              <th style={{ width: "20%" }}>Options</th>
              <th style={{ width: "20%" }}>In Use?</th>
              <th style={{ width: "20%" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {categories.length === 0 ? (
              <tr>
                <td colSpan={4} style={{ textAlign: "center", padding: "20px" }}>
                  No custom categories yet.
                </td>
              </tr>
            ) : (
              categories.map((cat) => {
                const isInUse = !!existingMappings[cat.id];
                return (
                  <tr key={cat.id}>
                    <td>{cat.name}</td>
                    <td>{cat.options.length}</td>
                    <td>{isInUse ? "Yes" : "No"}</td>
                    <td>
                      <ActionButton onClick={() => handleOpenModal(cat)}>
                        Edit
                      </ActionButton>
                      <ActionButton
                        disabled={isInUse}
                        onClick={() => confirmDeleteCategory(cat.id)}
                      >
                        Delete
                      </ActionButton>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </StyledTable>
      </TableWrapper>

      {/* 
        Modal for creating/editing a category 
        We pass down the "editingCategory" data so the modal can be reused for both
      */}
      {showModal && (
        <CategoryModal
          initialData={editingCategory}
          onClose={handleCloseModal}
          onSave={handleSaveCategory}
        />
      )}

      {/* Delete Confirmation Dialog (could also be a modal) */}
      {catToDelete && (
        <ModalBackdrop>
          <ModalContainer>
            <ModalHeader>Confirm Delete</ModalHeader>
            <p>Are you sure you want to delete this category?</p>
            <div style={{ display: "flex", gap: "8px", marginTop: "20px" }}>
              <StyledButton onClick={handleDeleteCategory}>Yes, Delete</StyledButton>
              <StyledButton
                style={{ backgroundColor: "#6c757d" }}
                onClick={() => setCatToDelete(null)}
              >
                Cancel
              </StyledButton>
            </div>
          </ModalContainer>
        </ModalBackdrop>
      )}
    </Container>
  );
}

// --------------------------------------------
// Sub-component: CategoryModal
// Reusable for creating or editing a category
// --------------------------------------------
function CategoryModal({ initialData, onClose, onSave }) {
  // If editing, we load the data; if new, it's empty
  const [id] = useState(initialData?.id || null);
  const [name, setName] = useState(initialData?.name || "");
  const [options, setOptions] = useState(initialData?.options || []);
  const [optionInput, setOptionInput] = useState("");

  const handleAddOption = () => {
    if (!optionInput.trim()) return;
    setOptions((prev) => [
      ...prev,
      { id: uuidv4(), name: optionInput.trim() }
    ]);
    setOptionInput("");
  };

  const handleRemoveOption = (optId) => {
    setOptions((prev) => prev.filter((o) => o.id !== optId));
  };

  const handleSubmit = () => {
    if (!name.trim()) {
      alert("Category name cannot be empty.");
      return;
    }
    onSave({ id, name: name.trim(), options });
  };

  return (
    <ModalBackdrop>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalHeader>{id ? "Edit Category" : "Create New Category"}</ModalHeader>
        <InputRow>
          <label>Category Name:</label>
          <StyledInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="e.g. 'My Awesome Category'"
          />
        </InputRow>

        <InputRow>
          <label>Options:</label>
          {options.length === 0 ? (
            <p style={{ fontSize: "14px", color: "#666" }}>
              No options yet. Add some below.
            </p>
          ) : (
            options.map((opt) => (
              <OptionListItem key={opt.id}>
                <OptionName>{opt.name}</OptionName>
                <RemoveOptionButton onClick={() => handleRemoveOption(opt.id)}>
                  Remove
                </RemoveOptionButton>
              </OptionListItem>
            ))
          )}
        </InputRow>

        <InputRow>
          <label>Add an Option</label>
          <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
            <StyledInput
              value={optionInput}
              onChange={(e) => setOptionInput(e.target.value)}
              placeholder="Enter new option"
            />
            <StyledButton onClick={handleAddOption}>Add</StyledButton>
          </div>
        </InputRow>

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <StyledButton onClick={handleSubmit}>
            {id ? "Save Changes" : "Create Category"}
          </StyledButton>
        </div>
      </ModalContainer>
    </ModalBackdrop>
  );
}

export default CustomCategoryRepository;

