import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Data
import { useSurveyDataContext } from "../Data/SurveyViewContext";

// Components
import Modal from "new_components/molecules/Modal";
import Button from "new_components/atoms/Button";
import TextInput from "new_components/molecules/TextInput";
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import ImageButton from "new_components/molecules/ImageButton";

const FooterContainer = styled.div`
  display: flex;
  gap: 10px;
  justify-content: right;
  width: 100%;
`;

const PendingAssigneesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
`;

const AssigneeChip = styled.div`
  background-color: ${colors.background.lightBlue};
  color: ${colors.text.dark};
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TextWrapper = styled.div`
  margin-bottom: 16px;
`;

const AddAccessModal = ({
  isOpen,
  onClose,
  pendingUsers,
  removePendingUser,
  clearPendingUsers,
}) => {
  const { fetched_scheduled_task, addEmployeeReportAccess, surveyInfo } =
    useSurveyDataContext();
  const [inputValue, setInputValue] = useState("");
  const isConfirmConfirmed = inputValue.trim().toLowerCase() === "confirm";

  const handleConfirm = () => {
    if (inputValue.trim().toLowerCase() === "confirm") {
      let list = pendingUsers.map((user) => user.value);
      addEmployeeReportAccess(fetched_scheduled_task.id, list);
      onClose();
      setInputValue("");
      clearPendingUsers();
    }
  };

  const pendingRow = () => {
    return (
      <PendingAssigneesRow>
        {pendingUsers.map((user) => (
          <AssigneeChip key={user.value}>
            <ImageButton
              icon={ICON_SET.close}
              text={user.text}
              color={colors.text.dark}
              textStyle={true}
              iconPosition="right"
              onClick={() => removePendingUser(user.value)}
            />
          </AssigneeChip>
        ))}
      </PendingAssigneesRow>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setInputValue("");
        onClose();
      }}
      modalHeading="ADD ACCESS"
      modalWidth="800px"
      modalBody={
        <div>
          <TextWrapper>
            <Text>Add access to the following employees:</Text>
          </TextWrapper>
          {pendingRow()}
          <TextInput
            label="Type 'confirm' to give access"
            placeholder="confirm"
            value={inputValue}
            onChange={setInputValue}
          />
        </div>
      }
      modalFooter={
        <FooterContainer>
          <Button
            variant="secondary"
            onClick={() => {
              onClose();
              setInputValue("");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={handleConfirm}
            disabled={!isConfirmConfirmed}
          >
            Give Access
          </Button>
        </FooterContainer>
      }
    />
  );
};

export default AddAccessModal;
