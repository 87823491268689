import { UPDATE_EMAIL_TEMPLATE, ADD_EMAIL_TEMPLATE } from "constants/actions";

const initialState = {
  email_templates: [],
  created_email_template: null,
  updated_email_template: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_EMAIL_TEMPLATE:
      return {
        ...state,
        created_email_template: action.payload,
      };

    case UPDATE_EMAIL_TEMPLATE:
      return {
        ...state,
        updated_email_template: action.payload,
      };

    default:
      return state;
  }
};
