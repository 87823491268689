import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Data
import { UserSearchProvider, useUserSearchContext } from "Providers/UserSearchProvider";

// Components
import Text from "new_components/atoms/Text";
import IconWithBackground from "new_components/molecules/IconWithBackground";
import SearchDropdown from "new_components/organisms/SearchDropdown";
import Button from "new_components/atoms/Button";
import ImageButton from "new_components/molecules/ImageButton";
import Table from "new_components/organisms/Table";
import DeleteModal from "new_components/organisms/DeleteModal";
import AddAssigneesModal from "./AddAssigneesModal";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import typography from "styles/typography";
import Icon from "new_components/atoms/Icon";

const SectionWrapper = styled.section`
  margin-bottom: 64px; /* Space between sections */
  scroll-margin-top: 72px; /* Offset for sticky submenu */
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const ActionRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const SearchContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  flex-direction: row;
  min-width: 250px;
`;

const PendingAssigneesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
`;

// Container should not extend the full width of the screen
const StatusContainer = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ background }) => background};
  display: inline-block;
`;

// Ensure the column takes up at least 70% of the width
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 70%;
`;

// Optional: If you want to style the "selected assignees" chips or badges
const AssigneeChip = styled.div`
  background-color: ${colors.background.lightBlue};
  color: ${colors.text.dark};
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TableColumns = [
  {
    key: "name",
    label: "Name",
    isSortable: true,
    sortFunction: (a, b, direction) => {
      if (direction === "asc") {
        return a.nameSort.localeCompare(b.nameSort);
      }
      return b.nameSort.localeCompare(a.nameSort);
    },
  },
  {
    key: "status",
    label: "Status",
    isSortable: true,
    sortFunction: (a, b, direction) => {
      if (direction === "asc") {
        return a.statusSort.localeCompare(b.statusSort);
      }
      return b.statusSort.localeCompare(a.statusSort);
    },
  },
  {
    key: "actions",
    label: "Actions",
    isSortable: false,
  },
];

const STATUS_MAP = {
  pending: {
    text: colors.auxiliary.yellow[500],
    background: colors.auxiliary.yellow[50],
  },
  completed: {
    text: colors.secondary[500],
    background: colors.secondary[50],
  },
};

const Status = ({ statusText }) => {
  // capitalize the first letter
  let capText = statusText.charAt(0).toUpperCase() + statusText.slice(1);
  return (
    <StatusContainer background={STATUS_MAP[statusText].background}>
      <Text
        typographyStyle={typography.smallText.semibold}
        color={STATUS_MAP[statusText].text}
      >
        {capText}
      </Text>
    </StatusContainer>
  );
};

const getTableData = (assignees, setPendingDelete) => {
  return assignees?.map((assignee) => {
    return {
      name: (
        <NameContainer>
          <Text typographyStyle={typography.smallText.semibold}>
            {assignee.employee_full_name}
          </Text>
        </NameContainer>
      ),
      nameSort: assignee.employee_full_name,
      status: <Status statusText={assignee.status} />,
      statusSort: assignee.status,
      actions: (
        <ActionRow>
          <Icon
            onClick={() => setPendingDelete(assignee.id)}
            name={ICON_SET.delete}
            size={20}
            color={colors.text.dark}
          />
        </ActionRow>
      ),
    };
  });
};

const SummaryView = ({
  assignees,
  removeEmployee,
  assignEmployees,
  handleEmailChange,
  templateId,
}) => {
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [pendingDelete, setPendingDelete] = React.useState(null);
  const [openAssignModal, setOpenAssignModal] = React.useState(false);

  const { userList } = useUserSearchContext();

  const removePendingUser = (userId) => {
    setPendingUsers(pendingUsers.filter((user) => user.value !== userId));
  };

  const addPendingUser = (user) => {
    setPendingUsers([...pendingUsers, user]);
  };

  const clearAllPendingUsers = () => {
    setPendingUsers([]);
  };

  return (
    <SectionWrapper>
      <AddAssigneesModal
        isOpen={openAssignModal}
        onClose={() => setOpenAssignModal(false)}
        onConfirm={() => {
          setOpenAssignModal(false);
          clearAllPendingUsers();
          // Get an array of keys from the pendingUsers array
          let pendingArr = pendingUsers.map((user) => user.value);
          assignEmployees(templateId, pendingArr);
        }}
        handleEmailUpdate={handleEmailChange}
        pendingUsers={pendingUsers}
        removePendingUser={removePendingUser}
      />
      <DeleteModal
        isOpen={!!pendingDelete}
        itemName="assignee"
        onClose={() => setPendingDelete(null)}
        onConfirm={() => removeEmployee(pendingDelete)}
      />
      <SectionHeader>
        <IconWithBackground
          name={ICON_SET.assign}
          background={colors.background.lightBlue}
          size={24}
        />
        <Text typographyStyle={typography.body.semibold}>
          Add Survey Participant
        </Text>
      </SectionHeader>
      <ActionRow>
        <SearchContainer>
          <SearchDropdown
            placeholder="Search for user or group"
            data={userList}
            onSelect={addPendingUser}
            onSearch={(item, searchTerm) =>
              item.text.toLowerCase().includes(searchTerm.toLowerCase())
            }
          />
        </SearchContainer>
        {pendingUsers.length > 0 && (
          <ButtonSection>
            <ImageButton
              icon={ICON_SET.close}
              text="Clear All"
              color={colors.text.gray}
              textStyle={true}
              onClick={clearAllPendingUsers}
            />
            <Button variant="primary" onClick={() => setOpenAssignModal(true)}>
              Send Survey
            </Button>
          </ButtonSection>
        )}
      </ActionRow>
      <PendingAssigneesRow>
        {pendingUsers.map((user) => (
          <AssigneeChip key={user.value}>
            <ImageButton
              icon={ICON_SET.close}
              text={user.text}
              color={colors.text.dark}
              textStyle={true}
              iconPosition="right"
              onClick={() => removePendingUser(user.value)}
            />
          </AssigneeChip>
        ))}
      </PendingAssigneesRow>
      <Table
        columns={TableColumns}
        data={getTableData(assignees, setPendingDelete)}
        filteredProperties={["nameSort", "statusSort"]}
      />
    </SectionWrapper>
  );
};

SummaryView.propTypes = {
  /** Pre-existing assignees array (optional) */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  /**
   * Callback to handle the final list of assignees
   * (e.g. store in parent or do something else with it)
   */
  addAssignees: PropTypes.func.isRequired,
  /**
   * Full list of user options for the SearchDropdown
   * Format: [{key, value, text}, ...]
   */
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
};

const SummaryViewPage = ({
  assignees = [],
  assignEmployees,
  handleEmailChange,
  id,
}) => {
  return (
    <UserSearchProvider>
      <SummaryView
        assignees={assignees}
        assignEmployees={assignEmployees}
        handleEmailChange={handleEmailChange}
        templateId={id}
      />
    </UserSearchProvider>
  );
};

SummaryView.defaultProps = {
  assignees: [],
  userOptions: [],
};

export default SummaryViewPage;
