export const ICON_SET = {
  search: "search",
  clear: "clear",
  edit: "edit",
  delete: "delete",
  send: "send",
  view: "view",
  chevronLeft: "chevron-left",
  chevronRight: "chevron-right",
  question: "question",
  mail: "mail",
  notification: "notification",
  close: "close",
  assign: "assign",
  save: "save",
  add: "add",
  dropdown: "dropdown",
  userIcon: "user-icon",
  layers: "layers",
  aiIcon: "ai-icon",
  upload: "upload",
  database: "database",
  file: "file",
};
