import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import SectionLoader from "new_components/molecules/SectionLoader";

const Loading = () => {
  const [progress, setProgress] = useState(0);
  const [currentMessage, setCurrentMessage] = useState("Loading...");

  // Create a fake prrogress increase for testing purposes
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => prev + 10);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Create a fake message change for testing purposes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prev) => {
        if (prev === "Loading...") {
          return "Almost there...";
        } else {
          return "Loading...";
        }
      });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <SectionLoader currentMessage={currentMessage} infinite />
    </Container>
  );
};


export default Loading;


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
    font-family: 'Raleway';
`

const T1 = styled.div`
    font-size: 24px;
    font-weight: 600;
    margin-top: 10px;
    color: #2D70E2;
`

const T2 = styled.div`
    font-size: 18px;
    font-weight: 500;
    color:#2A3039;
    margin-top:30px;
`