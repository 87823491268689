import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import { parseISO, isValid } from "date-fns";
import { useSelector, shallowEqual } from "react-redux";
import styled from "styled-components";
import CustomCategoryRepository from "./CustomCategoryRepository";

// For reference, your known users for email checks
const KNOWN_USERS = ["alice@example.com", "bob@example.com", "carol@example.com", "derek@example.com"];

/* ----------------------------------
   Styled Components (same as before)
---------------------------------- */
const WizardContainer = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Raleway";
`;

const StepContent = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 30px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
`;

const StepHeader = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

const StepDescription = styled.div`
  margin-bottom: 20px;
  line-height: 1.4;
  max-width: 800px;
  font-size: 14px;
`;

const WizardFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  background-color: #2d70e2;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Raleway";

  &:hover {
    background-color: #1c5ec4;
  }
  &:disabled {
    background-color: #a7b4cf;
    cursor: not-allowed;
  }
`;

const BackButton = styled(StyledButton)`
  background-color: #f0f0f0;
  color: #333333;
  &:hover {
    background-color: #dcdcdc;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;
  font-size: 12px;

  th, td {
    padding: 8px;
    border: 1px solid #e0e0e0;
    font-family: "Raleway";
  }
  th {
    background-color: #f4f6f9;
    text-align: left;
    font-weight: 600;
  }
`;

const StyledSelect = styled.select`
  padding: 6px 8px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  font-family: "Raleway";
  outline: none;
  min-width: 100px;
`;

const ToggleLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  input {
    margin-right: 5px;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const PaginationButton = styled.button`
  padding: 5px 10px;
  background-color: #f4f6f9;
  border: 1px solid #ccc;
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

/* ------------------------------------------------------------------
   Step 4 (Emails) Subcomponent
   We only do explicit mapping for email columns
------------------------------------------------------------------ */
function EmailMappingTable({
  colName,
  rawData,
  knownUsers,
  emailRemaps,
  setEmailRemaps,
  gatherUniqueValues
}) {
  const [hideMapped, setHideMapped] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  // gather unique emails
  const allEmails = gatherUniqueValues(colName);
  const unknownEmails = allEmails.filter((e) => !knownUsers.includes(e));

  function isMapped(e) {
    return !!(emailRemaps[colName]?.[e] && emailRemaps[colName][e] !== "");
  }

  const unmappedItems = unknownEmails.filter((e) => !isMapped(e));
  const mappedItems = unknownEmails.filter((e) => isMapped(e));

  const displayList = hideMapped ? unmappedItems : unknownEmails;
  const totalPages = Math.ceil(displayList.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const pageItems = displayList.slice(startIndex, startIndex + pageSize);

  const handleEmailRemap = (rawE, newVal) => {
    setEmailRemaps((prev) => ({
      ...prev,
      [colName]: {
        ...(prev[colName] || {}),
        [rawE]: newVal
      }
    }));
  };

  function bulkIgnoreAll() {
    setEmailRemaps((prev) => {
      const copy = { ...(prev[colName] || {}) };
      unmappedItems.forEach((e) => {
        copy[e] = "IGNORE";
      });
      return { ...prev, [colName]: copy };
    });
  }

  return (
    <div style={{ marginBottom: "30px" }}>
      <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
        Column: {colName}
      </div>
      <ToggleLabel>
        <input
          type="checkbox"
          checked={hideMapped}
          onChange={(e) => {
            setCurrentPage(1);
            setHideMapped(e.target.checked);
          }}
        />
        Hide Already Mapped
      </ToggleLabel>
      <div style={{ marginBottom: "8px" }}>
        Total: {unknownEmails.length} | Mapped: {mappedItems.length} | Unmapped: {unmappedItems.length}
      </div>
      <StyledButton style={{ marginBottom: "10px" }} onClick={bulkIgnoreAll}>
        Bulk Ignore All Unmapped
      </StyledButton>
      {unmappedItems.length === 0 && hideMapped ? (
        <div>All unknown emails are mapped or ignored.</div>
      ) : (
        <>
          <StyledTable>
            <thead>
              <tr>
                <th>Email</th>
                <th>Remap To</th>
              </tr>
            </thead>
            <tbody>
              {pageItems.map((e) => {
                const mappedVal = emailRemaps[colName]?.[e] || "";
                return (
                  <tr key={e}>
                    <td>{e}</td>
                    <td>
                      <StyledSelect
                        value={mappedVal}
                        onChange={(ev) => handleEmailRemap(e, ev.target.value)}
                      >
                        <option value="">-- Select or Ignore --</option>
                        {knownUsers.map((u) => (
                          <option key={u} value={u}>
                            {u}
                          </option>
                        ))}
                        <option value="IGNORE">Ignore</option>
                      </StyledSelect>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>
          <PaginationContainer>
            <PaginationButton
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((p) => p - 1)}
            >
              Prev
            </PaginationButton>
            <div>Page {currentPage} / {totalPages}</div>
            <PaginationButton
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((p) => p + 1)}
            >
              Next
            </PaginationButton>
          </PaginationContainer>
        </>
      )}
    </div>
  );
}

/* ------------------------------------------------------------------
   Step 5 (Existing Category) Subcomponents
------------------------------------------------------------------ */
function ExistingCategoryTable({
  colName,
  parentId,
  rawData,
  columnValueToOptionID,
  setColumnValueToOptionID,
  gatherUniqueValues,
  getParentOptions
}) {
  const [hideMapped, setHideMapped] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const rawValues = gatherUniqueValues(colName);
  const options = parentId ? getParentOptions(parentId) : [];

  function isMapped(val) {
    const mapped = columnValueToOptionID[colName]?.[val];
    return mapped && mapped !== "";
  }

  const mappedItems = rawValues.filter(isMapped);
  const unmappedItems = rawValues.filter((v) => !isMapped(v));
  const displayValues = hideMapped ? unmappedItems : rawValues;

  const totalPages = Math.ceil(displayValues.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const pageItems = displayValues.slice(startIndex, startIndex + pageSize);

  const handleRemap = (val, newId) => {
    setColumnValueToOptionID((prev) => ({
      ...prev,
      [colName]: {
        ...(prev[colName] || {}),
        [val]: newId
      }
    }));
  };

  function bulkIgnoreAll() {
    setColumnValueToOptionID((prev) => {
      const copy = { ...prev };
      const curMap = { ...(copy[colName] || {}) };
      unmappedItems.forEach((val) => {
        curMap[val] = "IGNORE";
      });
      copy[colName] = curMap;
      return copy;
    });
  }

  return (
    <div style={{ marginBottom: "30px" }}>
      <div style={{ fontWeight: "bold", marginBottom: "10px" }}>
        Column: {colName}
      </div>
      {!parentId ? (
        <div>No parent selected. Please go back to Step 3.</div>
      ) : (
        <>
          <ToggleLabel>
            <input
              type="checkbox"
              checked={hideMapped}
              onChange={(e) => {
                setCurrentPage(1);
                setHideMapped(e.target.checked);
              }}
            />
            Hide Mapped
          </ToggleLabel>
          <div style={{ marginBottom: "8px" }}>
            Total: {rawValues.length} | Mapped: {mappedItems.length} | Unmapped: {unmappedItems.length}
          </div>
          <StyledButton style={{ marginBottom: "10px" }} onClick={bulkIgnoreAll}>
            Bulk Ignore Unmapped
          </StyledButton>
          <StyledTable>
            <thead>
              <tr>
                <th>Raw Value</th>
                <th>Map To Option</th>
              </tr>
            </thead>
            <tbody>
              {pageItems.map((val) => {
                const curMap = columnValueToOptionID[colName]?.[val] || "";
                return (
                  <tr key={val}>
                    <td>{val}</td>
                    <td>
                      <select
                        value={curMap}
                        onChange={(e) => handleRemap(val, e.target.value)}
                      >
                        <option value="">-- Select or Ignore --</option>
                        {options.map((o) => (
                          <option key={o.id} value={o.id}>
                            {o.name}
                          </option>
                        ))}
                        <option value="IGNORE">Ignore</option>
                      </select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </StyledTable>

          <PaginationContainer>
            <PaginationButton
              disabled={currentPage === 1}
              onClick={() => setCurrentPage((p) => p - 1)}
            >
              Prev
            </PaginationButton>
            <div>Page {currentPage} / {totalPages}</div>
            <PaginationButton
              disabled={currentPage === totalPages}
              onClick={() => setCurrentPage((p) => p + 1)}
            >
              Next
            </PaginationButton>
          </PaginationContainer>
        </>
      )}
    </div>
  );
}

function Step5ExistingCat({
  headers,
  rawData,
  columnMappings,
  columnValueToOptionID,
  setColumnValueToOptionID,
  gatherUniqueValues,
  getParentOptions,
  getParentIdForColumn
}) {
  const [autoMatchedCols, setAutoMatchedCols] = useState({});

  // columns mapped to existingCategory
  const columns = headers.filter(
    (colName) => columnMappings[colName] === "existingCategory"
  );

  // Auto-match once
  useEffect(() => {
    columns.forEach((colName) => {
      if (autoMatchedCols[colName]) return; // already matched
      const parentId = getParentIdForColumn(colName);
      if (!parentId) return; // can't match if no parent

      const rawValues = gatherUniqueValues(colName);
      const options = getParentOptions(parentId);

      setColumnValueToOptionID((prev) => {
        const copy = { ...prev };
        const curMap = { ...(copy[colName] || {}) };

        rawValues.forEach((val) => {
          if (curMap[val]) return;
          // find matching child name
          const match = options.find(
            (o) => o.name?.toLowerCase() === val.toLowerCase()
          );
          if (match) {
            curMap[val] = match.id;
          }
        });
        copy[colName] = curMap;
        return copy;
      });

      setAutoMatchedCols((prev) => ({ ...prev, [colName]: true }));
    });
  }, [
    columns,
    autoMatchedCols,
    gatherUniqueValues,
    getParentOptions,
    getParentIdForColumn,
    setColumnValueToOptionID
  ]);

  if (columns.length === 0) {
    return (
      <StepContent>
        <StepHeader>Step 5: Existing Category Mapping</StepHeader>
        <StepDescription>No existing-category columns found. Proceed.</StepDescription>
      </StepContent>
    );
  }

  return (
    <StepContent>
      <StepHeader>Step 5: Existing Category Mapping</StepHeader>
      <StepDescription>
        We attempt to auto-match raw values if they match the child option’s name exactly.
        You can override them if needed.
      </StepDescription>

      {columns.map((colName) => {
        const parentId = getParentIdForColumn(colName);
        return (
          <ExistingCategoryTable
            key={colName}
            colName={colName}
            parentId={parentId}
            rawData={rawData}
            columnValueToOptionID={columnValueToOptionID}
            setColumnValueToOptionID={setColumnValueToOptionID}
            gatherUniqueValues={gatherUniqueValues}
            getParentOptions={getParentOptions}
          />
        );
      })}
    </StepContent>
  );
}

/* ------------------------------------------------------------------
   Main CSVWizard
   - Now includes "newCategory", but we do NOT have a step for it
   - If user sets a column to newCategory, we do no further mapping steps
------------------------------------------------------------------ */
function CSVWizard() {
  const core_data = useSelector((state) => state.audit?.core_data, shallowEqual);
  const CATEGORY_MAP = core_data?.categories?.[0]?.categories || [];

  const [stepIndex, setStepIndex] = useState(1);

  // CSV states
  const [csvFile, setCsvFile] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const csvParsed = headers.length > 0;

  // Column type mapping
  const [columnMappings, setColumnMappings] = useState({});
  // existingCategory parent ID
  const [columnCategoryParents, setColumnCategoryParents] = useState({});

  // For categories: rawValue -> childId
  const [columnValueToOptionID, setColumnValueToOptionID] = useState({});
  // For emails: rawEmail -> newEmail or "IGNORE"
  const [emailRemaps, setEmailRemaps] = useState({});

  // final results
  const [finalData, setFinalData] = useState(null);
  const [headerDescriptor, setHeaderDescriptor] = useState(null);

  const goNext = () => {
    // block if step 1 and CSV not parsed
    if (stepIndex === 1 && !csvParsed) {
      alert("Please parse a CSV first!");
      return;
    }
    if (!canProceed(stepIndex)) {
      alert("Please complete all mappings before proceeding.");
      return;
    }
    // max step is 5, then step 6 is finalize
    setStepIndex((prev) => Math.min(prev + 1, 6));
  };

  const goBack = () => setStepIndex((prev) => Math.max(prev - 1, 1));

  /* 
    canProceed(stepIndex):
    We only block if step 4 (Email) or step 5 (Existing Category)
    are not fully mapped.
    "newCategory" does not require an extra mapping step,
    so we do NOT block for those columns.
  */
  const canProceed = (step) => {
    if (step === 4) {
      // check all email columns fully mapped
      const emailCols = headers.filter((c) => columnMappings[c] === "email");
      for (const colName of emailCols) {
        const allEmails = gatherUniqueValues(colName);
        const unknownEmails = allEmails.filter((e) => !KNOWN_USERS.includes(e));
        for (const e of unknownEmails) {
          const mappedVal = emailRemaps[colName]?.[e] || "";
          if (!mappedVal) {
            // not mapped => can't proceed
            return false;
          }
        }
      }
    } else if (step === 5) {
      // check existingCategory columns fully mapped
      const existingCols = headers.filter((c) => columnMappings[c] === "existingCategory");
      for (const colName of existingCols) {
        const rawVals = gatherUniqueValues(colName);
        for (const val of rawVals) {
          const mapped = columnValueToOptionID[colName]?.[val] || "";
          if (!mapped) {
            // not mapped => can't proceed
            return false;
          }
        }
      }
    }
    return true;
  };

  // parse CSV
  const handleFileChange = (e) => setCsvFile(e.target.files[0]);
  const parseFile = () => {
    if (!csvFile) return;
    Papa.parse(csvFile, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        const data = results.data;
        const cols = results.meta.fields || [];
        if (!data || data.length === 0 || cols.length === 0) {
          alert("No valid data or headers found.");
          return;
        }
        // guess col types
        const guessed = {};
        cols.forEach((col) => {
          guessed[col] = guessColumnType(data, col);
        });

        setRawData(data);
        setHeaders(cols);
        setColumnMappings(guessed);

        // reset
        setColumnCategoryParents({});
        setColumnValueToOptionID({});
        setEmailRemaps({});
      }
    });
  };

  // auto-guess
  const guessColumnType = (data, col) => {
    const samples = data.slice(0, 20).map((row) => row[col]).filter(Boolean);
    let dateCount = 0;
    let emailCount = 0;
    let numericCount = 0;
    const emailRegex = /\S+@\S+\.\S+/;

    samples.forEach((val) => {
      const trimmed = val.trim();
      if (isValid(parseISO(trimmed))) dateCount++;
      if (emailRegex.test(trimmed)) emailCount++;
      if (!isNaN(Number(trimmed))) numericCount++;
    });

    if (dateCount > samples.length * 0.8) return "date";
    if (emailCount > samples.length * 0.8) return "email";
    if (numericCount > samples.length * 0.8) return "numeric";
    return "existingCategory"; // default guess
  };

  // Step 2 override
  const handleMappingChange = (col, newType) => {
    setColumnMappings((prev) => ({ ...prev, [col]: newType }));
  };

  // Step 3 handle parent
  const handleParentCategoryChange = (col, parentId) => {
    setColumnCategoryParents((prev) => ({ ...prev, [col]: parentId }));
    // reset that column's mapping
    setColumnValueToOptionID((prev) => {
      const copy = { ...prev };
      delete copy[col];
      return copy;
    });
  };

  // gather unique
  const gatherUniqueValues = (colName) => {
    const s = new Set();
    rawData.forEach((row) => {
      const val = row[colName]?.trim();
      if (val) s.add(val);
    });
    return Array.from(s);
  };

  // get parent
  const getParentOptions = (parentId) => {
    const cat = CATEGORY_MAP.find((c) => c.id === parentId);
    return cat?.options || [];
  };
  const getParentIdForColumn = (colName) => columnCategoryParents[colName] || null;

  // finalize
  const handleFinalize = () => {
    // build final data
    const mappedRows = rawData.map((row) => {
      const newRow = {};
      headers.forEach((col) => {
        const rawVal = row[col]?.trim() || "";
        const colType = columnMappings[col];
        switch (colType) {
          case "date":
            newRow[col] = isValid(parseISO(rawVal)) ? parseISO(rawVal).toISOString() : null;
            break;
          case "email": {
            const known = KNOWN_USERS.includes(rawVal);
            if (known) {
              newRow[col] = rawVal;
            } else {
              const mapped = emailRemaps[col]?.[rawVal] || null;
              newRow[col] = mapped === "IGNORE" ? null : mapped;
            }
            break;
          }
          case "numeric": {
            const numVal = Number(rawVal);
            newRow[col] = isNaN(numVal) ? null : numVal;
            break;
          }
          case "existingCategory": {
            const mappedId = columnValueToOptionID[col]?.[rawVal] || null;
            newRow[col] = mappedId === "IGNORE" ? null : mappedId;
            break;
          }
          case "newCategory":
            // If user selected "newCategory," we just store rawVal (no mapping step).
            newRow[col] = rawVal || null;
            break;
          default:
            newRow[col] = rawVal || null;
        }
      });
      return newRow;
    });

    const descriptor = headers.map((col) => ({
      columnName: col,
      type: columnMappings[col],
      parentCategoryId: 
        columnMappings[col] === "existingCategory"
          ? columnCategoryParents[col] || null
          : null
      // If newCategory, no extra mapping; we just have the raw data
    }));

    setFinalData(mappedRows);
    setHeaderDescriptor(descriptor);
  };

  /* -----------------------
     Step Renderers
  ------------------------ */
  const renderStep1 = () => (
    <StepContent>
      <StepHeader>Step 1: Upload CSV</StepHeader>
      <StepDescription>
        Please select and parse your CSV file. You cannot proceed until it’s parsed.
      </StepDescription>
      <input type="file" accept=".csv" onChange={handleFileChange} />
      <StyledButton onClick={parseFile}>Parse CSV</StyledButton>

      <StyledButton onClick={()=>setStepIndex(7)}>View Custom Categories</StyledButton>

    </StepContent>
  );

  const renderStep7 = () => <CustomCategoryRepository />


  const renderStep2 = () => (
    <StepContent>
      <StepHeader>Step 2: Review & Override Column Types</StepHeader>
      <StepDescription>
        The system guessed some types. Adjust if needed. We also allow "newCategory" if you want brand-new category data stored as-is.
      </StepDescription>
      {!headers.length ? (
        <div>No columns found. Please go back and parse a CSV first.</div>
      ) : (
        <StyledTable>
          <thead>
            <tr>
              <th>Column Name</th>
              <th>Guessed Type</th>
              <th>Override</th>
            </tr>
          </thead>
          <tbody>
            {headers.map((col) => (
              <tr key={col}>
                <td>{col}</td>
                <td>{columnMappings[col]}</td>
                <td>
                  <StyledSelect
                    value={columnMappings[col]}
                    onChange={(e) => handleMappingChange(col, e.target.value)}
                  >
                    <option value="date">Date</option>
                    <option value="email">Email</option>
                    <option value="numeric">Numeric</option>
                    <option value="existingCategory">Existing Category</option>
                    <option value="newCategory">New Category</option>
                  </StyledSelect>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      )}
    </StepContent>
  );

  const renderStep3 = () => {
    const existingCatCols = headers.filter((c) => columnMappings[c] === "existingCategory");
    return (
      <StepContent>
        <StepHeader>Step 3: Category Setup</StepHeader>
        <StepDescription>
          For “existingCategory,” pick an existing parent. 
          If a column is “newCategory,” no further mapping step is required.
        </StepDescription>

        {existingCatCols.map((col) => (
          <div key={col} style={{ marginBottom: "20px" }}>
            <div style={{ fontWeight: "bold" }}>{col} (Existing Category)</div>
            <StyledSelect
              value={columnCategoryParents[col] || ""}
              onChange={(e) => handleParentCategoryChange(col, e.target.value)}
            >
              <option value="">-- Select a Parent Category --</option>
              {CATEGORY_MAP.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.name}
                </option>
              ))}
            </StyledSelect>
          </div>
        ))}
      </StepContent>
    );
  };

  const renderStep4_Emails = () => {
    const emailCols = headers.filter((c) => columnMappings[c] === "email");
    if (emailCols.length === 0) {
      return (
        <StepContent>
          <StepHeader>Step 4: Email Mapping</StepHeader>
          <StepDescription>No email columns found. You can proceed.</StepDescription>
        </StepContent>
      );
    }
    return (
      <StepContent>
        <StepHeader>Step 4: Email Mapping</StepHeader>
        <StepDescription>
          For columns typed as “email,” below are all unique emails not found in our system.
          Remap or ignore.
        </StepDescription>
        {emailCols.map((col) => (
          <EmailMappingTable
            key={col}
            colName={col}
            rawData={rawData}
            knownUsers={KNOWN_USERS}
            emailRemaps={emailRemaps}
            setEmailRemaps={setEmailRemaps}
            gatherUniqueValues={gatherUniqueValues}
          />
        ))}
      </StepContent>
    );
  };

  const renderStep5_ExistingCat = () => (
    <Step5ExistingCat
      headers={headers}
      rawData={rawData}
      columnMappings={columnMappings}
      columnValueToOptionID={columnValueToOptionID}
      setColumnValueToOptionID={setColumnValueToOptionID}
      gatherUniqueValues={gatherUniqueValues}
      getParentOptions={getParentOptions}
      getParentIdForColumn={getParentIdForColumn}
    />
  );

  const renderStep6_Finalize = () => (
    <StepContent>
      <StepHeader>Step 6: Finalize & Output</StepHeader>
      <StepDescription>
        Click “Finalize” to see the final JSON results. You can still go back if needed.
      </StepDescription>
      <StyledButton onClick={handleFinalize}>Finalize Mappings</StyledButton>
      {finalData && headerDescriptor && (
        <div style={{ marginTop: "20px" }}>
          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Mapped Data (Rows)
          </div>
          <pre style={{ fontSize: "12px" }}>
            {JSON.stringify(finalData, null, 2)}
          </pre>

          <div style={{ fontWeight: "bold", marginBottom: "5px" }}>
            Header Descriptor
          </div>
          <pre style={{ fontSize: "12px" }}>
            {JSON.stringify(headerDescriptor, null, 2)}
          </pre>
        </div>
      )}
    </StepContent>
  );

  /* -------------------------------------
     Render main
  --------------------------------------*/
  return (
    <WizardContainer>
      {stepIndex === 1 && renderStep1()}
      {stepIndex === 2 && renderStep2()}
      {stepIndex === 3 && renderStep3()}
      {stepIndex === 4 && renderStep4_Emails()}
      {stepIndex === 5 && renderStep5_ExistingCat()}
      {stepIndex === 6 && renderStep6_Finalize()}
      {stepIndex === 7 && renderStep7()}

      <WizardFooter>
        {stepIndex > 1 && <BackButton onClick={goBack}>Back</BackButton>}
        {stepIndex < 6 && (
          <StyledButton onClick={goNext} disabled={stepIndex === 1 && !csvParsed}>
            Next
          </StyledButton>
        )}
      </WizardFooter>
    </WizardContainer>
  );
}

export default CSVWizard;
