import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";

// Styled Components
const DropdownWrapper = styled.div`
  position: relative;
  background-color: ${colors.background.white};
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  z-index: 1000;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  max-height: ${({ scrollable, maxHeight }) =>
    scrollable ? maxHeight || "200px" : "auto"};
  overflow-y: ${({ scrollable }) => (scrollable ? "auto" : "hidden")};

  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: ${colors.neutral[200]};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${colors.neutral[500]};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${colors.neutral[600]};
  }
`;

const DropdownContent = ({ children, scrollable, maxHeight, className }) => {
  return (
    <DropdownWrapper className={className}>
      <ContentContainer scrollable={scrollable} maxHeight={maxHeight}>
        {children}
      </ContentContainer>
    </DropdownWrapper>
  );
};

DropdownContent.propTypes = {
  children: PropTypes.node.isRequired, // Content to display inside the dropdown
  scrollable: PropTypes.bool, // Enables scrolling
  maxHeight: PropTypes.string, // Max height for scrollable dropdown
  className: PropTypes.string, // Additional styling (if required)
};

DropdownContent.defaultProps = {
  scrollable: false, // Default is non-scrollable
};

export default DropdownContent;
