import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, shallowEqual } from "react-redux";

// Create SurveyContext
const SurveyContext = createContext();

// Custom Hook for easy access
export const useSurveyContext = () => useContext(SurveyContext);

// Helper Function: Parse JSON response
const parseResponse = (responseString) => {
  try {
    const jsonStartIndex = responseString.indexOf("{");
    const jsonEndIndex = responseString.lastIndexOf("}");
    if (jsonStartIndex === -1 || jsonEndIndex === -1) {
      throw new Error("No valid JSON object found in the response.");
    }
    return JSON.parse(responseString.slice(jsonStartIndex, jsonEndIndex + 1));
  } catch (error) {
    console.error("Error parsing response:", error.message);
    return null;
  }
};

export const SurveyProvider = ({ children }) => {
  const [factorDropdown, setFactorDropdown] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState([]);
  const [feedbackData, setFeedbackData] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [parsedJSON, setParsedJSON] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [promptText, setPromptText] = useState(null);
  const [viewSurvey, setViewSurvey] = useState(false);


  // A list of messages for the conversation
  const [messages, setMessages] = useState([]);

  // NEW: A list/array of surveys (created or modified)
  const [surveys, setSurveys] = useState([]);

  // Redux Selectors
  const filteredData = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );
  const coreData = useSelector((state) => state.audit?.core_data, shallowEqual);
  const getOrganizations = useSelector((state) => state.organizations);
  const getAuth = useSelector((state) => state.auth);
  const getSurveyQuestions = useSelector((state) => state.surveyquestions);

    // Generate Dropdown Options
    useEffect(() => {
      const orgId =
        getAuth?.organization_id || getOrganizations?.organization?.id;
      const organizationData = getOrganizations?.[orgId];
  
      if (organizationData) {
        const sortOrder = organizationData?.styling?.survey_sequence?.find(
          (item) => item.value === "outcome_question"
        )?.question?.sort_order;
  
        const outcomeQ = getSurveyQuestions?.outcome_questions?.find(
          (item) => item.sort_order === sortOrder
        );
  
        if (coreData) {
          const options = [];
  
          coreData.questions.dimensions.forEach((dimension, i) => {
            dimension.factors.forEach((factor) => {
              options.push({
                label: factor.title,
                value: { ...factor, dimension: i, type: "factor" }, // Keep as a JS object
              });
            });
          });
  
          // Add outcome factors
          outcomeQ?.questions.forEach((q) => {
            options.push({
              label: q.name,
              value: { ...q, type: "outcome" }, // Keep as a JS object
            });
          });
  
          setFactorDropdown(options);
        }
      }
    }, [coreData, getOrganizations, getAuth, getSurveyQuestions]);
  
  
    // Process Feedback Data
    useEffect(() => {
      if (!filteredData || selectedFactor.length === 0) return;
  
      let _feedback = "";
      const correctedData = filteredData[0]?.[0] ? filteredData[0] : filteredData;
      selectedFactor.map((factor) => {
          if (factor.type === "outcome") {
            correctedData
              ?.filter((f) => "feedback_builder" in f)
              ?.forEach((item) => {
                console.log(item)
                item.feedback_builder.responses
                  ?.filter((f) => f.q === parseInt(factor.id))
                  ?.forEach((f) => {
                    _feedback += `## Question:\n${f.question}\n`;
                    _feedback += `## Feedback:\n${f.response}\n\n`;
                  });
              });
          } else {
            correctedData
              ?.filter((f) => "feedback" in f)
              ?.forEach((item) => {
                item.feedback.forEach((f) => {
                  if (
                    factor === "All Factors" ||
                    (f.id === factor.dimension &&
                      f.factor === factor.id - 1)
                  ) {
                    _feedback += `## Question:\n${f.question}\n`;
                    _feedback += `## Feedback:\n${f.feedback}\n\n`;
                  }
                });
              });
          }
      });
  
      setFeedbackData(_feedback);
    }, [filteredData, selectedFactor]);


    const clearState = () => {
      setMessages([]);
      setSurveys([]);
      setPromptText('');
      setViewSurvey(null);
      setSelectedFactor([]);      

    };


  // --------------------------------
  // 1) MAIN FUNCTION: fetchSurveyData
  // --------------------------------
  const fetchSurveyData = async () => {

    if(!promptText){
      return 
    }
    setLoading(true);
    setError(null);

    let userMessageContent
    if(messages.length == 0){
      userMessageContent = "Build a survey to accomplish: " + promptText
    }else{
      userMessageContent = promptText 
    }




    // Save the user's prompt into the messages array
    setMessages((prev) => [
      ...prev,
      {
        id: Date.now() + Math.random(),
        role: "user",
        content: userMessageContent,
      },
    ]);

    try {

      const response = await fetch("https://api.openai.com/v1/chat/completions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer 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`,
        },
        body: JSON.stringify({
          model: "gpt-4o",
          stream: true,
          messages: [
            ...messages,
            {
              role: "user",
              content: userMessageContent,
            },
          ],
          function_call: "auto",
          functions: [
            {
              name: "create_survey",
              description: "Create a new survey or pulse questions. This is the main function to call. You call this function when the user wants to create a new survey, even if its not entirely clear.",
              parameters: {
                type: "object",
                properties: {},
                required: [],
              },
            },
            {
              name: "modify_survey",
              description: "Modify an existing survey. Call this when the user wants to modify an existing survey. This function will take a survey that has been created, and will update it based on the desired changes the user has requested.",
              parameters: {
                type: "object",
                properties: {},
                required: [],
              },
            },
            {
              name: "answer_question_on_survey",
              description: "Use this function to answer any questions relative to the survey you generated. This function can be used to rationalize, explain, or answer questions on the survey.",
              parameters: {
                type: "object",
                properties: {},
                required: [],
              },
            },
          ],
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      // Read the stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");

     

      let functionName = null;
      let functionArgsBuffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;

        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n");

        for (const line of lines) {
          if (line.trim().startsWith("data:")) {
            const jsonStr = line.replace("data:", "").trim();

            if (jsonStr === "[DONE]") {
              break;
            }

            try {
              const parsed = JSON.parse(jsonStr);
              const delta = parsed?.choices?.[0]?.delta;
              if (!delta) continue;

              // If there's normal text content
              if (delta.content) {
                setMessages((prev) => {
                  const lastMsg = prev[prev.length - 1];
                  if (lastMsg && lastMsg.role === "assistant" && !lastMsg.functionCall) {
                    return [
                      ...prev.slice(0, prev.length - 1),
                      {
                        ...lastMsg,
                        content: lastMsg.content + delta.content,
                      },
                    ];
                  } else {
                    return [
                      ...prev,
                      {
                        id: Date.now() + Math.random(),
                        role: "assistant",
                        content: delta.content,
                        functionCall: null,
                      },
                    ];
                  }
                });
              }

              // If there's a partial function call
              if (delta.function_call) {
                if (delta.function_call.name) {
                  functionName = delta.function_call.name;
                }
                if (delta.function_call.arguments) {
                  functionArgsBuffer += delta.function_call.arguments;
                }
              }
            } catch (err) {
              console.error("Error parsing chunk JSON", err, line);
            }
          }
        }
      }


      // If we ended with a function call
      if (functionName && functionArgsBuffer) {
        let finalArgs = null;
        try {
          finalArgs = JSON.parse(functionArgsBuffer);
        } catch (parseErr) {
          console.error("Failed to parse function call arguments:", parseErr);
        }

        if (finalArgs) {
          // Add the function call to messages
           // Generate a unique ID for the card
          const cardId = Date.now() + Math.random();
          const surveyId = Date.now() + Math.random();

          setMessages((prev) => [
            ...prev,
            {
              id: cardId,
              role: "assistant",
              content: "",
              functionCall: {
                name: functionName,
                args: finalArgs,
              },
              // 2) Also store the newly created "surveyId" so the card can look it up
              //    or we can store it in handleFunctionCall (either approach)
              surveyId: surveyId, // the same unique ID from handleFunctionCall
            },
          ]);

          // Now handle the function call
          await handleFunctionCall(functionName, finalArgs,surveyId);
        }
      }

      setPromptText('');

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // -----------------------------------
  // 2) handleFunctionCall Implementation
  // -----------------------------------
 // This function is called after the model chooses "create_survey" or "modify_survey"
const handleFunctionCall = async (functionName, args,surveyId) => {
  if (functionName === "create_survey" || functionName === "modify_survey") {
    // 2) Insert a placeholder into surveys with loading=true
    //    That way, the card can show "loading" until GPT call finishes
    setSurveys((prev) => [
      ...prev,
      {
        id: surveyId,
        version: 1, // or you can pass a version if you want
        loading: true, // indicates GPT is still generating
        data: null,    // final JSON goes here,
        dataSource: selectedFactor, // if you want to store the source of the data
      },
    ]);

    // 3) Save in some local variable so we can pass to the card
    let currentSurveyId = surveyId;

    // 4) Actually do the GPT call to generate/modify the survey
    let newSurvey;
    try {
      newSurvey = await generateOrModifySurveyWithGPT(functionName, args);

      // Once done, store the final JSON in `surveys`, mark `loading=false`
      setSurveys((prev) =>
        prev.map((s) => {
          if (s.id === currentSurveyId) {
            return {
              ...s,
              loading: false,
              data: newSurvey,
            };
          }
          return s;
        })
      );

      setViewSurvey(currentSurveyId);

      // Now you can call `getSurveyRationaleWithGPT` if you like
      // and also stream it into messages, etc.
      // await getSurveyRationaleWithGPT(newSurvey);

    } catch (err) {
      console.error("Error generating or modifying survey:", err);
      // If it fails, you might want to remove that survey from `surveys`
      // or mark it as error.
      setSurveys((prev) =>
        prev.map((s) => {
          if (s.id === currentSurveyId) {
            return { ...s, loading: false, error: String(err) };
          }
          return s;
        })
      );
    }
  }
  else if (functionName === "answer_question_on_survey") {
    // handle or simulate answering question logic
    console.log("answer_question_on_survey not implemented yet");
    await getSurveyRationaleWithGPT(JSON.stringify(surveys[surveys.length-1]?.data),promptText);
  }
};

  // -----------------------------------
  // 3) GPT Call to Actually Generate Survey
  // -----------------------------------
  const generateOrModifySurveyWithGPT = async (fnName, fnArgs) => {
    // For example, you might prompt GPT to produce the actual JSON
    // (or you might rely on function calling again).

    // prompt for creating a survey



     let prompt = `
      Your job here is to create a survey using the best practices for pulse surveys design.
      
      **Important**: Only return JSON with the final survey,

       Return a JSON object like:
      {
        "title": "...",
        "description": "...",
        "questions": ["question 1","question 2","question 3","question 4","question 5",...]
      }

      ${feedbackData ? 
        `The user has included feedback information from previous survey data on culture. 
        Here is the user feedback from the survey:
        ${feedbackData}` : ""
      }

      If not specified by the user, generate only 5 questions. All questions are open-ended feedback questions only.

      Here is the users original prompt for creating this survey:
      ${promptText}
      
    `;

    if(fnName == 'modify_survey'){
      prompt = `
        Your job here is to modify the survey that has been created, based on the users feedback.

        **Important**: Only return JSON with the final survey,

       Return a JSON object like:
      {
        "title": "...",
        "description": "...",
        "questions": ["question 1","question 2","question 3","question 4","question 5",...]
      }

      Only ever add qualitative questions to the survey.

       ${feedbackData ? 
        `The user has included feedback information from previous survey data on culture. 
        Here is the user feedback from the survey:
        ${feedbackData}` : ""
      }

      Here is the original survey that you are modifying:
      ${JSON.stringify(surveys[surveys.length-1]?.data)}

      Here is the users original prompt for updating this survey:
      ${promptText}
      `
    }


    setPromptText('');

    // const prompt = `
    //   Please ${fnName === "create_survey" ? "create" : "modify"} a survey 
    //   with these requirements: ${JSON.stringify(fnArgs, null, 2)}.
    //   Return a JSON object like:
    //   {
    //     "title": "...",
    //     "description": "...",
    //     "questions": [...]
    //   }
    // `;

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 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`,
      },
      body: JSON.stringify({
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: "You produce strictly valid JSON representing a survey.",
          },
          {
            role: "user",
            content: prompt,
          },
        ],
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to generate the survey JSON");
    }

    const data = await response.json();
    const rawText = data.choices?.[0]?.message?.content;
    const parsed = parseResponse(rawText);
    if (!parsed) throw new Error("Couldn't parse the returned survey JSON");
    return parsed; // e.g. { title, description, questions: [...] }
  };

 // Streams the rationale from GPT and appends to your conversation messages
const getSurveyRationaleWithGPT = async (surveyObject,userPrompt="") => {
  setLoading(true);

  // We'll store a reference to the "assistant" message we're building, if any
  let messageId = null;

  try {
    let rationalePrompt = ""
    if(userPrompt){
      rationalePrompt = `
      Here is the survey JSON:
      ${surveyObject}

      Answer the users question: ${userPrompt}
      `
    }else{
      rationalePrompt = `
      Tell the user what options they have for next steps. 
      The user can give feedback to modify the survey, or we can explain the rationale behind anything in the survey.

      If you've already said this in a previous message, then state it in one single line a suggestion on next steps.
    `;
    }

    setPromptText('');

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer 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`,
      },
      body: JSON.stringify({
        model: "gpt-4o",
        stream: true,
        messages: [
          {
            role: "system",
            content: "You are an AI that gives next steps, or help to the user about the survey that was created.",
          },
          {
            role: "user",
            content: rationalePrompt,
          },
        ],
      }),
    });

    if (!response.ok) {
      console.error("Failed to stream rationale");
      return;
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
      const { value, done } = await reader.read();
      if (done) break; // End of stream

      // Convert chunk of bytes to string
      const chunk = decoder.decode(value, { stream: true });
      // Each chunk can contain multiple lines
      const lines = chunk.split("\n");

      for (const line of lines) {
        if (line.trim().startsWith("data:")) {
          const jsonStr = line.replace("data:", "").trim();
          if (jsonStr === "[DONE]") {
            // End of stream
            break;
          }

          try {
            const parsed = JSON.parse(jsonStr);
            const delta = parsed?.choices?.[0]?.delta;
            if (!delta) continue;

            if (delta.content) {
              const partialText = delta.content;

              // 1) If we haven't started building an "assistant" message for the rationale,
              //    create it. Otherwise, append to the existing one.
              setMessages((prev) => {
                let updatedMessages = [...prev];
                const lastMsg = updatedMessages[updatedMessages.length - 1];

                if (!messageId) {
                  // Create a new "assistant" message
                  messageId = Date.now() + Math.random();
                  updatedMessages.push({
                    id: messageId,
                    role: "assistant",
                    content: partialText,
                    functionCall: null,
                  });
                } else {
                  // We already created a message; just append content to it
                  // find it by ID in updatedMessages (should be the last)
                  const msgIndex = updatedMessages.findIndex((m) => m.id === messageId);
                  if (msgIndex !== -1) {
                    updatedMessages[msgIndex] = {
                      ...updatedMessages[msgIndex],
                      content: updatedMessages[msgIndex].content + partialText,
                    };
                  }
                }

                return updatedMessages;
              });
            }
          } catch (err) {
            console.error("Error parsing rationale chunk JSON", err, line);
          }
        }
      }
    }
  } catch (err) {
    console.error("Error in getSurveyRationaleWithGPT:", err);
  } finally {
    setLoading(false);
  }
};



  // Return context
  return (
    <SurveyContext.Provider
      value={{
        factorDropdown,
        selectedFactor,
        setSelectedFactor,
        questions,
        setQuestions,
        loading,
        error,
        parsedJSON,
        fetchSurveyData,
        setPromptText,
        messages,
        surveys, // NEW: expose the surveys array
        promptText,
        viewSurvey,
        setViewSurvey,
        clearState
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};

SurveyProvider.propTypes = {
  children: PropTypes.node.isRequired,
};



 // Here is the updated prompt:
    // const prompt = `
    //   Your job here is to propose how to create a pulse survey (or modify one, or answer a survey question)
    //   based on the feedback from a culture survey on ${selectedFactor?.label}.

    //   **Important**: Instead of returning JSON with the final survey,
    //   I want you to output text instructions on which function should be called and with what arguments.

    //   Possible function calls:
    //     - create_survey
    //     - modify_survey
    //     - answer_question_on_survey

    //   If you decide to create a new survey, for example, you might output something like:

    //     CALL: create_survey {
    //       "title": "Some Title",
    //       "description": "Some Description",
    //       "questions": [ "Q1", "Q2" ]
    //     }

    //   If you decide to answer a question on a survey, you might say:

    //     CALL: answer_question_on_survey {
    //       "surveyId": 123,
    //       "questionId": 456,
    //       "answer": "My answer"
    //     }

    //   I will read this text, parse out the JSON, and call the function myself. **Do NOT** call the function directly in JSON.
    //   Just produce the text containing the function name, and the parameters that you think should be passed.

    //   Here is the user feedback from the survey:
    //   ${feedbackData}

    //   Generate ${questions} open-ended questions (if creating or modifying a survey).
    //   Never output the final JSON. Just show me your textual function call instructions.
    // `;
