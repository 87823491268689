import React, { useEffect, useState } from "react";
import styled from "styled-components";

// Data
import { useSurveyDataContext } from "../Data/SurveyViewContext";

// Components
import Modal from "new_components/molecules/Modal";
import Button from "new_components/atoms/Button";
import TextEditor from "new_components/organisms/TextEditor";
import TextInput from "new_components/molecules/TextInput";
import SectionHeader from "./SectionHeader";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import ImageButton from "new_components/molecules/ImageButton";

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  flex-direction: row;
`;

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const FooterSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const PendingAssigneesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 20px;
`;

const AssigneeChip = styled.div`
  background-color: ${colors.background.lightBlue};
  color: ${colors.text.dark};
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AddAssigneesModal = ({
  isOpen,
  onClose,
  onConfirm,
  errors,
  pendingUsers,
  removePendingUser,
  handleEmailUpdate,
}) => {
  const { fetched_scheduled_task } = useSurveyDataContext();
  const [emailSubject, setEmailSubject] = useState(
    fetched_scheduled_task?.task_template_email?.email_subject || ""
  );
  const [emailBody, setEmailBody] = useState(
    fetched_scheduled_task?.task_template_email?.email_content || ""
  );

  useEffect(() => {
    if (emailSubject && emailBody && fetched_scheduled_task) {
      handleEmailUpdate(emailSubject, emailBody);
    }
  }, [emailSubject, emailBody, fetched_scheduled_task]);

  useEffect(() => {
    setEmailSubject(
      fetched_scheduled_task?.task_template_email?.email_subject || ""
    );
    setEmailBody(
      fetched_scheduled_task?.task_template_email?.email_content || ""
    );
  }, [fetched_scheduled_task]);

  const pendingRow = () => {
    return (
      <PendingAssigneesRow>
        {pendingUsers.map((user) => (
          <AssigneeChip key={user.value}>
            <ImageButton
              icon={ICON_SET.close}
              text={user.text}
              color={colors.text.dark}
              textStyle={true}
              iconPosition="right"
              onClick={() => removePendingUser(user.value)}
            />
          </AssigneeChip>
        ))}
      </PendingAssigneesRow>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalHeading="SEND SURVEY TO NEW PARTICIPANTS"
      modalWidth="800px"
      modalBody={
        <BodySection>
          {pendingRow()}
          <TopSection>
            <SectionHeader
              text="Set E-mail Subject and Body"
              icon={ICON_SET.mail}
            />
            <Button
              onClick={() => {
                onConfirm();
              }}
            >
              Send
            </Button>
          </TopSection>
          <TextInput
            label={"Email Subject"}
            value={emailSubject}
            onChange={(e) => {
              setEmailSubject(e.target.value);
            }} // Directly update the state
            error={errors?.emailSubjectValidation}
          />
          <TextEditor
            hasError={errors?.emailBodyValidation}
            onChange={setEmailBody} // Keep editor content in sync with emailBody
            initialValue={emailBody}
            value={emailBody}
          />
        </BodySection>
      }
    />
  );
};

export default AddAssigneesModal;
