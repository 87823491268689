import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import styled from "styled-components";
import { SG_LOGIN_UPDATE_ACCOUNT_DEFAULT } from "constants/actions";
import Select from "react-select";
import { Domain } from "_config";
import UserErrorMessages from "./UserErrorMessages";
import { Message } from "semantic-ui-react";

const SelectOrg = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [orgList, setOrgList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [pristine, setPristine] = useState(true);
  const [userAuth, setUserAuth] = useState(false);

  const { get_auth } = useSelector(
    (state) => ({
      get_auth: state.auth
    }),
    shallowEqual
  );

  useEffect(() => {
    setUserAuth(get_auth);
    setOrgList(get_auth?.organization_list);
    setEmail(get_auth?.user?.email);
  }, [get_auth]);

  useEffect(() => {
    const ErrorList = [];
    let totalErrors = 0;

    if (orgList.length > 0) {
      if (!selectedOrg.AccountID) {
        ErrorList["organization"] = {
          error: true,
          message: "Please select an Organization"
        };
        totalErrors += 1;
      }
    }
    ErrorList['errors'] = totalErrors;
    setFormErrors(ErrorList);
  }, [orgList, selectedOrg, pristine]);

  const onSubmit = () => {
    if (formErrors?.errors > 0) {
      setPristine(false);
      return;
    }

    dispatch({
      type: SG_LOGIN_UPDATE_ACCOUNT_DEFAULT,
      payload: {
        email: email,
        account_id: selectedOrg?.AccountID,
        employee_id: selectedOrg?.EmployeeID,
      },
    });
    setPristine(true);
  };

  // console.log("select org location.state", location.state);
  let { from } = location.state || { from: { pathname: "/simple/dashboard" } };

  // Check if the from pathname is "/settings/select-organization" and update it
  if (from.pathname === "/settings/select-organization") {
    from = { pathname: "/simple/dashboard" };
  }
  if (get_auth?.token.trim() === get_auth?.account_default?.verify_token.trim()) {
    return <Navigate to={from} />;
  }

  return (
    <Container>
      <Box>
        <IMG src={Domain.image} />
        <UserErrorMessages />
        {formErrors.length > 0 && !pristine && (
          <Message
            negative
            header="We need these details!"
            list={formErrors}
          />
        )}
        <>
          <Label>Please select an organization</Label>
          <Description>This account is associated with more than one organization. Please select the one you'd like to login to. You can easily switch organizations by accessing the profile settings in the top right corner.</Description>
          <Select
            name="organization"
            options={orgList}
            onChange={setSelectedOrg}
            getOptionLabel={(option) => `${option.Name} (${option.OrganizationID})`}
            getOptionValue={(option) => `${option.OrganizationID}`}
            value={selectedOrg}
          />
          {formErrors?.organization?.error && !pristine && (
            <Message
              error
              content={formErrors?.organization?.message}
            />
          )}
        </>
        <Button onClick={onSubmit} id="sign-in">
          Select Org
        </Button>
      </Box>
    </Container>
  );
};

export default SelectOrg;

const Button = styled.button`
  width: 100%;
  height: 40px;
  min-height: 55px;
  border-radius: 8px;
  background-color: #2D70E2;
  color: white;
  font-size: 18px;
  font-family: "Raleway";
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  outline: none;
  margin-top: 30px;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #2D70E2;
    color: #2D70E2;
    margin-right: 3px;
  }
`;

const IMG = styled.img`
  width: 100px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: 'Raleway';
`;

const Box = styled.div`
  height: 100%;
  background-color: #ffffff;
  padding: 50px;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Label = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Raleway';
  margin-bottom: 10px;
`;

const Description = styled.div`
  font-size: 14px;
  font-family: "Raleway";
  margin-bottom: 10px;
  line-height: 1.5;
`;