import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import ImageButton from "new_components/molecules/ImageButton";
import DropdownContent from "new_components/atoms/DropdownContent";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Styled Components
const SelectBoxWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${({ width }) => width || "auto"};
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  margin-top: 8px;
  z-index: 1000;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: ${({ isActive }) => (isActive ? "-8px" : "16px")};
  font-size: ${({ isActive }) => (isActive ? "12px" : "16px")};
  color: ${({ isActive }) => (isActive ? colors.text.dark : colors.text.gray)};
  transition: all 0.2s ease-in-out;
  pointer-events: none;
  background-color: transparent; /* Prevent overlap */
  padding: 0 4px; /* Matches padding for alignment */
  z-index: ${({ isActive }) => (isActive ? "1" : "0")};
`;

const SelectButtonWrapper = styled.div`
  padding: 16px 0 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;

const SelectBox = ({ label, options, onSelect, width, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const ref = useRef(null);

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setIsOpen(false);
    onSelect(option.value);
  };

  const handleClickAway = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickAway);
    return () => document.removeEventListener("click", handleClickAway);
  }, []);

  const isActive = !!selectedValue;

  return (
    <SelectBoxWrapper width={width} ref={ref}>
      {/* Floating Label */}
      <StyledLabel isActive={isActive}>{label}</StyledLabel>

      {/* Select Button */}
      <SelectButtonWrapper onClick={handleToggle}>
        <ImageButton
          text={selectedValue?.label || ""}
          icon={ICON_SET.dropdown}
          iconPosition="right"
          textStyle={true}
          isDropdown={true}
          color={colors.text.gray}
          iconProps={{ rotate: isOpen ? 180 : 0 }}
        />
      </SelectButtonWrapper>

      {/* Dropdown Content */}
      {isOpen && (
        <DropdownWrapper>
          <DropdownContent scrollable maxHeight="200px">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleOptionClick(option)}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  backgroundColor: colors.background.white,
                }}
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = colors.neutral[200])
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = colors.background.white)
                }
              >
                {option.label}
              </div>
            ))}
          </DropdownContent>
        </DropdownWrapper>
      )}
    </SelectBoxWrapper>
  );
};

SelectBox.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
    })
  ).isRequired,
  onSelect: PropTypes.func.isRequired,
  width: PropTypes.string,
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
};

export default SelectBox;