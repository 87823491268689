import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import TextInput from "new_components/molecules/TextInput";
import TextEditor from "new_components/organisms/TextEditor";
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;

const EmailTemplate = ({ emailTemplate, onUpdate, errors }) => {
  const handleEmailChange = (field, value) => {
    onUpdate({ ...emailTemplate, [field]: value });
  };

  return (
    <SectionWrapper>
      <InputWrapper>
        <TextInput
          label="Email Subject"
          value={emailTemplate?.email_subject || ""}
          onChange={(value) => handleEmailChange("email_subject", value)}
          error={errors?.emailSubjectValidation}
        />
      </InputWrapper>
      <TextEditor
        value={emailTemplate?.email_content || ""}
        onChange={(value) => handleEmailChange("email_content", value)}
        hasError={errors?.emailBodyValidation}
      />
      {errors?.emailBodyValidation && (
        <Text
          color={colors.auxiliary.red[500]}
          typographyStyle={typography.smallText.regular}
        >
          {errors.emailBodyValidation}
        </Text>
      )}
    </SectionWrapper>
  );
};

EmailTemplate.propTypes = {
  emailTemplate: PropTypes.shape({
    email_subject: PropTypes.string,
    email_content: PropTypes.string,
  }),
  onUpdate: PropTypes.func,
};

export default EmailTemplate;
