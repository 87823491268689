import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Data
import {
  UserSearchProvider,
  useUserSearchContext,
} from "Providers/UserSearchProvider";
import { useSurveyDataContext } from "../Data/SurveyViewContext";

// Components
import Text from "new_components/atoms/Text";
import IconWithBackground from "new_components/molecules/IconWithBackground";
import SearchDropdown from "new_components/organisms/SearchDropdown";
import Button from "new_components/atoms/Button";
import ImageButton from "new_components/molecules/ImageButton";
import Table from "new_components/organisms/Table";
import DeleteModal from "new_components/organisms/DeleteModal";
import AddAccessModal from "./AddAccessModal";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import typography from "styles/typography";
import Icon from "new_components/atoms/Icon";

const SectionWrapper = styled.section`
  margin-bottom: 64px; /* Space between sections */
  scroll-margin-top: 72px; /* Offset for sticky submenu */
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const ActionRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

const SearchContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const ButtonSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  flex-direction: row;
  min-width: 250px;
`;

const PendingAssigneesRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-top: 24px;
  margin-bottom: 40px;
`;

// Ensure the column takes up at least 70% of the width
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 70%;
`;

// Optional: If you want to style the "selected assignees" chips or badges
const AssigneeChip = styled.div`
  background-color: ${colors.background.lightBlue};
  color: ${colors.text.dark};
  padding: 8px 12px;
  border-radius: 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const TableColumns = [
  {
    key: "user",
    label: "User",
    isSortable: true,
    sortFunction: (a, b, direction) => {
      if (direction === "asc") {
        return a.nameSort.localeCompare(b.nameSort);
      }
      return b.nameSort.localeCompare(a.nameSort);
    },
  },
  {
    key: "actions",
    label: "Actions",
    isSortable: false,
  },
];

const getTableData = (assignees, setPendingDelete) => {
  return assignees?.map((assignee) => {
    return {
      name: (
        <NameContainer>
          <Text typographyStyle={typography.smallText.semibold}>
            {assignee.employee_full_name}
          </Text>
        </NameContainer>
      ),
      nameSort: assignee.employee_full_name,
      actions: (
        <ActionRow>
          <Icon
            onClick={() => setPendingDelete(assignee.id)}
            name={ICON_SET.delete}
            size={20}
            color={colors.text.dark}
          />
        </ActionRow>
      ),
    };
  });
};

const EmployeeAccessView = ({ removeEmployee, addAccess }) => {
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [pendingDelete, setPendingDelete] = React.useState(null);
  const [openAccessModal, setOpenAccessModal] = React.useState(false);
  const [mappedAccessList, setMappedAccessList] = React.useState([]);

  const { userList } = useUserSearchContext();

  const { fetched_scheduled_task } = useSurveyDataContext();
  const removePendingUser = (userId) => {
    setPendingUsers(pendingUsers.filter((user) => user.value !== userId));
  };

  const addPendingUser = (user) => {
    setPendingUsers([...pendingUsers, user]);
  };

  const removeAccess = () => {
    removeEmployee(fetched_scheduled_task?.id, [pendingDelete]);
    setPendingDelete(null);
  };

  const clearAllPendingUsers = () => {
    setPendingUsers([]);
  };

  useEffect(() => {
    if (fetched_scheduled_task?.employee_access) {
      const accessIds = fetched_scheduled_task.employee_access;
      const mappedIds = accessIds.map((access) => {
        const foundId = userList.find((user) => user.value === access);
        return {
          employee_full_name: foundId?.text,
          id: access,
        };
      });

      setMappedAccessList(mappedIds);
    }
  }, [fetched_scheduled_task, userList]);

  return (
    <SectionWrapper>
      <DeleteModal
        isOpen={!!pendingDelete}
        onClose={() => setPendingDelete(null)}
        onConfirm={removeAccess}
        textOverride={
          <>
            Are you sure you want to remove access for{" "}
            <strong>
              {
                mappedAccessList.find((access) => access.id === pendingDelete)
                  ?.employee_full_name
              }
            </strong>
            ? They will lose access to this survey data.
          </>
        }
      />
      <AddAccessModal
        isOpen={openAccessModal}
        onClose={() => setOpenAccessModal(false)}
        onConfirm={(surveyId, users) => {
          addAccess(surveyId, users);
          setOpenAccessModal(false);
        }}
        clearPendingUsers={clearAllPendingUsers}
        pendingUsers={pendingUsers}
        removePendingUser={removePendingUser}
      />
      <SectionHeader>
        <IconWithBackground
          name={ICON_SET.assign}
          background={colors.background.lightBlue}
          size={24}
        />
        <Text typographyStyle={typography.body.semibold}>Access</Text>
      </SectionHeader>
      <ActionRow>
        <SearchContainer>
          <SearchDropdown
            placeholder="Search for user or group"
            data={userList}
            onSelect={addPendingUser}
            onSearch={(item, searchTerm) =>
              item.text.toLowerCase().includes(searchTerm.toLowerCase())
            }
          />
        </SearchContainer>
        {pendingUsers.length > 0 && (
          <ButtonSection>
            <ImageButton
              icon={ICON_SET.close}
              text="Clear All"
              color={colors.text.gray}
              textStyle={true}
              onClick={clearAllPendingUsers}
            />
            <Button variant="primary" onClick={() => setOpenAccessModal(true)}>
              Give Access
            </Button>
          </ButtonSection>
        )}
      </ActionRow>
      <PendingAssigneesRow>
        {pendingUsers.map((user) => (
          <AssigneeChip key={user.value}>
            <ImageButton
              icon={ICON_SET.close}
              text={user.text}
              color={colors.text.dark}
              textStyle={true}
              iconPosition="right"
              onClick={() => removePendingUser(user.value)}
            />
          </AssigneeChip>
        ))}
      </PendingAssigneesRow>
      <Table
        columns={TableColumns}
        data={getTableData(mappedAccessList, setPendingDelete)}
        filteredProperties={["nameSort"]}
      />
    </SectionWrapper>
  );
};

EmployeeAccessView.propTypes = {
  /** Pre-existing assignees array (optional) */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  /**
   * Callback to handle the final list of assignees
   * (e.g. store in parent or do something else with it)
   */
  addAssignees: PropTypes.func.isRequired,
  /**
   * Full list of user options for the SearchDropdown
   * Format: [{key, value, text}, ...]
   */
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
};

const EmployeeAccessPage = ({ removeEmployee, addAccess }) => {
  return (
    <UserSearchProvider>
      <EmployeeAccessView
        removeEmployee={removeEmployee}
        addAccess={addAccess}
      />
    </UserSearchProvider>
  );
};

EmployeeAccessView.defaultProps = {
  access: [],
  addAccess: () => {},
  removeAccess: () => {},
};

export default EmployeeAccessPage;
