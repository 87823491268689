import React, {useState,useEffect,useRef} from "react";
import styled,{keyframes} from "styled-components";
import { useSurveyContext, SurveyProvider } from "./Data/PulseBuilderContext";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router";

// Components
import ImageButton from "new_components/molecules/ImageButton";
import SurveyFunctionCard from "./ViewComponents/AICard";
import { formatElements } from "./ViewComponents/parseText";
import SurveyContent from "./ViewComponents/SurveyContent";
import ClickAwayListener from "react-click-away-listener";
import IconWithBackground from "new_components/molecules/IconWithBackground";

// Constants
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
import { Popup } from "semantic-ui-react";

import {Icon} from "semantic-ui-react";

const fadeIn = keyframes`
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

// Styles
const TopSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 0px;
  width: 100%;
`;

const QuestionWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
`;

const Message = styled.div`
  background-color: #f8f8f8;
  border-radius: 10px;
  padding: 10px;
  display: block;
  max-width: 60%;
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  margin-bottom: 20px;
`;

const UserMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  animation: ${fadeIn} 0.5s forwards;
  margin-bottom: ${(props) => (props.marginBottom ? "40px" : "10px")};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: center;
  position: relative;
  height: calc(100vh - 200px);
  overflow-y: overlay;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in the width/height */
`;

const ActionRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
`;

const IntroContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 95vh;
  width: 100%;
`;
const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  letter-spacing: -0.5px;
  margin-bottom: 20px;
  line-height: 1.2;
  width: 100%;
  text-align: center;
  color: #2a3039;
`;

const Content = styled.div`
  max-width: 700px;
  width: 100%;
`;

const SendContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;

const IconPosition = styled.div`
  position: absolute;
  left: -50px;
  z-index: -1;
`;

const PulseBuilderContent = ({ navigateToForm }) => {
  const {
    factorDropdown,
    selectedFactor,
    setSelectedFactor,
    questions,
    fetchSurveyData,
    loading,
    parsedJSON,
    setPromptText,
    messages,
    promptText,
    viewSurvey,
    setViewSurvey,
    clearState
  } = useSurveyContext();

  const [pendingFunctionCall, setPendingFunctionCall] = useState(null);
  const scrollRef = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (parsedJSON) {
      // Suppose "parsedJSON" is actually the raw text from the assistant.
      const match = parsedJSON.match(/CALL:\s*(\w+)\s*(\{[\s\S]*?\})/);
      if (match) {
        const functionName = match[1];
        const jsonBlock = match[2];
        try {
          const args = JSON.parse(jsonBlock);
          // Show the card
          if (
            functionName === "create_survey" ||
            functionName === "modify_survey"
          ) {
            setPendingFunctionCall({ name: functionName, args });
          }
        } catch (err) {
          console.error("Error parsing function call args:", err);
        }
      }
    }
  }, [parsedJSON]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        top: scrollRef.current.scrollHeight,
      });
    }
  }, [messages]);

  const handleSave = (survey) => {
    if (survey) {
      // Navigate to the form page with the generated questions
      navigateToForm({
        name: survey?.data?.title, // Builder does not generate a name
        description: survey?.data?.description, // Builder does not generate a description
        questions: survey?.data?.questions.map((q, index) => ({
          id: uuidv4(),
          question: q,
          aiEnhanced: true, // Default AI Enhanced for generated questions
          type: { value: "text", label: "Text" }, // default to text
          scale: null,
          qualifier: null,
        })),
      });
    }
  };


  const iconVisible = (m, i) => {
    if (i === 0) {
      return true;
    }
    if (m.role === "assistant" && messages[i - 1].role === "assistant") {
      return false;
    }
    return true;
  };



  if (messages.length > 0) {
    return (
      <Container ref={scrollRef}>
        <ActionRow>
        <CreateNew onClick={() => clearState()}>
          <Icon name="plus" /> New chat
        </CreateNew>
        </ActionRow>
        <AIContent open={viewSurvey}>
          {messages.map((m, i) =>
            m.functionCall ? (
              <MessageContainer marginBottom={iconVisible(m, i)}>
                <IconPosition>
                  <IconWithBackground
                    name={ICON_SET.aiIcon}
                    color={colors.background.white}
                    stroke={colors.background.white}
                    bgColor={colors.primary[500]}
                    hidden={!iconVisible(m, i)}
                  />
                </IconPosition>
                <SurveyFunctionCard
                  key={m.id}
                  functionName={m.functionCall.name}
                  functionArgs={m.functionCall.args}
                  surveyId={m.surveyId} // pass the ID you stored
                  setViewSurvey={setViewSurvey}
                  viewSurvey={viewSurvey}
                />
              </MessageContainer>
            ) : m.role == "user" ? (
              <UserMessageContainer key={m.id}>
                <UserMessage key={m.id} content={m.content} />
              </UserMessageContainer>
            ) : (
              <MessageContainer key={m.id}>
                <IconPosition>
                  <IconWithBackground
                    name={ICON_SET.aiIcon}
                    color={colors.background.white}
                    bgColor={colors.primary[500]}
                    hidden={!iconVisible(m, i)}
                  />
                </IconPosition>{" "}
                {formatElements(m.content)}
              </MessageContainer>
            )
          )}
        </AIContent>
        <AIinput
          onClick={fetchSurveyData}
          factorDropdown={factorDropdown}
          selectedFactors={selectedFactor}
          setSelectedFactors={(value) => setSelectedFactor(value)}
          setPromptText={setPromptText}
          fixed={true}
          open={viewSurvey}
          promptText={promptText}
        />
        <SurveyContent open={viewSurvey} onClick={handleSave} setViewSurvey={()=>setViewSurvey(null)}/>
      </Container>
    );
  }

  return (
    // <CenteredTemplate
    //   backAction={() => window.history.back()}
    //   pageTitle={"Pulse AI Surveys"}
    //   heading={"AI Toolkit"}
    //   middleHeading={"Pulse AI Builder"}
    //   middleSubtitle={
    //     "Select the number of questions you want to include, and the data you want incorporated into your pulse survey."
    //   }
    // >
    <>
      <ActionRow>
        <ImageButton
          text={"Back"}
          textStyle={true}
          icon={ICON_SET.chevronLeft}
          onClick={() => navigate(-1)}
          color={colors.text.gray}
        />
      </ActionRow>
      <IntroContainer>
        <Content>
          <Title>Build a world-class pulse survey</Title>
          <AIinput
            onClick={fetchSurveyData}
            factorDropdown={factorDropdown}
            selectedFactors={selectedFactor}
            setSelectedFactors={(value) => setSelectedFactor(value)}
            setPromptText={setPromptText}
            promptText={promptText}
          />

          <TopSection>
            {
              // Save button becomes visible when the user has selected a factor and number of questions and the data has been fetched
              selectedFactor && questions && parsedJSON && !loading && (
                <ImageButton
                  icon={ICON_SET.save}
                  color={colors.text.dark}
                  textStyle
                  onClick={handleSave} // Navigate to the form
                  text="Save"
                />
              )
            }
            {/* <Button 
          disabled={!selectedFactor}
          onClick={fetchSurveyData}>Generate Survey</Button> */}
          </TopSection>
        </Content>
      </IntroContainer>
    </>
  );
};

const PulseBuilderPage = ({ navigateToForm }) => {
  return (
    <SurveyProvider>
      <PulseBuilderContent navigateToForm={navigateToForm} />
    </SurveyProvider>
  );
};

export default PulseBuilderPage;

const UserMessage = ({ content }) => {
  return <Message>{content}</Message>;
};

function AIinput({
  factorDropdown = [],
  selectedFactors = [],
  setSelectedFactors = () => {},
  onClick = () => {},
  setPromptText = () => {},
  fixed = false,
  open = false,
  promptText = "",
}) {
  // Whether the data source dropdown is visible
  const [showDropdown, setShowDropdown] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleDataSourceClick = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleOptionSelect = (itemValue) => {
    // Only add if not already selected
    if (!selectedFactors.includes(itemValue)) {
      setSelectedFactors([...selectedFactors, itemValue]);
    }
  };

  const handleRemove = (itemValue) => {
    setSelectedFactors(selectedFactors.filter((val) => val !== itemValue));
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      // Prevent default newline behavior
      e.preventDefault();

      if (promptText.trim() !== "") {
        // Call the onClick or submit function
        onClick();
      }
    } else if (e.key === "Enter" && e.shiftKey) {
      // Allow newline when Shift+Enter is pressed
      e.preventDefault();
      setPromptText((prev) => prev + "\n");
    }
  };

  const handleTextAreaInput = (e) => {
    e.target.style.height = "auto"; // Reset height to auto for recalculation
    e.target.style.height = `${e.target.scrollHeight}px`; // Adjust height to fit content
  };

  return (
    <TextContainer fixed={fixed} open={open}>
      {/* 
         Button to "Generate survey questions"
         triggers parent's onClick prop
      */}

      <TextArea
        placeholder={fixed ? "How would you like to modify the survey?" :"Build a survey to accomplish..."}
        onChange={(e) => setPromptText(e.target.value)}
        onInput={handleTextAreaInput}
        maxLength={3000}
        onKeyDown={handleKeyDown}
        value={promptText}
      />
      <Popup
        content="Generate survey questions"
        position="top center"
        inverted
        trigger={
          <SendContainer>
            <IconWithBackground
              name={ICON_SET.upload}
              onClick={onClick}
              bgColor={colors.primary[500]}
              color={colors.background.white}
              size={15}
            />
          </SendContainer>
        }
      />

      {/* Data Source icon + tags + dropdown */}
      <DataSourceWrapper>
        <Popup
          content="Add data sources"
          position="top center"
          inverted
          trigger={
            <DataSourceIcon onClick={handleDataSourceClick}>
              <Icon
                name={ICON_SET.database}
                size={20}
                fill={colors.neutral[600]}
                style={{ cursor: "pointer" }}
              />
            </DataSourceIcon>
          }
        />

        {/* Render tags for each selected data source */}
        <TagsContainer>
          {selectedFactors.map((ds) => {
            const foundOption = factorDropdown.find((o) => o.value === ds);
            return (
              <Tag key={ds}>
                {foundOption ? foundOption.label : ds}
                <RemoveTag onClick={() => handleRemove(ds)}>×</RemoveTag>
              </Tag>
            );
          })}
        </TagsContainer>

        {/* Dropdown with factor options */}
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          {showDropdown && (
            <DropdownList fixed={fixed}>
              {factorDropdown.map((option) => (
                <DropdownItem
                  key={option.value}
                  onClick={() => handleOptionSelect(option.value)}
                >
                  {option.label}
                </DropdownItem>
              ))}
            </DropdownList>
          )}
        </ClickAwayListener>
      </DataSourceWrapper>
    </TextContainer>
  );
}

const TextContainer = styled.div`
  position: ${(props) => (props.fixed ? "fixed" : "relative")};
  bottom: ${(props) => (props.fixed ? "40px" : "0px")};
  left: ${(props) => (props.open ? "210px" : "")};
  width: ${(props) =>
    props.fixed ? (props.open ? "calc(100% - 820px)" : "700px") : "100%"};
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: Raleway;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  background-color: white;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 15px 15px 0px 15px;
  font-size: 14px;
  font-family: Raleway, sans-serif;
  border: none;
  color: #333;
  resize: none; /* Prevent manual resize by user */
  border-radius: 10px;
  line-height: 1.4;
  max-height: 400px; /* Limit height to 200px */
  overflow-y: auto; /* Enable vertical scrolling */

  /* Remove the focus border */
  &:focus {
    outline: none;
    border: none; /* Keep the same border as unfocused state */
  }
`;

// The data source wrapper holds the icon, tags, and the dropdown
const DataSourceWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  position: relative;
`;

const DataSourceIcon = styled.div`
  height: 30px;
  width: 30px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  color: ${colors.neutral[600]};
  align-items: center;
  border: 1px solid ${colors.neutral[200]};
  justify-content: center;
`;

// Container that holds selected "tag" chips
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  gap: 5px;
  width: 85%;
`;

// Individual Tag styling
const Tag = styled.div`
  background-color: #e8f1fc;
  color: #2d70e2;
  padding: 3px 8px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  font-size: 13px;
`;

// The "X" to remove a selected data source
const RemoveTag = styled.span`
  margin-left: 8px;
  cursor: pointer;
  color: #999;
  &:hover {
    color: #666;
  }
`;

// The dropdown container for data source options
const DropdownList = styled.ul`
  position: absolute;
  top: ${props=>props.fixed ? '-300px' : '50px'}; /* position below the icon */
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 8px 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 200px; /* or adjust to your preference */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height:300px;
  overflow-y:scroll;
  scrollbar-width: thin;
`;

// Each dropdown item
const DropdownItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    background: #f2f2f2;
  }
`;

const AIContent = styled.div`
  max-width: ${props=>props.open ? 'calc(100% - 670px)' : '700px'};
  width: 100%;
  font-family: 'Raleway', sans-serif;
  line-height: 1.6;
  padding-bottom:100px;
  margin-right:${props=>props.open? '550px': '0px'};
  transition: all 0.5s;

`

const CreateNew = styled.div`
  color: #2d70e2;
  cursor: pointer;
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  margin-left:20px;
  margin-top:-10px;
  margin-bottom:20px;
  z-index:9999999;
`