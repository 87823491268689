import React from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";
import IconWithBackground from "new_components/molecules/IconWithBackground";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const SectionHeader = ({ icon, text }) => {
  return (
    <SectionContainer>
      <IconWithBackground
        name={icon}
        background={colors.background.lightBlue}
        size={24}
      />
      <Text typographyStyle={typography.body.semibold}>{text}</Text>
    </SectionContainer>
  );
};

export default SectionHeader;
