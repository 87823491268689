import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text"; // Your existing Text component

// Constants
import colors from "styles/colors";
import typography, { applyTypography } from "styles/typography";

// Wrapper for the input field
const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledLabel = styled.label`
  position: absolute;
  top: ${({ isActive }) => (isActive ? "-8px" : "16px")};
  left: 0;
  color: ${({ isActive, isError }) =>
    isError ? colors.auxiliary.red[500] : colors.text.gray};
  transition: all 0.2s ease-in-out;
  pointer-events: none;

  ${({ isActive }) =>
    isActive
      ? applyTypography(typography.smallText.regular)
      : applyTypography(typography.body.regular)}
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: 1px solid
    ${({ isError }) =>
      isError ? colors.auxiliary.red[500] : colors.neutral[500]};
  padding: 16px 0px 4px;
  outline: none;
  color: ${colors.text.dark};
  transition: border-color 0.2s ease-in-out;
  resize: none; /* Prevents manual resizing */
  overflow: hidden; /* Hides the scrollbar */
  background-color: transparent;

  &:focus {
    border-bottom-color: ${({ isError }) =>
      isError ? colors.auxiliary.red[500] : colors.primary[500]};
  }

  &::placeholder {
    color: transparent;
  }

  /* Apply typography styles */
  ${({ typographyStyle }) => applyTypography(typographyStyle)}
`;

const ErrorText = styled(Text)`
  margin-top: 8px;
  color: ${colors.auxiliary.red[500]};
  ${({ typographyStyle }) => applyTypography(typographyStyle)}
`;

const TextInput = ({
  label,
  placeholder,
  value,
  onChange,
  error,
  typographyStyle = typography.body.regular, // Default typography style
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(value || "");
  const isActive = isFocused || inputValue.length > 0;
  const textareaRef = useRef(null);

  const handleOnChange = (e) => {
    setInputValue(e.target.value);
    if (onChange) {
      onChange(e.target.value);
    }

    // Auto-resize logic
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set to scroll height
    }
  };

  useEffect(() => {
    setInputValue(value || ""); // Sync inputValue with value prop changes
  }, [value]);

  useEffect(() => {
    // Adjust height on initial render
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, []);

  return (
    <InputWrapper>
      <StyledLabel isActive={isActive} isError={!!error} htmlFor={props.id}>
        {label}
      </StyledLabel>
      <StyledTextarea
        ref={textareaRef}
        id={props.id}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleOnChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        isError={!!error}
        typographyStyle={typographyStyle}
        rows={1} // Start with one row
        {...props}
      />
      {error && (
        <ErrorText typographyStyle={typography.smallText.regular}>
          {error}
        </ErrorText>
      )}
    </InputWrapper>
  );
};

export default TextInput;
