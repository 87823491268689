import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";
import typography from "styles/typography";

import Text from "new_components/atoms/Text";

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const TabItem = styled(Text)`
  background: transparent;
  border: none;
  padding: 8px 0px;
  cursor: pointer;
  color: ${({ isActive }) =>
    isActive ? colors.primary[500] : colors.text.gray};
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${colors.primary[500]}` : "2px solid transparent"};
  outline: none;
  margin-right: 26px;
  &:hover {
    color: ${colors.primary[400]};
  }
`;

const Tabs = ({ tabs, activeTabIndex, onTabChange }) => {
  if (!tabs || tabs.length === 0) return null;

  return (
    <TabsContainer>
      {tabs.map((tab, index) => (
        <div onClick={() => onTabChange(index)}>
          <TabItem
            key={tab.label}
            isActive={index === activeTabIndex}
            typographyStyle={
              index === activeTabIndex
                ? typography.body.semibold
                : typography.body.regular
            }
          >
            {tab.label}
          </TabItem>
        </div>
      ))}
    </TabsContainer>
  );
};

Tabs.propTypes = {
  /**
   * An array of tab objects: [{ label: string, content: ReactNode }, ...]
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node,
    })
  ).isRequired,
  /**
   * The currently active tab index.
   */
  activeTabIndex: PropTypes.number.isRequired,
  /**
   * Callback triggered when a tab is clicked, with the new index.
   */
  onTabChange: PropTypes.func.isRequired,
};

export default Tabs;
