import styled, { keyframes } from 'styled-components';
import React, { useEffect, useCallback, useState, useRef } from "react";
import {
  process_feedback_data_V2,
} from "../../calculations";
import { useSelector, shallowEqual } from "react-redux";
import { filter_data } from "reports/Tag/calculations";
import Filters from '../Filters'
import Anchor from "../Anchor";
import SentimentSummaryChart from "./Chart";

  
  const Feedback = ({ data, core_data, outcomeQ, navigation, originalData }) => {

    const [filtered_feedback, setFilteredFeedback] = useState([]);
    
    const { selectedFilters } = useSelector((state) => ({selectedFilters: state.audit?.new_filters,}),shallowEqual);
     const anchor = useSelector((state) => state.audit?.anchor,shallowEqual);

  
  
    useEffect(() => {
      let filteredData;
  
      if (data?.[0]?.[0]) {
        filteredData = filter_data(data[0], selectedFilters);
      } else {
        filteredData = filter_data(data, selectedFilters);
      }

      setFilteredFeedback(process_feedback_data_V2(filteredData, undefined, true))

    }, [data, selectedFilters]);  
    
  
    return (
        <Container>
          <CultureLabel>Culture Analytics</CultureLabel>
  
          <TopTitle>Sentiment Analytics</TopTitle>
          <TopDescription>
            A summary of the sentiment of each comment from the survey. This
            feedback was labeled using a machine learning algorithm trained on
            culture feedback.
          </TopDescription>
          <Anchor anchorLabel=" " />

          <Filters />


          <SentimentSummaryChart
            feedback={filtered_feedback}
            anchorCategory={anchor}
            categories={core_data?.categories[0]?.categories}
            />  
          
            </Container>

        
    );
  };
  
  export default Feedback;
  
  
  const Container = styled.div`
    width: 100%;
    position: relative;
    // Remove scroll if chat is open
    max-height: 100%;
    padding-bottom: 10px;
  `;
  
  const TopTitle = styled.h1`
    display: flex;
    align-items: flex-start;
  `;
  const TopDescription = styled.div``;
  
  
  const CultureLabel = styled.div`
    font-family: "Raleway";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
      "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  
    /* Primary/500 */
    color: #2d70e2;
  
    display: flex;
    align-items: flex-start;
    margin-bottom: -15px;
  `;
  
  
  