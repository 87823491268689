import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";

// Components
import FullPageContent from "new_components/templates/FullContentTemplate";
import SelectCard from "new_components/organisms/SelectCard";
import ImageButton from "new_components/molecules/ImageButton";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Styles
const CardsWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-top: 32px;
`;

// Wrapper for the action row
const ActionRow = styled.div`
  display: ${({ show }) => (show ? "flex" : "none")};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

const SelectionPage = (props) => {
  const navigate = useNavigate();
  const { noData } = props;

  return (
    <FullPageContent heading="AI Toolkit" pageTitle="Pulse AI Surveys">
      <ActionRow show={!noData}>
        <ImageButton
          icon={ICON_SET.chevronLeft}
          text="Back"
          color={colors.text.gray}
          textStyle={true}
          onClick={() => navigate("/leader/audit/AI/pulse")}
        />
      </ActionRow>
      <CardsWrapper>
        {/* Card 1: Library */}
        {/* <SelectCard
          iconName={ICON_SET.layers}
          iconBgColor={colors.primary[100]}
          title="Library"
          description="Choose from a variety of pre-built AI pulse surveys, built by experts in organizational psychology and culture."
          linkText="Next"
          onClick={() => navigate("/leader/audit/AI/pulse/survey-library")}
        /> */}

        <SelectCard
          iconName={ICON_SET.aiIcon}
          iconBgColor={colors.primary[100]}
          title="AI Builder"
          description="Use our AI builder to create your own pulse survey based on your data and insights."
          linkText="Next"
          onClick={() => navigate("/leader/audit/AI/pulse/builder")}
        />

        <SelectCard
          iconName={ICON_SET.userIcon}
          iconBgColor={colors.primary[100]}
          title="Self Guided"
          description="Use our self-guided builder to create your own pulse survey based on the questions you think are best."
          linkText="Next"
          onClick={() => navigate("/leader/audit/AI/pulse/create")}
        />
      </CardsWrapper>
    </FullPageContent>
  );
};

export default SelectionPage;
