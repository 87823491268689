import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TemplateBuilder from "./TeamplteList/index";
import Menu from "./Menu";
import Empty from "./Empty";
import { useSelector, shallowEqual } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GenerateChartModal from "./GenerateChart"; // Import the modal
import { Button } from "semantic-ui-react";

const Templates = ({ updateSlides, generatePresentation }) => {
  const [slides, setSlides] = useState([]); // Each slide is { template: '...', data: { ... } }
  const [selectedSlideIndex, setSelectedSlideIndex] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedSlideData, setSelectedSlideData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [outcomeQ, setOutcomeQ] = useState(null);
  const [outcomeStandards, setOutcomeStandards] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // State to control modal

  const get_organizations = useSelector((state) => state.organizations);
  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );
  const get_auth = useSelector((state) => state.auth);
  const get_survey_questions = useSelector((state) => state.surveyquestions);

  const addSlide = (template) => {
    const newSlide = { template, data: {} };
    setSlides([...slides, newSlide]);
    setSelectedSlideIndex(slides.length);
  };

  const removeSlide = (index) => {
    setSlides((prevSlides) => {
      const updatedSlides = prevSlides.filter((_, i) => i !== index);
      return updatedSlides;
    });

    if (slides.length === 1) {
      setSelectedTemplate(null);
      setSelectedSlideIndex(null);
    } else {
      if (selectedSlideIndex == 0) {
        setSelectedSlideIndex(0);
        setSelectedTemplate(slides[0].template);
      } else {
        setSelectedSlideIndex(selectedSlideIndex - 1);
        setSelectedTemplate(slides[selectedSlideIndex - 1].template);
      }
    }
  };

  const updateSlideData = (data) => {
    // Create an updated slides array
    const updatedSlides = slides.map((slide, index) =>
      index === selectedSlideIndex ? { ...slide, data } : slide
    );

    // Update local slides state
    setSlides(updatedSlides);
    // Pass updated slides to parent component
    updateSlides(updatedSlides);
  };

  useEffect(() => {
    if (selectedSlideIndex !== null) {
      const selectedSlide = slides[selectedSlideIndex];
      setSelectedTemplate(selectedSlide.template);
      setSelectedSlideData(selectedSlide.data);
    }
  }, [selectedSlideIndex, slides]);

  useEffect(() => {
    if (get_organizations) {
      setOrganizationData(
        get_organizations[
          get_auth?.organization_id || get_organizations?.organization?.id
        ]
      );
    }
  }, [get_organizations]);

  useEffect(() => {
    if (organizationData) {
      // We need to get the outcome question id
      // Then parse the outcome question list for the proper version
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;

      if (sortOrder) {
        const outcomeQ = get_survey_questions?.outcome_questions.find(
          (item) => item.sort_order === sortOrder
        );
        setOutcomeQ(outcomeQ);
      }
    }
  }, [core_data, organizationData]);

  useEffect(() => {
    if (outcomeQ) {
      setOutcomeStandards(
        core_data?.get_standards.find((f) =>
          f.question_sort_order.includes(outcomeQ.sort_order)
        )?.response
      );
    }
  }, [outcomeQ]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [selectedSlideIndex]);

  // Add the onDragEnd handler
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items = Array.from(slides);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setSlides(items);
    // Update selectedSlideIndex if needed
    if (result.source.index === selectedSlideIndex) {
      setSelectedSlideIndex(result.destination.index);
    } else if (
      result.source.index < selectedSlideIndex &&
      result.destination.index >= selectedSlideIndex
    ) {
      setSelectedSlideIndex(selectedSlideIndex - 1);
    } else if (
      result.source.index > selectedSlideIndex &&
      result.destination.index <= selectedSlideIndex
    ) {
      setSelectedSlideIndex(selectedSlideIndex + 1);
    }
  };

  return (
    <Container>
      <Top>
        <BlueTitle>AI Toolkit</BlueTitle>
        <BigTitle>Presentation Builder</BigTitle>
      </Top>

      <GenerateButton onClick={() => setModalOpen(true)} disabled={slides.length==0}>
        Generate Presentation
      </GenerateButton>
      <Content>
        <SlideOptions>
          <Menu onSelect={(t) => addSlide(t)} />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="slides">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {slides.map((slide, index) => (
                    <Draggable
                      key={index}
                      draggableId={`slide-${index}`}
                      index={index}
                    >
                      {(provided) => (
                        <Option
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          onClick={() => setSelectedSlideIndex(index)}
                          selected={selectedSlideIndex === index}
                        >
                          {slide.template}
                          <FactorTitle>
                            {slide.data?.title
                              ? slide.data?.title
                              : "Empty slide"}
                          </FactorTitle>
                        </Option>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </SlideOptions>

        <SelectedTemplate>
          {selectedTemplate && (
            <Remove onClick={() => removeSlide(selectedSlideIndex)}>
              Remove slide
            </Remove>
          )}
          {selectedTemplate ? (
            <TemplateBuilder
              selected={selectedTemplate}
              slideData={selectedSlideData}
              updateSlideData={updateSlideData}
              loading={loading}
              outcomeQ={outcomeQ}
              outcomeStandards={outcomeStandards}
            />
          ) : (
            <Empty />
          )}
        </SelectedTemplate>
      </Content>
      <GenerateChartModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        generatePresentation={generatePresentation}
        slides={slides}
      />
    </Container>
  );
};

export default Templates;

const Container = styled.div`
  margin-top: -10px;
`;

const Content = styled.div`
  display: flex;
  width: 100%;
`;

const Remove = styled.div`
  z-index: 100;
  font-size: 12px;
  color: #2d70e2;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`;
const SlideOptions = styled.div`
  width: 250px;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-right: 1px solid #ccc;
`;

const Option = styled.div`
  width: 100%;
  padding: 10px;
  border: ${(props) =>
    props.selected ? "2px solid #2D70E2" : "1px solid #ccc"};
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f8faff;
  }
  font-size: 12px;
  margin-bottom: 10px;

  color: ${(props) => (props.selected ? "#2D70E2" : "#666D79")};
  font-weight: ${(props) => (props.selected ? "600" : "400")};
  background-color: ${(props) => (props.selected ? "#F8FAFF" : "#fff")};
`;

const SelectedTemplate = styled.div`
  width: calc(100% - 250px);
  padding: 20px;
  position: relative;
`;

const Top = styled.div`
  margin-bottom: 40px;
`;

const BlueTitle = styled.div`
  font-size: 12px;
  color: #2d70e2;
  font-weight: 600;
  margin-bottom: 5px;
`;

const BigTitle = styled.div`
  color: #2a3039;
  font-size: 32px;
  font-weight: 700;
`;

const FactorTitle = styled.div`
  font-size: 12px;
  color: #666d79;
  margin-top: 5px;
  font-weight: 400;
`;

const GenerateButton = styled(Button)`
  background-color: #2d70e2 !important;
  color: white !important;
  padding: 10px 20px !important;
  border: none !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 16px !important;
  margin-top: 20px !important;
  align-self: flex-end !important;
  margin-bottom: 20px !important;

  &:hover {
    background-color: #1a4a8e !important;
  }
`;
