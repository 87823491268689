import React from "react";
import styled from "styled-components";
import { useSurveyContext } from "../Data/PulseBuilderContext"; // or wherever
import { Icon } from "semantic-ui-react";

const SurveyContent = ({open,onClick,setViewSurvey}) => {
    const { surveys } = useSurveyContext();
    const survey = surveys.find(s => s.id === open);


    const renderSurveyContent = () => {
        if(survey){
            const data = survey.data;
            return (
                <div>
                    <Blue>Name</Blue>
                    <Title>{data.title}</Title>

                    <Blue>Description</Blue>
                    <Description>{data.description}</Description>

                    {survey.dataSource?.length >0 && 
                         (<>
                         <Blue>Data sources</Blue>
                            {survey.dataSource.map((d,i)=>{
                                const title = d.type == 'factor' ? d.title : d.name
                                return (
                                    <LI key={i}>{title}</LI>
                                )
                            }
                            )}
                        </>) 
                    }   
                    


                    <Blue>Questions</Blue>
                    <ol>
                        {data.questions.map((q,i)=>{
                            return (
                                <LI key={i}>{q}</LI>
                            )
                        }
                        )}
                    </ol>
                  
                </div>
            )
        }

    }


    return (
      <Container open={open}>
        <ButtonContainer>
          <Back onClick={() => setViewSurvey(null)}>
            <Icon name="angle double right" />
          </Back>
          <DeployButton onClick={() => onClick(survey)}>Next</DeployButton>
        </ButtonContainer>
        {renderSurveyContent()}
      </Container>
    );

}

export default SurveyContent;


const Container = styled.div`
    width:600px;
    height:95vh;
    position:fixed;
    top:50px;
    right:${props=>props.open ? '0' : '-650px'};
    background-color:#fff;
    transition: right 0.3s;
    border-left:1px solid #ccc;
    padding:20px;
    font-family: 'Raleway', sans-serif;
    overflow-y:auto;
`


const Title = styled.div`
    font-size:20px;
    font-weight:600;
    margin-bottom:20px;
`
const Description = styled.div`
    color:#666D79;
    margin-bottom:20px;
`

const LI = styled.li`
    margin-bottom:15px;
    font-size:14px;
    color:#2A3039;

`

const Blue = styled.div`
    color:#2D70E2;
    font-weight:600;
    font-size:12px;
    margin-bottom:5px;
`   

const DeployButton = styled.div`
    background-color:#2D70E2;
    color:#fff;
    padding:10px;
    border-radius:5px;
    text-align:center;
    cursor:pointer;
    margin-bottom:20px;
    font-weight:600;
    font-size:14px;
    transition: background-color 0.3s;
    display:inline-block;
    &:hover{
        background-color:#1F5A9D;
    }
`

const ButtonContainer = styled.div`
    width:100%;
    display:flex;
    justify-content:space-between;
`

const Back = styled.div`
    color:#666D79;
    cursor:pointer;
    font-size:18px;
    margin-bottom:20px;
    display:flex;
    align-items:center;
`