import data from "data/live/navigation";


export function calculate_summary_chart(data, lastData, anchor, selectedFactor, core_data, rule) {

    if (!anchor || !selectedFactor) {
        return null;
    }

    // Find the anchor category in the categories array
    const categories = core_data.categories[0].categories;
    const anchorVariable = anchor;
    const questionStructure = core_data.questions;

    // Find the anchor category in the categories array
    const anchorCategory = categories.find(cat => cat.id === anchorVariable);
    if (!anchorCategory) {
        throw new Error(`Anchor category with id ${anchorVariable} not found.`);
    }

    // Map category option IDs to names
    const categoryOptions = {};
    for (const option of anchorCategory.options) {
        categoryOptions[option.id] = option.name;
    }

    // Initialize an object to hold data grouped by category options
    const groupedData = {};
    const questionGroupedData = {}; // Holds data per question
    // Initialize similar structures for lastData
    const lastGroupedData = {};
    const lastQuestionGroupedData = {};

    for (const optionId in categoryOptions) {
        const categoryName = categoryOptions[optionId];
        groupedData[categoryName] = [];
        questionGroupedData[categoryName] = {}; // Initialize per category

        // Initialize for lastData
        lastGroupedData[categoryName] = [];
        lastQuestionGroupedData[categoryName] = {};
    }

    // Find the dimension ID for the selected factor in the questionStructure
    let selectedDimensionId = selectedFactor.dimension;

    if (selectedDimensionId === null) {
        throw new Error(`Selected factor with id ${selectedFactor.id} not found in questionStructure.`);
    }

    // Build a map from question indices to their properties (reverse flag, question text)
    const questionMap = {};
    selectedFactor.questions.forEach((qObj, index) => {
        questionMap[index] = {
            reverse: qObj.reverse,
            text: qObj.q
        };
    });

    // Initialize arrays to collect overall data
    const overallScores = []; // Collects the average scores of the factor across all responses
    const questionOverallScores = {}; // Collects responses per question across all categories

    // Similar arrays for lastData
    const lastOverallScores = [];
    const lastQuestionOverallScores = {};
   
    // Helper function to process data
    function processData(
      dataset,
      groupedDataObj,
      questionGroupedDataObj,
      overallScoresArr,
      questionOverallScoresObj
    ) {
      for (const response of dataset) {
        // Find the value of the anchor variable (category option)
        const categoryResponse = response?.categories?.find(
          (cat) => cat.id === anchorVariable
        );
        if (!categoryResponse || !categoryResponse.response) {
          // If no response for the anchor category, skip this response
          continue;
        }
        const categoryOptionId = categoryResponse?.response;
        const categoryOptionName = categoryOptions[categoryOptionId];

        if (!categoryOptionName) {
          // If the option ID is not found, skip this response
          continue;
        }

        // Extract responses to selected factor's questions
        const responses = [];
        const questionResponses = {}; // Holds response per question
        for (const question of response.questions) {
          // Check if the question belongs to the selected factor by matching dimension ID, factor ID, and question index
          if (
            question.id == selectedDimensionId && // dimension ID
            question.factor == selectedFactor.id - 1 && // factor ID
            questionMap.hasOwnProperty(question.q) // question.q is index in selectedFactor.questions
          ) {
            const qIndex = question.q;
            let resp = question.response;
            if (resp !== null && resp !== undefined) {
              // Adjust for reverse-scored questions
              const reverse = questionMap[qIndex].reverse;
              if (reverse) {
                resp = 10 - resp; // Assuming responses are from 1 to 10
              }
              responses.push(resp);

              // Store response per question
              const questionText = questionMap[qIndex].text;
              if (!questionResponses[questionText]) {
                questionResponses[questionText] = [];
              }
              questionResponses[questionText].push(resp);

              // Collect overall responses per question
              if (!questionOverallScoresObj[questionText]) {
                questionOverallScoresObj[questionText] = [];
              }
              questionOverallScoresObj[questionText].push(resp);
            }
          }
        }

        if (responses.length > 0) {
          // Compute the average score for this response (across the selected factor's questions)
          const avgScore =
            responses.reduce((a, b) => a + b, 0) / responses.length;
          // Add the score to the list for the corresponding category option
          groupedDataObj[categoryOptionName].push(avgScore);

          // Add avgScore to overallScores
          overallScoresArr.push(...responses);

          // Store per-question responses
          for (const [questionText, resps] of Object.entries(
            questionResponses
          )) {
            if (!questionGroupedDataObj[categoryOptionName][questionText]) {
              questionGroupedDataObj[categoryOptionName][questionText] = [];
            }
            // Since we're processing one response at a time, resps is an array with one element
            questionGroupedDataObj[categoryOptionName][questionText].push(
              resps[0]
            );
          }
        }
      }
    }
    // Process current data
    processData(data, groupedData, questionGroupedData, overallScores, questionOverallScores);

    // Process lastData if provided
    if (lastData) {
        processData(lastData, lastGroupedData, lastQuestionGroupedData, lastOverallScores, lastQuestionOverallScores);
    }

    // Now compute the counts for each category
    const categoryCounts = {};
    for (const categoryOptionName in groupedData) {
        categoryCounts[categoryOptionName] = groupedData[categoryOptionName].length;
    }

    // Filter out categories that do not meet the rule
    const categoriesToKeep = Object.keys(categoryCounts).filter(
        categoryOptionName => categoryCounts[categoryOptionName] > rule
    );

    // Now compute the statistics for each category option that meets the rule
    const output = {
        categories: categoriesToKeep, // Only include categories that meet the rule
        series: [
            {
                name: 'Average Score',
                data: []
            },
            {
                name: 'Max Score',
                data: []
            },
            {
                name: 'Min Score',
                data: []
            },
            {
                name: 'Standard Deviation',
                data: []
            }
        ],
        questionSeries: {}, // Holds data per question
        overallStats: {}, // Holds overall average and standard deviation for the factor
        questionOverallStats: {}, // Holds overall average and standard deviation per question
        // New fields for last average scores
        lastSeries: [
            {
                name: 'Last Average Score',
                data: []
            }
        ],
        lastOverallStats: {}, // Holds overall average and standard deviation for lastData
        questionLastSeries: {}, // Holds data per question for lastData
        questionLastOverallStats: {} // Holds overall average and standard deviation per question for lastData
    };

    // Compute overall statistics for the factor
    if (overallScores.length > 0) {
        const overallMean = overallScores.reduce((a, b) => a + b, 0) / overallScores.length;
        const overallVariance =
            overallScores.reduce((sum, val) => sum + (val - overallMean) ** 2, 0) / overallScores.length;
        const overallStd = Math.sqrt(overallVariance);

        // Add to output
        output.overallStats = {
            average: overallMean,
            standardDeviation: overallStd
        };
    } else {
        output.overallStats = {
            average: null,
            standardDeviation: null
        };
    }

    // Compute overall statistics for lastData
    if (lastOverallScores.length > 0) {
        const lastOverallMean = lastOverallScores.reduce((a, b) => a + b, 0) / lastOverallScores.length;
        const lastOverallVariance =
            lastOverallScores.reduce((sum, val) => sum + (val - lastOverallMean) ** 2, 0) / lastOverallScores.length;
        const lastOverallStd = Math.sqrt(lastOverallVariance);

        // Add to output
        output.lastOverallStats = {
            average: lastOverallMean,
            standardDeviation: lastOverallStd
        };
    } else {
        output.lastOverallStats = {
            average: null,
            standardDeviation: null
        };
    }

    // Compute statistics per category option
    for (const categoryOptionName of categoriesToKeep) {
        const scores = groupedData[categoryOptionName];
        const lastScores = lastGroupedData[categoryOptionName] || [];

        // Current data statistics
        if (scores.length > 0) {
            const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
            const max = Math.max(...scores);
            const min = Math.min(...scores);
            const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
            const std = Math.sqrt(variance);

            output.series[0].data.push(mean);
            output.series[1].data.push(max);
            output.series[2].data.push(min);
            output.series[3].data.push(std);
        } else {
            output.series[0].data.push(null);
            output.series[1].data.push(null);
            output.series[2].data.push(null);
            output.series[3].data.push(null);
        }

        // Last data statistics
        if (lastScores.length > 0) {
            const lastMean = lastScores.reduce((a, b) => a + b, 0) / lastScores.length;
            output.lastSeries[0].data.push(lastMean);
        } else {
            output.lastSeries[0].data.push(null);
        }
    }

    // Compute statistics per question
    const questionTexts = Object.values(questionMap).map(q => q.text);

    for (const questionText of questionTexts) {
        output.questionSeries[questionText] = {
            'Average Score': { name: 'Average Score', data: [] },
            'Max Score': { name: 'Max Score', data: [] },
            'Min Score': { name: 'Min Score', data: [] },
            'Standard Deviation': { name: 'Standard Deviation', data: [] }
        };

        // Initialize lastQuestionSeries
        output.questionLastSeries[questionText] = {
            'Last Average Score': { name: 'Last Average Score', data: [] }
        };

        for (const categoryOptionName of categoriesToKeep) {
            const scores = questionGroupedData[categoryOptionName][questionText] || [];
            const lastScores = lastQuestionGroupedData[categoryOptionName][questionText] || [];

            // Current data statistics
            if (scores.length > 0) {
                const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
                const max = Math.max(...scores);
                const min = Math.min(...scores);
                const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
                const std = Math.sqrt(variance);

                output.questionSeries[questionText]['Average Score'].data.push(mean);
                output.questionSeries[questionText]['Max Score'].data.push(max);
                output.questionSeries[questionText]['Min Score'].data.push(min);
                output.questionSeries[questionText]['Standard Deviation'].data.push(std);
            } else {
                output.questionSeries[questionText]['Average Score'].data.push(null);
                output.questionSeries[questionText]['Max Score'].data.push(null);
                output.questionSeries[questionText]['Min Score'].data.push(null);
                output.questionSeries[questionText]['Standard Deviation'].data.push(null);
            }

            // Last data statistics per question
            if (lastScores.length > 0) {
                const lastMean = lastScores.reduce((a, b) => a + b, 0) / lastScores.length;
                output.questionLastSeries[questionText]['Last Average Score'].data.push(lastMean);
            } else {
                output.questionLastSeries[questionText]['Last Average Score'].data.push(null);
            }
        }

        // Compute overall statistics per question across all categories
        const overallQuestionScores = questionOverallScores[questionText] || [];
        if (overallQuestionScores.length > 0) {
            const mean = overallQuestionScores.reduce((a, b) => a + b, 0) / overallQuestionScores.length;
            const variance =
                overallQuestionScores.reduce((sum, val) => sum + (val - mean) ** 2, 0) /
                overallQuestionScores.length;
            const std = Math.sqrt(variance);

            output.questionOverallStats[questionText] = {
                average: mean,
                standardDeviation: std
            };
        } else {
            output.questionOverallStats[questionText] = {
                average: null,
                standardDeviation: null
            };
        }

        // Compute overall statistics per question for lastData
        const lastOverallQuestionScores = lastQuestionOverallScores[questionText] || [];
        if (lastOverallQuestionScores.length > 0) {
            const lastMean = lastOverallQuestionScores.reduce((a, b) => a + b, 0) / lastOverallQuestionScores.length;
            const lastVariance =
                lastOverallQuestionScores.reduce((sum, val) => sum + (val - lastMean) ** 2, 0) /
                lastOverallQuestionScores.length;
            const lastStd = Math.sqrt(lastVariance);

            output.questionLastOverallStats[questionText] = {
                average: lastMean,
                standardDeviation: lastStd
            };
        } else {
            output.questionLastOverallStats[questionText] = {
                average: null,
                standardDeviation: null
            };
        }
    }

    return output;
}


export function calculate_outcome_summary_chart(data, lastData, anchor, selectedFactor, core_data, outcomeQ, rule) {

    if (!anchor || !selectedFactor) {
        return null;
    }
    // Find the anchor category in the categories array
    const categories = core_data.categories[0].categories;
    const anchorVariable = anchor;
    const questionStructure = outcomeQ; // Use outcomeQ instead of core_data.questions

    // Find the anchor category in the categories array
    const anchorCategory = categories.find(cat => cat.id === anchorVariable);
    if (!anchorCategory) {
        throw new Error(`Anchor category with id ${anchorVariable} not found.`);
    }

    // Map category option IDs to names
    const categoryOptions = {};
    for (const option of anchorCategory.options) {
        categoryOptions[option.id] = option.name;
    }

    // Initialize an object to hold data grouped by category options
    const groupedData = {};
    const questionGroupedData = {}; // Holds data per question
    // Initialize similar structures for lastData
    const lastGroupedData = {};
    const lastQuestionGroupedData = {};

    for (const optionId in categoryOptions) {
        const categoryName = categoryOptions[optionId];
        groupedData[categoryName] = [];
        questionGroupedData[categoryName] = {}; // Initialize per category

        // Initialize for lastData
        lastGroupedData[categoryName] = [];
        lastQuestionGroupedData[categoryName] = {};
    }

    // Build a map from question keys to their properties (question text)
    // Also, create a set of question keys that belong to the selectedFactor
    const questionMap = {};
    const selectedQuestionKeys = new Set();

    // Build the questionMap and selectedQuestionKeys
    outcomeQ.questions.forEach((category) => {
        if (String(category.id) === String(selectedFactor.id)) {
            category.questions.forEach((qObj) => {
                const qKey = `${String(qObj.q)}_${String(qObj.s)}`; // Ensure q and s are strings
                questionMap[qKey] = {
                    text: qObj.question
                };
                selectedQuestionKeys.add(qKey);
            });
        }
    });

    // Initialize arrays to collect overall data
    const overallScores = []; // Collects the average scores of the outcome across all responses
    const questionOverallScores = {}; // Collects responses per question across all categories

    // Similar arrays for lastData
    const lastOverallScores = [];
    const lastQuestionOverallScores = {};

    // Helper function to process data
    function processData(dataset, groupedDataObj, questionGroupedDataObj, overallScoresArr, questionOverallScoresObj) {
        for (const response of dataset) {
            // Find the value of the anchor variable (category option)
            const categoryResponse = response.categories.find(cat => cat.id === anchorVariable);
            if (!categoryResponse || !categoryResponse.response) {
                // If no response for the anchor category, skip this response
                continue;
            }
            const categoryOptionId = categoryResponse.response;
            const categoryOptionName = categoryOptions[categoryOptionId];

            if (!categoryOptionName) {
                // If the option ID is not found, skip this response
                continue;
            }

            // Extract responses to selected outcome's questions
            const responses = [];
            const questionResponses = {}; // Holds response per question

            // Access employee_outcome
            const employeeOutcome = response.employee_outcomes;
            if (employeeOutcome && employeeOutcome.responses) {
                const outcomeResponses = employeeOutcome.responses || [];
                for (const question of outcomeResponses) {
                    const qKey = `${String(question.q)}_${String(question.s)}`; // Ensure q and s are strings
                    if (selectedQuestionKeys.has(qKey)) {
                        let resp = question.response;
                        if (resp !== null && resp !== undefined) {
                            responses.push(resp);

                            // Store response per question
                            const questionText = questionMap[qKey].text;
                            if (!questionResponses[questionText]) {
                                questionResponses[questionText] = [];
                            }
                            questionResponses[questionText].push(resp);

                            // Collect overall responses per question
                            if (!questionOverallScoresObj[questionText]) {
                                questionOverallScoresObj[questionText] = [];
                            }
                            questionOverallScoresObj[questionText].push(resp);
                        }
                    }
                }
            }

            if (responses.length > 0) {
                // Compute the average score for this response (across the selected outcome's questions)
                const avgScore = responses.reduce((a, b) => a + b, 0) / responses.length;
                // Add the score to the list for the corresponding category option
                groupedDataObj[categoryOptionName].push(avgScore);

                // Add avgScore to overallScores
                overallScoresArr.push(avgScore);

                // Store per-question responses
                for (const [questionText, resps] of Object.entries(questionResponses)) {
                    if (!questionGroupedDataObj[categoryOptionName][questionText]) {
                        questionGroupedDataObj[categoryOptionName][questionText] = [];
                    }
                    // Since we're processing one response at a time, resps is an array with one element
                    questionGroupedDataObj[categoryOptionName][questionText].push(resps[0]);
                }
            }
        }
    }
    // Process current data
    processData(data, groupedData, questionGroupedData, overallScores, questionOverallScores);

    // Process lastData if provided
    if (lastData) {
        processData(lastData, lastGroupedData, lastQuestionGroupedData, lastOverallScores, lastQuestionOverallScores);
    }

    // Now compute the counts for each category
    const categoryCounts = {};
    for (const categoryOptionName in groupedData) {
        categoryCounts[categoryOptionName] = groupedData[categoryOptionName].length;
    }

    // Filter out categories that do not meet the rule
    const categoriesToKeep = Object.keys(categoryCounts).filter(
        categoryOptionName => categoryCounts[categoryOptionName] > rule
    );

    // Now compute the statistics for each category option that meets the rule
    const output = {
        categories: categoriesToKeep, // Only include categories that meet the rule
        series: [
            {
                name: 'Average Score',
                data: []
            },
            {
                name: 'Max Score',
                data: []
            },
            {
                name: 'Min Score',
                data: []
            },
            {
                name: 'Standard Deviation',
                data: []
            }
        ],
        questionSeries: {}, // Holds data per question
        overallStats: {}, // Holds overall average and standard deviation for the outcome
        questionOverallStats: {}, // Holds overall average and standard deviation per question
        // New fields for last average scores
        lastSeries: [
            {
                name: 'Last Average Score',
                data: []
            }
        ],
        lastOverallStats: {}, // Holds overall average and standard deviation for lastData
        questionLastSeries: {}, // Holds data per question for lastData
        questionLastOverallStats: {} // Holds overall average and standard deviation per question for lastData
    };

    // Compute overall statistics for the outcome
    if (overallScores.length > 0) {
        const overallMean = overallScores.reduce((a, b) => a + b, 0) / overallScores.length;
        const overallVariance = overallScores.reduce((sum, val) => sum + (val - overallMean) ** 2, 0) / overallScores.length;
        const overallStd = Math.sqrt(overallVariance);

        // Add to output
        output.overallStats = {
            average: overallMean,
            standardDeviation: overallStd
        };
    } else {
        output.overallStats = {
            average: null,
            standardDeviation: null
        };
    }

    // Compute overall statistics for lastData
    if (lastOverallScores.length > 0) {
        const lastOverallMean = lastOverallScores.reduce((a, b) => a + b, 0) / lastOverallScores.length;
        const lastOverallVariance = lastOverallScores.reduce((sum, val) => sum + (val - lastOverallMean) ** 2, 0) / lastOverallScores.length;
        const lastOverallStd = Math.sqrt(lastOverallVariance);

        // Add to output
        output.lastOverallStats = {
            average: lastOverallMean,
            standardDeviation: lastOverallStd
        };
    } else {
        output.lastOverallStats = {
            average: null,
            standardDeviation: null
        };
    }

    // Compute statistics per category option
    for (const categoryOptionName of categoriesToKeep) {
        const scores = groupedData[categoryOptionName];
        const lastScores = lastGroupedData[categoryOptionName] || [];

        // Current data statistics
        if (scores.length > 0) {
            const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
            const max = Math.max(...scores);
            const min = Math.min(...scores);
            const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
            const std = Math.sqrt(variance);

            output.series[0].data.push(mean);
            output.series[1].data.push(max);
            output.series[2].data.push(min);
            output.series[3].data.push(std);
        } else {
            output.series[0].data.push(null);
            output.series[1].data.push(null);
            output.series[2].data.push(null);
            output.series[3].data.push(null);
        }

        // Last data statistics
        if (lastScores.length > 0) {
            const lastMean = lastScores.reduce((a, b) => a + b, 0) / lastScores.length;
            output.lastSeries[0].data.push(lastMean);
        } else {
            output.lastSeries[0].data.push(null);
        }
    }

    // Compute statistics per question
    const questionTexts = Object.values(questionMap).map(q => q.text);

    for (const questionText of questionTexts) {
        output.questionSeries[questionText] = {
            'Average Score': { name: 'Average Score', data: [] },
            'Max Score': { name: 'Max Score', data: [] },
            'Min Score': { name: 'Min Score', data: [] },
            'Standard Deviation': { name: 'Standard Deviation', data: [] }
        };

        // Initialize lastQuestionSeries
        output.questionLastSeries[questionText] = {
            'Last Average Score': { name: 'Last Average Score', data: [] }
        };

        for (const categoryOptionName of categoriesToKeep) {
            const scores = questionGroupedData[categoryOptionName][questionText] || [];
            const lastScores = lastQuestionGroupedData[categoryOptionName][questionText] || [];

            // Current data statistics
            if (scores.length > 0) {
                const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
                const max = Math.max(...scores);
                const min = Math.min(...scores);
                const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
                const std = Math.sqrt(variance);

                output.questionSeries[questionText]['Average Score'].data.push(mean);
                output.questionSeries[questionText]['Max Score'].data.push(max);
                output.questionSeries[questionText]['Min Score'].data.push(min);
                output.questionSeries[questionText]['Standard Deviation'].data.push(std);
            } else {
                output.questionSeries[questionText]['Average Score'].data.push(null);
                output.questionSeries[questionText]['Max Score'].data.push(null);
                output.questionSeries[questionText]['Min Score'].data.push(null);
                output.questionSeries[questionText]['Standard Deviation'].data.push(null);
            }

            // Last data statistics per question
            if (lastScores.length > 0) {
                const lastMean = lastScores.reduce((a, b) => a + b, 0) / lastScores.length;
                output.questionLastSeries[questionText]['Last Average Score'].data.push(lastMean);
            } else {
                output.questionLastSeries[questionText]['Last Average Score'].data.push(null);
            }
        }

        // Compute overall statistics per question across all categories
        const overallQuestionScores = questionOverallScores[questionText] || [];
        if (overallQuestionScores.length > 0) {
            const mean = overallQuestionScores.reduce((a, b) => a + b, 0) / overallQuestionScores.length;
            const variance = overallQuestionScores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / overallQuestionScores.length;
            const std = Math.sqrt(variance);

            output.questionOverallStats[questionText] = {
                average: mean,
                standardDeviation: std
            };
        } else {
            output.questionOverallStats[questionText] = {
                average: null,
                standardDeviation: null
            };
        }

        // Compute overall statistics per question for lastData
        const lastOverallQuestionScores = lastQuestionOverallScores[questionText] || [];
        if (lastOverallQuestionScores.length > 0) {
            const lastMean = lastOverallQuestionScores.reduce((a, b) => a + b, 0) / lastOverallQuestionScores.length;
            const lastVariance = lastOverallQuestionScores.reduce((sum, val) => sum + (val - lastMean) ** 2, 0) / lastOverallQuestionScores.length;
            const lastStd = Math.sqrt(lastVariance);

            output.questionLastOverallStats[questionText] = {
                average: lastMean,
                standardDeviation: lastStd
            };
        } else {
            output.questionLastOverallStats[questionText] = {
                average: null,
                standardDeviation: null
            };
        }
    }

    return output;
}


export function calculate_longitudinal_chart(timeSeriesData, anchorVariable, selectedFactor, categories, questionStructure) {
    // Map to store aggregated data
    const aggregatedData = {};

    // Get category options
    const anchorCategory = categories.find(cat => cat.id === anchorVariable);
    if (!anchorCategory) {
        throw new Error(`Anchor category with id ${anchorVariable} not found.`);
    }
    const categoryOptions = {};
    for (const option of anchorCategory.options) {
        categoryOptions[option.id] = option.name;
    }
    const categoryNames = Object.values(categoryOptions);

    // Find the dimension ID for the selected factor in the questionStructure
    let selectedDimensionId = selectedFactor.dimension;


    if (selectedDimensionId === null) {
        throw new Error(`Selected factor with id ${selectedFactor.id} not found in questionStructure.`);
    }

    // Build question map (similar to previous)
    const questionMap = {};
    selectedFactor.questions.forEach((qObj, index) => {
        questionMap[index] = {
            reverse: qObj.reverse,
            text: qObj.q
        };
    });

    // Initialize aggregated data structure
    for (const categoryName of categoryNames) {
        aggregatedData[categoryName] = {};
    }

    // Helper function to parse date strings like 'Q1 2024' into comparable values
    function parseDateString(dateStr) {
        const [quarterStr, yearStr] = dateStr.split(' ');
        const quarter = parseInt(quarterStr.replace('Q', ''));
        const year = parseInt(yearStr);
        return { year, quarter };
    }

    // Collect all dates and sort them from oldest to newest
    const allDatesSet = new Set(timeSeriesData.map(tp => tp.date));
    const allDates = Array.from(allDatesSet);
    allDates.sort((a, b) => {
        const dateA = parseDateString(a);
        const dateB = parseDateString(b);
        return dateA.year !== dateB.year ? dateA.year - dateB.year : dateA.quarter - dateB.quarter;
    });

    // Process each time point
    for (const timePoint of timeSeriesData) {
        const { date, responses } = timePoint;

        // Prepare data structures for this date
        for (const categoryName of categoryNames) {
            if (!aggregatedData[categoryName][date]) {
                aggregatedData[categoryName][date] = {
                    responses: [],
                    questionResponses: {}
                };
            }
        }
        // Process responses for this time point
        for (const responseEMP of responses) {
            const response = responseEMP.response;
            // Find the value of the anchor variable (category option)
            const categoryResponse = response.categories.find(cat => cat.id === anchorVariable);
            if (!categoryResponse || categoryResponse.response === null || categoryResponse.response === undefined) {
                continue; // Skip if no category response
            }
            const categoryOptionId = categoryResponse.response;
            const categoryOptionName = categoryOptions[categoryOptionId];
            if (!categoryOptionName) {
                continue; // Skip if invalid category option
            }

            const dataEntry = aggregatedData[categoryOptionName][date];

            // Extract responses to selected factor's questions
            const responsesArray = [];
            const questionResponses = {}; // Holds response per question
            for (const question of response.questions) {
                if (
                    question.id == selectedDimensionId && // dimension ID
                    question.factor == selectedFactor.id-1 && // factor ID
                    questionMap.hasOwnProperty(question.q) // question.q is index in selectedFactor.questions
                ) {
                    const qIndex = question.q;
                    let resp = question.response;
                    if (resp !== null && resp !== undefined) {
                        // Adjust for reverse-scored questions
                        const reverse = questionMap[qIndex].reverse;
                        if (reverse) {
                            resp = 10 - resp; // Assuming responses are from 1 to 10
                        }
                        responsesArray.push(resp);

                        // Store response per question
                        const questionText = questionMap[qIndex].text;
                        if (!questionResponses[questionText]) {
                            questionResponses[questionText] = [];
                        }
                        questionResponses[questionText].push(resp);
                    }
                }
            }

            if (responsesArray.length > 0) {
                // Add to aggregated data
                dataEntry.responses.push(...responsesArray);
                for (const [questionText, resps] of Object.entries(questionResponses)) {
                    if (!dataEntry.questionResponses[questionText]) {
                        dataEntry.questionResponses[questionText] = [];
                    }
                    dataEntry.questionResponses[questionText].push(...resps);
                }
            }
        }
    }

    // Now compute statistics for each category option over time
    const output = {
        series: {},         // Contains overall statistics per category
        questionSeries: {}  // Contains per-question statistics per category
    };

    // Initialize series for each category and statistical measure
    for (const categoryName of categoryNames) {
        output.series[categoryName] = {
            'Average Score': [],
            'Max Score': [],
            'Min Score': [],
            'Standard Deviation': [],
            'Distribution': [] // Added distribution data
        };
        output.questionSeries[categoryName] = {};
        for (const qObj of selectedFactor.questions) {
            const questionText = qObj.q;
            output.questionSeries[categoryName][questionText] = {
                'Average Score': [],
                'Max Score': [],
                'Min Score': [],
                'Standard Deviation': [],
                'Distribution': [] // Added distribution data
            };
        }
    }

    // Process data and populate output
    for (const date of allDates) {
        for (const categoryName of categoryNames) {
            const dataEntry = aggregatedData[categoryName][date];

            // Process overall statistics
            const stats = output.series[categoryName];
            if (dataEntry && dataEntry.responses.length > 0) {
                const scores = dataEntry.responses;
                const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
                const max = Math.max(...scores);
                const min = Math.min(...scores);
                const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
                const std = Math.sqrt(variance);

                stats['Average Score'].push({ x: date, y: mean });
                stats['Max Score'].push({ x: date, y: max });
                stats['Min Score'].push({ x: date, y: min });
                stats['Standard Deviation'].push({ x: date, y: std });

                // Compute distribution
                const distribution = Array(11).fill(0); // Responses from 0 to 10
                for (const score of scores) {
                    if (score >= 0 && score <= 10) {
                        distribution[score] += 1;
                    }
                }
                stats['Distribution'].push({ x: date, y: distribution });
            } else {
                // No data for this date
                stats['Average Score'].push({ x: date, y: null });
                stats['Max Score'].push({ x: date, y: null });
                stats['Min Score'].push({ x: date, y: null });
                stats['Standard Deviation'].push({ x: date, y: null });
                stats['Distribution'].push({ x: date, y: null });
            }

            // Process per-question statistics
            for (const qObj of selectedFactor.questions) {
                const questionText = qObj.q;
                const qStats = output.questionSeries[categoryName][questionText];
                if (dataEntry && dataEntry.questionResponses[questionText]) {
                    const qScores = dataEntry.questionResponses[questionText];
                    const qMean = qScores.reduce((a, b) => a + b, 0) / qScores.length;
                    const qMax = Math.max(...qScores);
                    const qMin = Math.min(...qScores);
                    const qVariance = qScores.reduce((sum, val) => sum + (val - qMean) ** 2, 0) / qScores.length;
                    const qStd = Math.sqrt(qVariance);

                    qStats['Average Score'].push({ x: date, y: qMean });
                    qStats['Max Score'].push({ x: date, y: qMax });
                    qStats['Min Score'].push({ x: date, y: qMin });
                    qStats['Standard Deviation'].push({ x: date, y: qStd });

                    // Compute distribution
                    const qDistribution = Array(11).fill(0); // Responses from 0 to 10
                    for (const qScore of qScores) {
                        if (qScore >= 0 && qScore <= 10) {
                            qDistribution[qScore] += 1;
                        }
                    }
                    qStats['Distribution'].push({ x: date, y: qDistribution });
                } else {
                    // No data for this date
                    qStats['Average Score'].push({ x: date, y: null });
                    qStats['Max Score'].push({ x: date, y: null });
                    qStats['Min Score'].push({ x: date, y: null });
                    qStats['Standard Deviation'].push({ x: date, y: null });
                    qStats['Distribution'].push({ x: date, y: null });
                }
            }
        }
    }

    return output;
}

export function calculate_longitudinal_outcome_chart(timeSeriesData, anchor, selectedFactor, core_data, outcomeQ) {
    
    // Map to store aggregated data
    const aggregatedData = {};

    // Get category options
    const categories = core_data.categories[0].categories;
    const anchorCategory = categories.find(cat => cat.id === anchor);
    if (!anchorCategory) {
        throw new Error(`Anchor category with id ${anchor} not found.`);
    }

    const categoryOptions = {};
    for (const option of anchorCategory.options) {
        categoryOptions[option.id] = option.name;
    }
    const categoryNames = Object.values(categoryOptions);

    // Build question map and selectedQuestionKeys
    const questionMap = {};
    const selectedQuestionKeys = new Set();

    outcomeQ.questions.forEach((category) => {
        if (String(category.id) === String(selectedFactor.id)) {
            category.questions.forEach((qObj) => {
                const qKey = `${String(qObj.q)}_${String(qObj.s)}`; // Ensure q and s are strings
                questionMap[qKey] = {
                    text: qObj.question
                };
                selectedQuestionKeys.add(qKey);
            });
        }
    });

    // Initialize aggregated data structure
    for (const categoryName of categoryNames) {
        aggregatedData[categoryName] = {};
    }

    // Helper function to parse date strings like 'Q1 2024' into comparable values
    function parseDateString(dateStr) {
        const [quarterStr, yearStr] = dateStr.split(' ');
        const quarter = parseInt(quarterStr.replace('Q', ''));
        const year = parseInt(yearStr);
        return { year, quarter };
    }

    // Collect all dates and sort them from oldest to newest
    const allDatesSet = new Set(timeSeriesData.map(tp => tp.date));
    const allDates = Array.from(allDatesSet);
    allDates.sort((a, b) => {
        const dateA = parseDateString(a);
        const dateB = parseDateString(b);
        return dateA.year !== dateB.year ? dateA.year - dateB.year : dateA.quarter - dateB.quarter;
    });

    // Process each time point
    for (const timePoint of timeSeriesData) {
        const { date, responses } = timePoint;

        // Prepare data structures for this date
        for (const categoryName of categoryNames) {
            if (!aggregatedData[categoryName][date]) {
                aggregatedData[categoryName][date] = {
                    responses: [],
                    questionResponses: {}
                };
            }
        }

        // Process responses for this time point
        for (const responseEmp of responses) {
            const response = responseEmp.response;
            // Find the value of the anchor variable (category option)
            const categoryResponse = response.categories.find(cat => cat.id === anchor);
            if (!categoryResponse || !categoryResponse.response) {
                // If no response for the anchor category, skip this response
                continue;
            }
            const categoryOptionId = categoryResponse.response;
            const categoryOptionName = categoryOptions[categoryOptionId];

            if (!categoryOptionName) {
                // If the option ID is not found, skip this response
                continue;
            }

            const dataEntry = aggregatedData[categoryOptionName][date];

            // Extract responses to selected outcome's questions
            const responsesArray = [];
            const questionResponses = {}; // Holds response per question

            // Access employee_outcomes
            const employeeOutcome = response.employee_outcomes;
            if (employeeOutcome && employeeOutcome.responses) {
                const outcomeResponses = employeeOutcome.responses || [];
                for (const question of outcomeResponses) {
                    const qKey = `${String(question.q)}_${String(question.s)}`; // Ensure q and s are strings
                    if (selectedQuestionKeys.has(qKey)) {
                        let resp = question.response;
                        if (resp !== null && resp !== undefined) {
                            responsesArray.push(resp);

                            // Store response per question
                            const questionText = questionMap[qKey].text;
                            if (!questionResponses[questionText]) {
                                questionResponses[questionText] = [];
                            }
                            questionResponses[questionText].push(resp);
                        }
                    }
                }
            }

            if (responsesArray.length > 0) {
                // Compute the average score for this response (across the selected outcome's questions)
                const avgScore = responsesArray.reduce((a, b) => a + b, 0) / responsesArray.length;
                // Add the score to the list for the corresponding category option
                dataEntry.responses.push(avgScore);

                // Store per-question responses
                for (const [questionText, resps] of Object.entries(questionResponses)) {
                    if (!dataEntry.questionResponses[questionText]) {
                        dataEntry.questionResponses[questionText] = [];
                    }
                    dataEntry.questionResponses[questionText].push(...resps);
                }
            }
        }
    }

    // Now compute statistics over time for each category option
    // Build output similar to calculate_longitudinal_chart

    // Initialize output
    const output = {
        series: {},         // Contains overall statistics per category over time
        questionSeries: {}  // Contains per-question statistics per category over time
    };

    // Initialize series for each category and statistical measure
    for (const categoryName of categoryNames) {
        output.series[categoryName] = {
            'Average Score': [],
            'Max Score': [],
            'Min Score': [],
            'Standard Deviation': [],
            'Distribution': []
        };
        output.questionSeries[categoryName] = {};
        for (const questionText of Object.values(questionMap).map(q => q.text)) {
            output.questionSeries[categoryName][questionText] = {
                'Average Score': [],
                'Max Score': [],
                'Min Score': [],
                'Standard Deviation': [],
                'Distribution': []
            };
        }
    }

    // Now, for each date, compute statistics and populate output
    for (const date of allDates) {
        for (const categoryName of categoryNames) {
            const dataEntry = aggregatedData[categoryName][date];

            // Process overall statistics
            const stats = output.series[categoryName];
            if (dataEntry && dataEntry.responses.length > 0) {
                const scores = dataEntry.responses;
                const mean = scores.reduce((a, b) => a + b, 0) / scores.length;
                const max = Math.max(...scores);
                const min = Math.min(...scores);
                const variance = scores.reduce((sum, val) => sum + (val - mean) ** 2, 0) / scores.length;
                const std = Math.sqrt(variance);

                stats['Average Score'].push({ x: date, y: mean });
                stats['Max Score'].push({ x: date, y: max });
                stats['Min Score'].push({ x: date, y: min });
                stats['Standard Deviation'].push({ x: date, y: std });

                // Compute distribution
                const distribution = Array(11).fill(0); // Responses from 0 to 10
                for (const score of scores) {
                    if (score >= 0 && score <= 10) {
                        distribution[Math.round(score)] += 1;
                    }
                }
                stats['Distribution'].push({ x: date, y: distribution });
            } else {
                // No data for this date
                stats['Average Score'].push({ x: date, y: null });
                stats['Max Score'].push({ x: date, y: null });
                stats['Min Score'].push({ x: date, y: null });
                stats['Standard Deviation'].push({ x: date, y: null });
                stats['Distribution'].push({ x: date, y: null });
            }

            // Process per-question statistics
            for (const questionText of Object.values(questionMap).map(q => q.text)) {
                const qStats = output.questionSeries[categoryName][questionText];
                if (dataEntry && dataEntry.questionResponses[questionText]) {
                    const qScores = dataEntry.questionResponses[questionText];
                    const qMean = qScores.reduce((a, b) => a + b, 0) / qScores.length;
                    const qMax = Math.max(...qScores);
                    const qMin = Math.min(...qScores);
                    const qVariance = qScores.reduce((sum, val) => sum + (val - qMean) ** 2, 0) / qScores.length;
                    const qStd = Math.sqrt(qVariance);

                    qStats['Average Score'].push({ x: date, y: qMean });
                    qStats['Max Score'].push({ x: date, y: qMax });
                    qStats['Min Score'].push({ x: date, y: qMin });
                    qStats['Standard Deviation'].push({ x: date, y: qStd });

                    // Compute distribution
                    const qDistribution = Array(11).fill(0); // Responses from 0 to 10
                    for (const qScore of qScores) {
                        if (qScore >= 0 && qScore <= 10) {
                            qDistribution[Math.round(qScore)] += 1;
                        }
                    }
                    qStats['Distribution'].push({ x: date, y: qDistribution });
                } else {
                    // No data for this date
                    qStats['Average Score'].push({ x: date, y: null });
                    qStats['Max Score'].push({ x: date, y: null });
                    qStats['Min Score'].push({ x: date, y: null });
                    qStats['Standard Deviation'].push({ x: date, y: null });
                    qStats['Distribution'].push({ x: date, y: null });
                }
            }
        }
    }

    return output;
}




  export function aggregateResponsesByQuarter(dataArray,survey_version) {
    // Initialize an empty object to hold the aggregated data
    let aggregatedData = {};
    // Iterate over the dataArray
    dataArray.forEach(item => {
        if(item.question_sort_order !== survey_version.sort_order){
                return ;
        }
        // Parse the date string to get year and month
        let dateParts = item.date.split('-'); // e.g., ['2024','04','23']
        let year = dateParts[0];
        let month = parseInt(dateParts[1], 10); // Convert month to integer

        // Determine the quarter
        let quarter;
        if (month >= 1 && month <= 3) {
            quarter = 'Q1';
        } else if (month >= 4 && month <= 6) {
            quarter = 'Q2';
        } else if (month >= 7 && month <= 9) {
            quarter = 'Q3';
        } else if (month >= 10 && month <= 12) {
            quarter = 'Q4';
        } else {
            // Invalid month
            console.error('Invalid month in date:', item.date);
            return;
        }

        // Build the key 'Q{quarter} {year}'
        let key = `${quarter} ${year}`;

        // If the key doesn't exist in aggregatedData, initialize it
        if (!aggregatedData[key]) {
            aggregatedData[key] = {
                date: key,
                responses: []
            };
        }

        // Append the 'responses' array to aggregatedData[key].responses
        aggregatedData[key].responses = aggregatedData[key].responses.concat(item.responses);
    });

    // Convert the aggregatedData object to an array and return it
    return Object.values(aggregatedData);
}

