import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";
import typography, { applyTypography } from "styles/typography";

const StyledText = styled.span`
  font-family: ${typography.fontFamily};
  font-size: ${({ size }) => size || typography.body.regular.fontSize};
  font-weight: ${({ weight }) => weight || typography.body.regular.fontWeight};
  color: ${({ color }) => color || colors.text.dark};

  /* Use the typography helper */
  ${({ typographyStyle }) =>
    applyTypography(typographyStyle || typography.body.regular)}
`;

const Text = ({
  children,
  size,
  weight,
  color,
  className,
  typographyStyle,
}) => {
  return (
    <StyledText
      typographyStyle={typographyStyle}
      size={size}
      weight={weight}
      color={color}
      className={className}
    >
      {children}
    </StyledText>
  );
};

Text.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.string,
  weight: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
};

export default Text;
