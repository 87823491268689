import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PDF from "components/PDF";
import DEIPDF from "components/DEI-PDF";
import { Loader } from "semantic-ui-react";

const GenerateWindow = () => {
  const [props, setProps] = useState();

  useEffect(() => {
    const bc = new BroadcastChannel("pdf_channel");
    bc.postMessage({ windowOpen: true });

    bc.onmessage = (event) => {
      if (event.data?.coverSubtitle && !props) {
        setProps(event.data);
      }
    };
  }, []);

  console.log(props);
  return (
    <>
      {props && !props?.edi && (
        <HiddenComponent>
          <PDF {...props} triggeredGenerate={true} />
        </HiddenComponent>
      )}
      {props && props?.edi && (
        <HiddenComponent>
          <DEIPDF {...props} triggeredGenerate={true} />
        </HiddenComponent>
      )}
      <div
        style={{
          position: "absolute",
          backgroundColor: "white",
          height: "100%",
          width: "100%",
          zIndex: 1000,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <SurveyVersions hidden={true} /> */}
        <LoaderSection>
          <LoadingWrapper>
            <Loader active size="medium" />
          </LoadingWrapper>
          <LoaderText>Generating your pdf...</LoaderText>
          <LoaderText>
            This window will close automatically when complete
          </LoaderText>
        </LoaderSection>
      </div>
    </>
  );
};;;

const LoadingWrapper = styled.div`
  height: 70px;
  margin-bottom: 10px;
`;

const HiddenComponent = styled.div`
  // display: none;
  z-index: -1000;
  position: absolute;
`;

const LoaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 250px);
`;

const LoaderText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7e7e7e;
  margin-top: 10px;
`;


export default GenerateWindow;
