import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Components
import IconWithBackground from "new_components/molecules/IconWithBackground";
import Text from "new_components/atoms/Text";
import ImageButton from "new_components/molecules/ImageButton";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Card Wrapper
const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  background-color: ${colors.primary[50]};
  border-radius: 12px;
  transition: box-shadow 0.2s ease;
  width: 100%;
  cursor: ${({ isReport }) => (isReport ? "pointer" : "default")};
`;

// Content Section
const Content = styled.div`
  flex: 1;
`;

// Footer Section
const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Card = ({
  iconName,
  iconBgColor,
  title,
  description,
  linkText,
  reportLength,
  onClick,
}) => {
  return (
    <CardWrapper
      isReport={reportLength}
      onClick={onClick && reportLength && onClick}
    >
      {/* Header */}
      <IconWithBackground name={iconName} bgColor={iconBgColor} />
      <Text typographyStyle={typography.head.semibold}>{title}</Text>

      {/* Content */}
      <Content>
        <Text
          typographyStyle={typography.caption.regular}
          color={colors.text.gray}
        >
          {description}
        </Text>
      </Content>

      {/* Footer */}
      <Footer>
        {linkText ? (
          <ImageButton
            text={linkText}
            icon={ICON_SET.chevronRight}
            iconPosition="right"
            textStyle
            onClick={onClick}
            color={colors.primary[500]}
          />
        ) : (
          <Text
            typographyStyle={typography.smallText.regular}
            color={colors.text.gray}
          >
            Report length:{" "}
            <Text typographyStyle={typography.smallText.semibold}>
              {reportLength}
            </Text>
          </Text>
        )}
      </Footer>
    </CardWrapper>
  );
};

Card.propTypes = {
  iconName: PropTypes.string.isRequired, // Icon name for the header
  iconBgColor: PropTypes.string, // Background color for the icon
  title: PropTypes.string.isRequired, // Card title
  description: PropTypes.string, // Card description
  linkText: PropTypes.string, // Text for the link button
  reportLength: PropTypes.string, // Report length (optional, used if no linkText)
};

Card.defaultProps = {
  iconBgColor: colors.primary[50],
  description: "",
  linkText: null,
  reportLength: null,
};

export default Card;
