const light = {
  primary: {
    50: "#EBF1FD",
    100: "#D5E2F9",
    500: "#2D70E2",
    600: "#2254AA",
  },
  secondary: {
    50: "#EAFBF7",
    100: "#D4F5ED",
    500: "#27CDA7",
    600: "#1D9A7D",
  },
  auxiliary: {
    red: {
      50: "#FEF3F4",
      100: "#FCDCE0",
      500: "#F15062",
    },
    yellow: {
      50: "#FFFAF1",
      100: "#FFEDCF",
      500: "#FEA711",
    },
  },
  neutral: {
    200: "#F2F2F2",
    500: "#DFDFDF",
    600: "#B3B3B3",
  },
  badge: {
    neutral: {
      100: "#F2F2F2",
      500: "#666D79",
    },
  },
  background: {
    lightBlue: "#F8FAFF",
    white: "#FFFFFF",
  },
  text: {
    dark: "#2A3039",
    gray: "#666D79",
  },
  gradients: {
    gray: "#666D79",
    blue: "#3F86FE",
    multicolor: "#F8A8B0, #FEC8DD, #95E6D2",
    greenBlue: "#27CDA7, #2D70E2",
  },
};

const dark = {
  primary: {
    50: "#1C2A3A",
    100: "#233B55",
    500: "#4A91E2",
    600: "#3672AA",
  },
  secondary: {
    50: "#16332C",
    100: "#1E463B",
    500: "#27CDA7",
    600: "#1D9A7D",
  },
  auxiliary: {
    red: {
      50: "#3A1C1E",
      100: "#54282C",
      500: "#F15062",
    },
    yellow: {
      50: "#3A2A16",
      100: "#55411E",
      500: "#FEA711",
    },
  },
  neutral: {
    200: "#2A2A2A",
    500: "#4D4D4D",
    600: "#7D7D7D",
  },
  badge: {
    neutral: {
      100: "#2A2A2A",
      500: "#4D4D4D",
    },
  },
  background: {
    lightBlue: "#141A21",
    white: "#0D0D0D",
  },
  text: {
    dark: "#E6E6E6",
    gray: "#A6A6A6",
  },
  gradients: {
    gray: "#4D4D4D",
    blue: "#3F86FE",
    multicolor: "#F8A8B0, #FEC8DD, #95E6D2",
    greenBlue: "#27CDA7, #4A91E2",
  },
};

const colorBlindFriendly = {
  primary: {
    50: "#E5F5E0", // Light green
    100: "#A1D99B", // Green
    500: "#31A354", // Strong green
    600: "#006D2C", // Dark green
  },
  secondary: {
    50: "#FEE8C8", // Light orange
    100: "#FDAE6B", // Orange
    500: "#E6550D", // Strong orange
    600: "#A63603", // Dark orange
  },
  auxiliary: {
    red: {
      50: "#FEE0D2", // Light salmon
      100: "#FC9272", // Salmon
      500: "#DE2D26", // Strong red
    },
    yellow: {
      50: "#FFF7BC", // Light yellow
      100: "#FEC44F", // Yellow-orange
      500: "#D95F0E", // Dark orange-yellow
    },
  },
  neutral: {
    200: "#E5E5E5", // Light gray
    500: "#BDBDBD", // Medium gray
    600: "#636363", // Dark gray
  },
  badge: {
    neutral: {
      100: "#E5E5E5", // Light gray
      500: "#737373", // Medium-dark gray
    },
  },
  background: {
    lightBlue: "#F7FBFF", // Very light blue
    white: "#FFFFFF", // White
  },
  text: {
    dark: "#252525", // Dark gray
    gray: "#525252", // Medium gray
  },
  gradients: {
    gray: "#636363",
    blue: "#41B6C4", // Teal blue gradient
    multicolor: "#FDD49E, #FD8D3C, #E31A1C", // Distinguishable red-orange gradient
    greenBlue: "#41AB5D, #225EA8", // Green to blue gradient
  },
};

export const getColorTheme = (darkMode, isColorBlind = false) => {
  if (isColorBlind) {
    return {
      ...light, // Use light or dark as a base, ensuring all colors are overridden
      ...colorBlindFriendly,
      ...(darkMode && { ...dark, ...colorBlindFriendly }), // Merge dark mode overrides if needed
    };
  }
  return darkMode ? dark : light;
};