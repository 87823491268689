import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";
import typography from "styles/typography";
import Text from "new_components/atoms/Text";

const Row = styled.tr`
  &:hover {
    background-color: ${colors.background.lightBlue};

    td svg {
      stroke: ${colors
        .primary[500]}; // Change the stroke color of SVGs on row hover
    }
  }
`;

const Cell = styled.td`
  padding: 0.75rem;
  text-align: left;

  svg {
    stroke: ${colors.text.gray}; // Default stroke color for SVGs
    transition: stroke 0.3s ease; // Smooth transition for hover effect
  }
`;

const TableRow = ({ rowData }) => {
  return (
    <Row>
      {Object.entries(rowData).map(([key, value]) => (
        <Cell key={key}>
          {React.isValidElement(value) ? (
            value
          ) : (
            <Text
              color={colors.text.dark}
              typographyStyle={typography.caption.semibold}
            >
              {value}
            </Text>
          )}
        </Cell>
      ))}
    </Row>
  );
};

TableRow.propTypes = {
  rowData: PropTypes.object.isRequired, // Data for the row
};

export default TableRow;