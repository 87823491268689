import React, { useState, useEffect } from 'react';

function App() {
  const [conversationId, setConversationId] = useState('test-convo-1');
  const [prompt, setPrompt] = useState('');
  const [codeOutput, setCodeOutput] = useState('');
  const [messages, setMessages] = useState([]); // to store entire conversation
  const [wsConnection, setWsConnection] = useState(null);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:8080');
    ws.onopen = () => {
      console.log('Connected to WebSocket server.');
      setWsConnection(ws);
    };
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.type === 'CODE_TOKEN') {
        // Append token to current output
        setCodeOutput((prev) => prev + data.token);
      } else if (data.type === 'END_OF_STREAM') {
        // The assistant's message has finished streaming
        console.log('Stream ended');
        // Optionally, fetch the updated conversation from the server,
        // or maintain your own conversation state in the client.
      } else if (data.type === 'ERROR') {
        console.error('Error:', data.message);
      }
    };
    ws.onclose = () => {
      console.log('WebSocket connection closed.');
    };
    return () => {
      ws.close();
    };
  }, []);

  const handleGenerateCode = () => {
    if (!wsConnection || wsConnection.readyState !== WebSocket.OPEN) {
      console.error('WebSocket not connected');
      return;
    }
    // Clear code output for this new request
    setCodeOutput('');
    // Send the request
    wsConnection.send(
      JSON.stringify({
        type: 'GENERATE_CODE',
        conversationId,  // Ties this request to a conversation
        prompt,
      })
    );
    // You might also add the user message to a local messages array if you wish
    setMessages((prev) => [...prev, { role: 'user', content: prompt }]);
    setPrompt(''); // reset prompt field
  };

  return (
    <div style={{ padding: '2rem' }}>
      <h1>Copilot with Memory Demo</h1>
      <div>
        <label>Conversation ID: </label>
        <input
          type="text"
          value={conversationId}
          onChange={(e) => setConversationId(e.target.value)}
        />
      </div>
      <br />
      <textarea
        rows={4}
        cols={50}
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
        placeholder="Your prompt..."
      />
      <div>
        <button onClick={handleGenerateCode}>Generate Code</button>
      </div>
      <hr />
      <h2>Streamed Assistant Output</h2>
      <div style={{ border: '1px solid #ccc', padding: '1rem' }}>
        <pre>{codeOutput}</pre>
      </div>
      <hr />
      <h2>Local Messages (optional)</h2>
      <div style={{ border: '1px solid #ccc', padding: '1rem', height: '100px', overflow: 'auto' }}>
        {messages.map((m, idx) => (
          <div key={idx}>
            <strong>{m.role}:</strong> {m.content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
