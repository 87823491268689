import { createMedia } from "@artsy/fresnel";
import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useNavigate, useLocation, Route, Outlet } from "react-router-dom";

import PageShell from "./PageShell";
import SideBar from "./SideBar";

import MobileNav from "./MobileNav";
import TopBar from "./TopBar";
import Help from "./Help";
import Checklist from "./onboarding";

import ErrorWrapper from "error/index";

import { SG_FETCH_USER_EMPLOYEE, CLEAR_PASSWORD_DATA } from "constants/actions";

import { DomainContext } from "layouts/LayoutContext";
import { DomainList } from "data/live/domain";

const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 0,
    tablet: 768,
    computer: 1024,
  },
});

const ComplianceCheck = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const [authEmployeeId, setAuthEmployeeId] = useState(false);
  const [authEmployee, setAuthEmployee] = useState(false);
  const [ambassadorId, setAmbassadorId] = useState(false);

  // const [organization, setOrganization] = useState(false);

  const { get_auth, get_employees, get_error, get_organizations } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_employees: state.employees,
      get_error: state.errors,
      // get_organizations: state.organizations
    }),
    shallowEqual
  );

  useEffect(() => {
    if (get_auth?.token) {
      setAuthEmployeeId(get_auth.employee_id);
      // setOrganization(get_organizations[get_auth.organization_id])
    }
  }, [get_auth, get_organizations]);

  // console.log(get_error)
  // console.log("select simple layout", location)
  useEffect(() => {
    if (
      get_error?.status == 401 ||
      get_error?.msg == "Request failed with status code 401" ||
      get_error?.msg?.message == "Request failed with status code 401"
    ) {
      dispatch({
        type: CLEAR_PASSWORD_DATA,
      });
      if (location.pathname !== "/account/login") {
        return navigate("/account/login", { state: { from: location } });
      }
    }
  }, [dispatch, get_error, navigate, location]);

  useEffect(() => {
    if (get_auth?.token) {
      setAmbassadorId(get_auth.ambassador_id);
    }
  }, [get_auth]);

  useEffect(() => {
    // if the response is ok 200
    // console.log("SG_FETCH_USER_EMPLOYEE here", employeeId)
    // console.log(employee)
    if (!authEmployee) {
      if (Number(authEmployeeId) > 0) {
        dispatch({
          type: SG_FETCH_USER_EMPLOYEE,
          payload: { id: authEmployeeId },
        });
      }
    }
  }, [dispatch, authEmployeeId, authEmployee]);

  useEffect(() => {
    if (get_auth?.token) {
      if (authEmployeeId) {
        setAuthEmployee(get_employees.userEmp);
        // props.setEmployee1(get_employees[authEmployeeId])
      }
    }
  }, [dispatch, get_employees, authEmployeeId, props, get_auth]);

  // if (get_auth?.token) {
  //   if (Number(get_auth?.organization_id) === 0) {

  //     if (location.pathname !== "/ambassador") {
  //       return navigate("/ambassador");
  //     }
  //   }
  //  if (employee?.email_status === 9) {
  //     if (location.pathname !== "/app/dashboard") {
  //      return navigate("/app/dashboard");
  //     }
  //   }
  // }

  // if (location.pathname !== '/account/logout') {
  //   navigate("/account/logout")
  // }
  // if (!get_auth?.token){
  //   if (location.pathname !== '/account/login') {
  //     navigate("/account/login")
  //   }
  // }

  return props.children;

  //return get_auth?.token? <WebSite props={props} /> :  <WebSite props={props} />
};
/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */

const MobileContainer = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [help, setHelp] = useState(false);

  return (
    <>
      {/* Desktop View */}
      <Media
        greaterThan="mobile"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <SideBar />

        <Top>
          <Logo>
            <Circle>{AiIcon()}</Circle>
            innerlogic
          </Logo>
        </Top>

        {/* <Checklist/> */}
        <div
          style={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <div
            style={{
              maxWidth: 1400,
              width: "100vw",
              transition: "all 0.5s",
              paddingTop: 75,
            }}
          >
            <ErrorWrapper>
              <Outlet />
            </ErrorWrapper>
          </div>
          {/* <Help open={help} setHelp={()=>setHelp(false)}/> */}
        </div>
      </Media>

      {/* Mobile View */}
      <Media at="mobile" style={{ position: "relative" }}>
        <MobileNav />
        <div
          style={{
            width: "100%",
            maxWidth: "100%",
            overflowX: "hidden",
          }}
        >
          <Outlet />
        </div>
      </Media>
    </>
  );
};

const ResponsiveContainer = ({ children }) => {
  const domain = window.location.host;
  // console.log(domain)
  return (
    <MediaContextProvider>
      <DomainContext.Provider
        value={
          DomainList?.[domain]
            ? DomainList?.[domain]
            : Object.values(DomainList)[0]
        }
      >
        <ComplianceCheck>
          <MobileContainer>{children}</MobileContainer>
        </ComplianceCheck>
      </DomainContext.Provider>
    </MediaContextProvider>
  );
};

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
};

export default ResponsiveContainer;

const Top = styled.div`
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  font-family: "Raleway";
  font-size: 18px;
  font-weight: bold;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: white;
`;
const Circle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-color: #2d70e2;
  margin-right: 10px;
  min-width: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: -1;
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

function AiIcon(fill) {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1389 18.7668C18.9017 18.7668 18.7021 18.6829 18.5398 18.5151C18.3776 18.3473 18.2965 18.1409 18.2965 17.8956V9.59098C18.2965 9.34577 18.3776 9.13929 18.5398 8.97152C18.7021 8.79084 18.9017 8.7005 19.1389 8.7005C19.3885 8.7005 19.5944 8.78439 19.7566 8.95216C19.9189 9.11993 20 9.33287 20 9.59098V17.8956C20 18.1409 19.9126 18.3473 19.7379 18.5151C19.5757 18.6829 19.376 18.7668 19.1389 18.7668Z"
        fill="white"
      />
      <path
        d="M18.5331 6.71753C18.6908 6.88064 18.8801 6.9622 19.1009 6.9622H19.1956C19.4164 6.9622 19.6057 6.88064 19.7634 6.71753C19.9211 6.55442 20 6.35868 20 6.13033V6.06508C20 5.83672 19.9211 5.64099 19.7634 5.47787C19.6057 5.31476 19.4164 5.23321 19.1956 5.23321H19.1009C18.8801 5.23321 18.6908 5.31476 18.5331 5.47787C18.3753 5.64099 18.2965 5.83672 18.2965 6.06508V6.13033C18.2965 6.35868 18.3753 6.55442 18.5331 6.71753Z"
        fill="white"
      />
      <path
        d="M0.861115 5.2374C1.09823 5.2374 1.29791 5.32122 1.46015 5.48888C1.62239 5.65653 1.70351 5.86287 1.70351 6.1079V14.4067C1.70351 14.6517 1.62239 14.8581 1.46015 15.0257C1.29791 15.2063 1.09823 15.2965 0.861115 15.2965C0.611516 15.2965 0.405596 15.2127 0.243357 15.0451C0.0811174 14.8774 0 14.6646 0 14.4067V6.1079C0 5.86287 0.0873585 5.65653 0.262078 5.48888C0.424317 5.32122 0.623996 5.2374 0.861115 5.2374Z"
        fill="white"
      />
      <path
        d="M1.46693 17.2792C1.3092 17.1162 1.11992 17.0347 0.89909 17.0347H0.804451C0.583623 17.0347 0.394341 17.1162 0.236607 17.2792C0.0788722 17.4422 5.03191e-06 17.6378 5.03191e-06 17.866V17.9312C5.03191e-06 18.1594 0.0788722 18.355 0.236607 18.518C0.394341 18.681 0.583623 18.7625 0.804451 18.7625H0.89909C1.11992 18.7625 1.3092 18.681 1.46693 18.518C1.62467 18.355 1.70354 18.1594 1.70354 17.9312V17.866C1.70354 17.6378 1.62467 17.4422 1.46693 17.2792Z"
        fill="white"
      />
      <path
        d="M13.7232 2.62093C13.7232 2.13448 14.1046 1.74013 14.575 1.74013C15.0454 1.74013 15.4267 2.13448 15.4267 2.62093V21.379C15.4267 21.8655 15.0454 22.2598 14.575 22.2598C14.1046 22.2598 13.7232 21.8655 13.7232 21.379V2.62093Z"
        fill="white"
      />
      <path
        d="M4.57663 2.62093C4.57663 2.13448 4.95798 1.74013 5.42839 1.74013C5.8988 1.74013 6.28015 2.13448 6.28015 2.62093V21.379C6.28015 21.8655 5.8988 22.2598 5.42839 22.2598C4.95798 22.2598 4.57663 21.8655 4.57663 21.379V2.62093Z"
        fill="white"
      />
      <path
        d="M9.1499 0.880798C9.1499 0.394347 9.53125 0 10.0017 0C10.4721 0 10.8534 0.394347 10.8534 0.880799V10.2502C10.8534 10.7366 10.4721 11.131 10.0017 11.131C9.53125 11.131 9.1499 10.7366 9.1499 10.2502V0.880798Z"
        fill="white"
      />
      <path
        d="M9.1499 13.7498C9.1499 13.2634 9.53125 12.869 10.0017 12.869C10.4721 12.869 10.8534 13.2634 10.8534 13.7498V23.1192C10.8534 23.6056 10.4721 24 10.0017 24C9.53125 24 9.1499 23.6056 9.1499 23.1192V13.7498Z"
        fill="white"
      />
    </svg>
  );
}
