import React, { useState, useMemo } from "react";
import styled from "styled-components";
import TableHeader from "new_components/molecules/TableHeader";
import TableRow from "new_components/molecules/TableRow";
import colors from "styles/colors";

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    background-color: ${colors.background.white};
    border-bottom: 2px solid ${colors.neutral[200]};
  }

  tbody tr:nth-child(odd) {
    background-color: ${colors.neutral[100]};
  }
`;

const Table = ({ columns, data, filteredProperties = [] }) => {
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  const onSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = useMemo(() => {
    if (!sortConfig.key) return data;

    const column = columns.find((col) => col.key === sortConfig.key);
    if (!column || !column.sortFunction) return data;

    return [...data].sort((a, b) =>
      column.sortFunction(a, b, sortConfig.direction)
    );
  }, [data, sortConfig, columns]);

  const filteredData = useMemo(() => {
    return sortedData.map((row) => {
      const filteredRow = { ...row };
      filteredProperties.forEach((prop) => {
        delete filteredRow[prop];
      });
      return filteredRow;
    });
  }, [sortedData, filteredProperties]);

  return (
    <TableWrapper>
      <StyledTable>
        <thead>
          <tr>
            {columns?.map((col) => (
              <TableHeader
                key={col.key}
                label={col.label}
                isSortable={col.isSortable}
                sortDirection={
                  sortConfig.key === col.key ? sortConfig.direction : null
                }
                onSort={() => col.isSortable && onSort(col.key)}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredData?.map((row, index) => (
            <TableRow key={index} rowData={row} />
          ))}
        </tbody>
      </StyledTable>
    </TableWrapper>
  );
};

export default Table;
