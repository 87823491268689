import React from "react";
import ReactApexChart from "react-apexcharts";

const ScaleChart = ({ questionData, responses, chartTitle = "Scale Responses" }) => {
  // Determine the max score (5 or 10)
  let maxScore = 5;
  if (questionData.type === "scale") {
    if (questionData.scale === "0-5") {
      maxScore = 5;
    } else if (questionData.scale === "0-10") {
      maxScore = 10;
    }
  }

  // Initialize counts array, one slot for each possible score from 0 to maxScore
  const counts = Array(maxScore + 1).fill(0);

  // Populate counts array from responses
  // For example, if a response has "task_list[0].response = 3", increment counts[3]
  responses.forEach((r) => {
    const score = r?.task_list?.[0]?.response;
    if (typeof score === "number" && score >= 0 && score <= maxScore) {
      counts[score] += 1;
    }
  });

  // X-axis labels: "0", "1", "2", etc.
  const categories = counts.map((_, idx) => `${idx}`);

  // Series for ApexCharts: single series with the count data
  const series = [
    {
      name: "Responses",
      data: counts,
    },
  ];

  // Calculate the maximum count to help scale the y-axis nicely
  const maxCount = Math.max(...counts);

  // Chart styling (inspired by your snippet)
  const options = {
    chart: {
      height: 400,
      type: "bar",
      id: "my-chart",
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    colors: ["#2D70E2"], // Bar color
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: "70%",
      },
    },
    legend: {
      show: false,
    },
    fill: {
      type: "solid",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories,
      title: {
        text: "Score", // X-axis title
      },
      labels: {
        style: {
          fontFamily: "Raleway, sans-serif",
        },
      },
    },
    yaxis: {
      // We set max to either the highest count or 1 to avoid a zero max
      max: maxCount < 5 ? 5 : maxCount,
      min: 0,
      labels: {
        style: {
          fontFamily: "Raleway, sans-serif",
        },
      },
      title: {
        text: "Number of Responses",
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        // Show raw counts (e.g., "5")
        return val;
      },
      style: {
        fontSize: "14px",
        fontFamily: "Raleway, sans-serif",
        colors: ["#FFFFFF"], // White text inside the bar
      },
      offsetY: 0,
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.3,
      },
    },
    tooltip: {
      enabled: true,
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        // Provide additional context if you wish
        const scoreValue = w.globals.labels[dataPointIndex];
        const countValue = series[seriesIndex][dataPointIndex];
        return `
          <div style="padding: 8px; background-color: white; border: 1px solid #e0e0e0; border-radius: 5px; font-family: Raleway, sans-serif;">
            <div style="font-size: 14px; font-weight: 500; color: #333;">
              Score: ${scoreValue} <br />
              Responses: ${countValue}
            </div>
          </div>
        `;
      },
    },
    title: {
      text: chartTitle,
      align: "center",
      style: {
        fontFamily: "Raleway, sans-serif",
      },
    },
  };

  return (
    <div style={{ width: "100%" }}>
      <ReactApexChart options={options} series={series} type="bar" height={400} />
    </div>
  );
};

export default ScaleChart;
