import { call, put, all, takeLatest } from "redux-saga/effects";
import * as api from "constants/api";

import {
  DEBRIEF_RESPONSE_VECTOR_DB_PENDING,
  GET_DEBRIEF_RESPONSE_VECTOR_DB,
  SG_GET_DEBRIEF_RESPONSE_VECTOR_DB,
  FETCH_DEBRIEF_RESPONSE_VECTOR_DB,
  SG_FETCH_DEBRIEF_RESPONSE_VECTOR_DB,
  ALL_ERRORS,
} from "constants/actions";

function* loadDebriefResponseVectors(action) {
  try {
    yield put({ type: DEBRIEF_RESPONSE_VECTOR_DB_PENDING });
    const json = yield call(api.GET_DEBRIEF_RESPONSE_VECTOR_DB, action.payload);
    yield put({ type: GET_DEBRIEF_RESPONSE_VECTOR_DB, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* debriefResponseVectorLoad() {
  yield takeLatest(SG_GET_DEBRIEF_RESPONSE_VECTOR_DB, loadDebriefResponseVectors);
}

function* fetchDebriefResponseVectors(action) {
  try {
    const json = yield call(api.FETCH_DEBRIEF_RESPONSE_VECTOR_DB, action.payload);
    yield put({ type: FETCH_DEBRIEF_RESPONSE_VECTOR_DB, payload: json.data });
  } catch (e) {
    yield put({ type: ALL_ERRORS, payload: e.response });
  }
}

export function* debriefResponseVectorFetch() {
  yield takeLatest(SG_FETCH_DEBRIEF_RESPONSE_VECTOR_DB, fetchDebriefResponseVectors);
}

export default function* index() {
  yield all([
    debriefResponseVectorLoad(),
    debriefResponseVectorFetch(),
  ]);
}