import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";

// Utility function to resolve colors dynamically
const getColor = (colorKey, shade) => {
  const [base, sub] = colorKey.split(".");
  if (colors[base]?.[sub]?.[shade]) return colors[base][sub][shade]; // Handle auxiliary.red, auxiliary.yellow
  if (colors[base]?.[shade]) return colors[base][shade]; // Handle primary, secondary, neutral
  return colors.neutral[shade]; // Default to neutral if no match
};

// Styled container for the badge
const BadgeContainer = styled.div`
  display: inline-flex; /* Ensures the badge only takes up as much space as needed */
  flex-direction: row;
  align-items: center; /* Centers text and icon vertically */
  padding: 4px 8px;
  gap: 10px;
  background-color: ${({ color }) =>
    getColor(color, 100)}; // Use 100 shade for background
  border-radius: 8px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 125%; /* Matches the height */
  color: ${({ color }) => getColor(color, 500)}; // Use 500 shade for text
  white-space: nowrap; /* Prevents the badge from wrapping to the next line */
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px; // Adjust icon size as needed
  height: 16px;
  cursor: ${({ isClickable }) =>
    isClickable ? "pointer" : "default"}; // Show pointer if clickable
  &:hover {
    opacity: ${({ isClickable }) =>
      isClickable ? 0.8 : 1}; // Add hover effect for clickable icon
  }
`;

const Badge = ({ text, color = "secondary", icon, onIconClick }) => {
  return (
    <BadgeContainer color={color}>
      {icon && (
        <IconWrapper
          isClickable={!!onIconClick}
          onClick={onIconClick || undefined} // Attach click handler if provided
        >
          {icon}
        </IconWrapper>
      )}
      {text}
    </BadgeContainer>
  );
};

Badge.propTypes = {
  text: PropTypes.string.isRequired, // Badge text
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "auxiliary.red",
    "auxiliary.yellow",
    "neutral",
  ]), // Add auxiliary.red and auxiliary.yellow
  icon: PropTypes.node, // Optional icon component
  onIconClick: PropTypes.func, // Optional click handler for the icon
};

export default Badge;
