import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import colors from "styles/colors";
import typography from "styles/typography";
import ImageButton from "new_components/molecules/ImageButton";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";

// Import the new re-usable Tabs component
import Tabs from "new_components/molecules/Tabs";
// Import your existing BaseTemplate (or replicate its logic inline)
import BaseTemplate from "./BaseTemplate";

/* ----------------------------- Styled Components ---------------------------- */

/**
 * Optional section that sits below the Tabs.
 * (Only rendered if the user passes the prop.)
 */
const OptionalSectionWrapper = styled.div`
  margin-bottom: 16px;
`;

// Wrapper for the action row
const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
`;

/* ------------------------------- Component ------------------------------- */

const BaseTabbedTemplate = ({
  tabs,
  defaultActiveTabIndex,
  belowTabsSection, // optional content that appears below tabs but above children
  heading,
  pageTitle,
  subtitle,
  scrollable,
  pagination,
  backAction,
  children,
  tabTrigger,
}) => {
  // The "parent" (this template) owns the active tab state
  const [activeTabIndex, setActiveTabIndex] = useState(
    defaultActiveTabIndex || 0
  );

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
  };

  useEffect(() => {
    if (tabTrigger) {
      setActiveTabIndex(tabTrigger);
    }
  }, [tabTrigger]);

  // If tabs are provided, pick the active tab's content
  const activeTab = tabs && tabs.length > 0 ? tabs[activeTabIndex] : null;

  return (
    <BaseTemplate
      heading={heading}
      pageTitle={pageTitle}
      subtitle={subtitle}
      scrollable={scrollable}
      pagination={pagination}
    >
      {/* Back Action */}
      {backAction && (
        <ActionRow>
          <ImageButton
            icon={ICON_SET.chevronLeft}
            text="Back"
            color={colors.text.gray}
            textStyle={true}
            onClick={backAction}
          />
        </ActionRow>
      )}

      {/* Tabs Header */}
      {tabs && tabs.length > 0 && (
        <Tabs
          tabs={tabs}
          activeTabIndex={activeTabIndex}
          onTabChange={handleTabChange}
        />
      )}

      {/* Optional Section below Tabs */}
      {belowTabsSection && (
        <OptionalSectionWrapper>{belowTabsSection}</OptionalSectionWrapper>
      )}

      {/* Render the active tab’s content if provided; else just children */}
      {activeTab && activeTab.content ? activeTab.content : children}
    </BaseTemplate>
  );
};

/* ------------------------------- PropTypes ------------------------------- */

BaseTabbedTemplate.propTypes = {
  /**
   * An array of tab objects
   * Each tab could look like: { label: string, content: node }
   */
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      content: PropTypes.node, // Or element, depending on how you store tab content
    })
  ),
  /** Index of the tab to show by default */
  defaultActiveTabIndex: PropTypes.number,
  /** Optional content that appears below the tabs but above the child layout */
  belowTabsSection: PropTypes.node,
  /** Inherited props from BaseTemplate */
  heading: PropTypes.string,
  pageTitle: PropTypes.string,
  subtitle: PropTypes.string,
  scrollable: PropTypes.bool,
  pagination: PropTypes.node,
  /** Callback for back button */
  backAction: PropTypes.func,
  /** Child content if no active tab or tabs array empty */
  children: PropTypes.node,
};

BaseTabbedTemplate.defaultProps = {
  tabs: [],
  defaultActiveTabIndex: 0,
  belowTabsSection: null,
  heading: "",
  pageTitle: "",
  subtitle: "",
  scrollable: true,
  pagination: null,
  backAction: null,
};

export default BaseTabbedTemplate;
