/* App.js */
import React, { useState } from "react";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";

// ==================== THEME & GLOBAL STYLES ====================

const theme = {
  colors: {
    primary: "#4C6EF5",
    primaryHover: "#3D59C3",
    secondary: "#ADB5BD",
    background: "#F8F9FA",
    text: "#212529",
    white: "#FFFFFF",
    success: "#3BB54A",
    danger: "#EE5A5A",
  },
  fonts: {
    primary: "'Raleway', sans-serif",
  },
  spacing: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
  },
  borderRadius: "8px",
};

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    font-family: ${(props) => props.theme.fonts.primary};
    background-color: ${(props) => props.theme.colors.background};
    color: ${(props) => props.theme.colors.text};
  }
`;

// ==================== LAYOUT COMPONENTS ====================

const Container = styled.div`
  max-width: 900px;
  margin: 0 auto;
  padding: ${(props) => props.theme.spacing.lg};
`;

const WizardContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Header = styled.header`
  background: ${(props) => props.theme.colors.primary};
  padding: ${(props) => props.theme.spacing.md};
  color: ${(props) => props.theme.colors.white};
`;

const StepContent = styled.div`
  padding: ${(props) => props.theme.spacing.lg};
`;

const StepFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing.md};
  background: ${(props) => props.theme.colors.background};
`;

// ==================== BUTTON COMPONENTS ====================

const Button = styled.button`
  border: none;
  border-radius: ${(props) => props.theme.borderRadius};
  padding: ${(props) => props.theme.spacing.sm} ${(props) => props.theme.spacing.md};
  font-size: 0.95rem;
  cursor: pointer;
  transition: background 0.3s ease;
  color: ${(props) => (props.primary ? props.theme.colors.white : props.theme.colors.text)};
  background-color: ${(props) =>
    props.primary ? props.theme.colors.primary : props.theme.colors.secondary};

  &:hover {
    background-color: ${(props) =>
      props.primary ? props.theme.colors.primaryHover : "#CED4DA"};
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
`;

const StepIndicatorContainer = styled.div`
  display: flex;
  justify-content: space-around;
  background: #f1f3f5;
`;

const StepIndicator = styled.div`
  flex: 1;
  text-align: center;
  padding: ${(props) => props.theme.spacing.sm};
  font-size: 0.85rem;
  color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
  border-bottom: 3px solid
    ${(props) => (props.active ? props.theme.colors.primary : "transparent")};
`;

// ==================== STEP COMPONENTS ====================

function StepDefineObjective({ data, setData }) {
  return (
    <div>
      <h2>Define Your Objective</h2>
      <p>What would you like to achieve with this experiment?</p>

      <label htmlFor="objective">Objective:</label>
      <Input
        id="objective"
        type="text"
        value={data.objective || ""}
        onChange={(e) => setData({ ...data, objective: e.target.value })}
        placeholder="e.g., Increase employee engagement"
      />

      <p style={{ marginTop: "16px" }}>
        Provide a clear statement about what you want to measure or improve (e.g.,
        “Increase engagement by 10% in the Sales team”).
      </p>
    </div>
  );
}

function StepSelectGroups({ data, setData }) {
  return (
    <div>
      <h2>Select Control & Treatment Groups</h2>
      <p>How would you like to split your employees or teams?</p>

      <label htmlFor="groupSize">Treatment Group Size (%):</label>
      <Input
        id="groupSize"
        type="number"
        value={data.treatmentGroupSize || ""}
        onChange={(e) => setData({ ...data, treatmentGroupSize: e.target.value })}
        placeholder="e.g., 50"
      />

      <p style={{ marginTop: "16px" }}>
        AI can auto-assign employees to balanced groups or you can select manual assignment in your
        HR system.
      </p>
    </div>
  );
}

function StepChooseMetrics({ data, setData }) {
  return (
    <div>
      <h2>Choose Key Metrics</h2>
      <p>Select the metrics that best measure your success criteria.</p>

      <CheckBoxContainer>
        <CheckBoxLabel>
          <input
            type="checkbox"
            checked={data.metrics?.includes("Engagement")}
            onChange={(e) => {
              const newMetrics = e.target.checked
                ? [...(data.metrics || []), "Engagement"]
                : data.metrics.filter((m) => m !== "Engagement");
              setData({ ...data, metrics: newMetrics });
            }}
          />
          Engagement
        </CheckBoxLabel>

        <CheckBoxLabel>
          <input
            type="checkbox"
            checked={data.metrics?.includes("Wellbeing")}
            onChange={(e) => {
              const newMetrics = e.target.checked
                ? [...(data.metrics || []), "Wellbeing"]
                : data.metrics.filter((m) => m !== "Wellbeing");
              setData({ ...data, metrics: newMetrics });
            }}
          />
          Wellbeing
        </CheckBoxLabel>

        <CheckBoxLabel>
          <input
            type="checkbox"
            checked={data.metrics?.includes("Performance")}
            onChange={(e) => {
              const newMetrics = e.target.checked
                ? [...(data.metrics || []), "Performance"]
                : data.metrics.filter((m) => m !== "Performance");
              setData({ ...data, metrics: newMetrics });
            }}
          />
          Performance
        </CheckBoxLabel>

        <CheckBoxLabel>
          <input
            type="checkbox"
            checked={data.metrics?.includes("Financial KPIs")}
            onChange={(e) => {
              const newMetrics = e.target.checked
                ? [...(data.metrics || []), "Financial KPIs"]
                : data.metrics.filter((m) => m !== "Financial KPIs");
              setData({ ...data, metrics: newMetrics });
            }}
          />
          Financial KPIs
        </CheckBoxLabel>
      </CheckBoxContainer>
    </div>
  );
}

function StepSetTimeline({ data, setData }) {
  return (
    <div>
      <h2>Set Timeline & Milestones</h2>
      <p>Choose how long your experiment will run and add checkpoints.</p>

      <label htmlFor="startDate">Start Date:</label>
      <Input
        type="date"
        id="startDate"
        value={data.startDate || ""}
        onChange={(e) => setData({ ...data, startDate: e.target.value })}
      />

      <label htmlFor="endDate" style={{ marginTop: "16px" }}>
        End Date:
      </label>
      <Input
        type="date"
        id="endDate"
        value={data.endDate || ""}
        onChange={(e) => setData({ ...data, endDate: e.target.value })}
      />
    </div>
  );
}

function StepReview({ data }) {
  return (
    <div>
      <h2>Review & Confirm</h2>
      <ReviewCard>
        <h3>Objective</h3>
        <p>{data.objective || "N/A"}</p>
      </ReviewCard>

      <ReviewCard>
        <h3>Groups</h3>
        <p>{data.treatmentGroupSize ? `Treatment Group: ${data.treatmentGroupSize}%` : "N/A"}</p>
      </ReviewCard>

      <ReviewCard>
        <h3>Metrics</h3>
        <ul>
          {data.metrics && data.metrics.length > 0 ? (
            data.metrics.map((m) => <li key={m}>{m}</li>)
          ) : (
            <li>N/A</li>
          )}
        </ul>
      </ReviewCard>

      <ReviewCard>
        <h3>Timeline</h3>
        <p>
          {data.startDate ? `Start: ${data.startDate}` : "N/A"} &nbsp; | &nbsp;{" "}
          {data.endDate ? `End: ${data.endDate}` : "N/A"}
        </p>
      </ReviewCard>

      <p style={{ marginTop: "16px" }}>
        Once you confirm, the system will create an experiment dashboard to track results in real
        time.
      </p>
    </div>
  );
}

// ==================== MAIN WIZARD COMPONENT ====================

function Wizard() {
  const steps = [
    { label: "Objective", component: StepDefineObjective },
    { label: "Groups", component: StepSelectGroups },
    { label: "Metrics", component: StepChooseMetrics },
    { label: "Timeline", component: StepSetTimeline },
    { label: "Review", component: StepReview },
  ];

  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    objective: "",
    treatmentGroupSize: "",
    metrics: [],
    startDate: "",
    endDate: "",
  });

  const StepComponent = steps[currentStep].component;

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <WizardContainer>
      <Header>
        <h1>A/B Testing & Experimentation Wizard</h1>
      </Header>
      <StepIndicatorContainer>
        {steps.map((step, index) => (
          <StepIndicator key={step.label} active={index === currentStep}>
            {step.label}
          </StepIndicator>
        ))}
      </StepIndicatorContainer>
      <StepContent>
        <StepComponent data={formData} setData={setFormData} />
      </StepContent>
      <StepFooter>
        <Button onClick={handleBack} disabled={currentStep === 0}>
          Back
        </Button>
        {currentStep < steps.length - 1 ? (
          <Button onClick={handleNext} primary>
            Next
          </Button>
        ) : (
          <Button
            primary
            onClick={() => alert("Experiment Started!")}
          >
            Confirm & Start
          </Button>
        )}
      </StepFooter>
    </WizardContainer>
  );
}

// ==================== STYLED HELPERS ====================

const Input = styled.input`
  display: block;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing.xs};
  margin-bottom: ${(props) => props.theme.spacing.md};
  padding: ${(props) => props.theme.spacing.sm};
  border: 1px solid ${(props) => props.theme.colors.secondary};
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: 0.95rem;
`;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => props.theme.spacing.md};
  margin-top: ${(props) => props.theme.spacing.md};
`;

const CheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.spacing.xs};
  cursor: pointer;

  input[type="checkbox"] {
    transform: scale(1.2);
  }
`;

const ReviewCard = styled.div`
  background: ${(props) => props.theme.colors.background};
  padding: ${(props) => props.theme.spacing.md};
  border-radius: ${(props) => props.theme.borderRadius};
  margin-bottom: ${(props) => props.theme.spacing.md};

  h3 {
    margin: 0 0 ${(props) => props.theme.spacing.xs} 0;
  }
`;

// ==================== APP ====================

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Wizard />
      </Container>
    </ThemeProvider>
  );
}

export default App;
