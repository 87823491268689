import React, { useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Text from "new_components/atoms/Text";
import IconWithBackground from "new_components/molecules/IconWithBackground";
import colors from "styles/colors";

const SectionWrapper = styled.section`
  margin-bottom: 64px; /* Space between sections */
  scroll-margin-top: 72px; /* Offset for sticky submenu */
`;

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
`;

const ContentSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ContentSection = ({
  categories,
  type = "multi",
  activeItemId,
  headerComponent: HeaderComponent,
}) => {
  const isGrouped = categories[0]?.groupTitle !== undefined;

  // Flatten categories to a list of all items
  const getAllItems = () => {
    if (isGrouped) {
      return categories.reduce((acc, group) => {
        const groupItems = group.items.map((item) => ({
          ...item,
          groupTitle: group.groupTitle,
        }));
        return acc.concat(groupItems);
      }, []);
    } else {
      return categories;
    }
  };

  const allItems = getAllItems();

  useEffect(() => {
    if (type === "multi") {
      const handleScroll = () => {
        const sections = allItems.map(({ id }) => document.getElementById(id));

        sections.forEach((section) => {
          if (section) {
            const rect = section.getBoundingClientRect();
            if (rect.top <= 100 && rect.bottom >= 100) {
              const activeCategory = section.id;
              const event = new CustomEvent("subMenuScroll", {
                detail: activeCategory,
              });
              window.dispatchEvent(event); // Notify the SubMenu of the active category
            }
          }
        });
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [allItems, type]);

  const renderContent = () => {
    if (type === "multi") {
      // Render all content sections
      return allItems.map(
        ({ id, categoryTitle, categoryIcon, categoryContent }) => (
          <SectionWrapper key={id} id={id}>
            {/* Render custom header component if provided, else default header */}
            {HeaderComponent ? (
              <HeaderComponent />
            ) : (
              <SectionHeader>
                {categoryIcon && (
                  <IconWithBackground
                    name={categoryIcon}
                    size={24}
                    stroke={colors.primary[500]}
                    bgColor={colors.primary[50]}
                  />
                )}
                <Text
                  typographyStyle={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "150%",
                  }}
                  color={colors.text.dark}
                >
                  {categoryTitle}
                </Text>
              </SectionHeader>
            )}
            {categoryContent}
          </SectionWrapper>
        )
      );
    } else if (type === "single") {
      // Render only the active content section
      const activeCategory = allItems.find((item) => item.id === activeItemId);
      if (activeCategory) {
        const { id, categoryContent } = activeCategory;
        return (
          <SectionWrapper key={id} id={id}>
            {/* Render custom header component if provided, else default header */}
            {HeaderComponent ? (
              <HeaderComponent />
            ) : (
              <SectionHeader>
                {/* {categoryIcon && (
                  <IconWithBackground
                    name={categoryIcon}
                    size={24}
                    stroke={colors.primary[500]}
                    bgColor={colors.primary[50]}
                  />
                )} */}
                {/* <Text
                  typographyStyle={{
                    fontSize: "18px",
                    fontWeight: "600",
                    lineHeight: "150%",
                  }}
                  color={colors.text.dark}
                >
                  {categoryTitle}
                </Text> */}
              </SectionHeader>
            )}
            {categoryContent}
          </SectionWrapper>
        );
      } else {
        return <div>No content available</div>;
      }
    }
  };

  return <ContentSectionWrapper>{renderContent()}</ContentSectionWrapper>;
};

ContentSection.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // For flat list of categories
      PropTypes.shape({
        id: PropTypes.string.isRequired, // Anchor ID for the section
        categoryTitle: PropTypes.string.isRequired, // Header text
        categoryIcon: PropTypes.string, // Optional icon name
        categoryContent: PropTypes.node.isRequired, // Content under the header
      }),
      // For grouped categories
      PropTypes.shape({
        groupTitle: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired, // Anchor ID for the section
            categoryTitle: PropTypes.string.isRequired, // Header text
            categoryIcon: PropTypes.string, // Optional icon name
            categoryContent: PropTypes.node.isRequired, // Content under the header
          })
        ).isRequired,
      }),
    ])
  ).isRequired,
  type: PropTypes.oneOf(["multi", "single"]), // Layout type
  activeItemId: PropTypes.string, // Active item ID for "single" type
  headerComponent: PropTypes.elementType, // Custom header component
};

export default ContentSection;