import React from "react";
import styled, {keyframes} from "styled-components";
import { Loader, Icon } from "semantic-ui-react";
import { useSurveyContext } from "../Data/PulseBuilderContext"; // or wherever

const CardWrapper = styled.div`
  width: 450px;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 1rem;
  background-color: #f8faff;
  font-family: "Raleway", sans-serif;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
  transition: all 0.3s;
`;

const Title = styled.div`
    font-size: 14px;
    color:#2A3039;
    font-weight:600;
    width: 250px;
    max-width:80%;
`

const Description = styled.div`
    color:#666D79;
`

// If you still want a "loading" style
const CardWrapperLoading = styled(CardWrapper)`
  cursor: default;
  justify-content: flex-start;

`;

// Define the pulsing animation
const pulse = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`;

// Style the loading text
const LoadingText = styled.div`
  color: #555;
  animation: ${pulse} 1.5s infinite;
  text-align: center;
  font-size: 14px;
  margin-left:10px;
`;

// ...
export default function SurveyFunctionCard({ functionName, functionArgs, surveyId,setViewSurvey,viewSurvey }) {
  const { surveys } = useSurveyContext();

  // 1) Find the matching survey in surveys
  const matchedSurvey = surveys.find((s) => s.id === surveyId);

  // If not found, maybe we assume it's still being created
  if (!matchedSurvey) {
    return (
      <CardWrapperLoading>
        <p>{functionName}... (Preparing to create/modify)</p>
        <Loader active inline size="tiny" />
      </CardWrapperLoading>
    );
  }

  // 2) If found, check if loading or error
  if (matchedSurvey.loading) {
    return (
      <CardWrapperLoading>
        <Loader active inline size="tiny" />
        <LoadingText>
          {functionName === "create_survey"
            ? "Generating Survey..."
            : functionName === "modify_survey"
            ? "Modifying Survey..."
            : "Processing..."}
        </LoadingText>
        
      </CardWrapperLoading>
    );
  }

  if (matchedSurvey.error) {
    return (
      <CardWrapper style={{ backgroundColor: "#fee" }}>
        <p>Error: {matchedSurvey.error}</p>
      </CardWrapper>
    );
  }

  // 3) Otherwise, it's done. matchedSurvey.data holds the final JSON
  const title = matchedSurvey.data?.title || "(No title)";
  const description = matchedSurvey.data?.description || "(No description)";

  // On click, we can open a separate SurveyDetail or something
  const handleOpenSurvey = () => {
    // e.g. navigate to a SurveyDetail component, or open a modal,
    // passing the matchedSurvey so we know which to display
    console.log("Opening survey ID:", matchedSurvey.id);

    if(viewSurvey == matchedSurvey.id){
      return setViewSurvey(null)
    }

    setViewSurvey(matchedSurvey.id)
  };

  return (
    <CardWrapper onClick={handleOpenSurvey}>
    <div>
      <Title>
        {functionName === "create_survey"
          ? title
          : functionName === "modify_survey"
          ? "Survey Updated"
          : "Unknown Function"}
      </Title>

      {/* <Description>
            {description}
      </Description> */}
    </div>
      <div style={{ display: "flex", alignItems: "center",color:'#2D70E2',position:'absolute',right:'10px' }}>
        <span style={{ marginRight: 4 }}>Click to open</span>
        <Icon name="external square alternate" style={{ marginTop: -7 }} />
      </div>
    </CardWrapper>
  );
}
