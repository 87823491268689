import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

// Components
import Text from "new_components/atoms/Text";
import ProgressDial from "new_components/molecules/ProgressDial";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

// Animations
const fadeOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(10px);
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: ${colors.background.white};
  border-radius: 8px;
`;

const DialWrapper = styled.div`
  margin-bottom: 16px;
`;

const MessageWrapper = styled.div`
  position: relative;
  height: 40px; /* Adjust based on text size */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px; /* Adjust based on text size */
`;

const FadingText = styled(Text)`
  position: absolute;
  animation: ${({ isExiting }) => (isExiting ? fadeOut : fadeIn)} 0.5s forwards;
  color: ${colors.text.gray};
`;

const SectionLoader = ({
  title = "Please wait..",
  progress,
  currentMessage,
  infinite = false,
}) => {
  const [message, setMessage] = useState(currentMessage);
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    if (message !== currentMessage) {
      setIsExiting(true); // Start fade-out animation

      // Wait for fade-out animation to finish before updating the message
      const timeout = setTimeout(() => {
        setMessage(currentMessage);
        setIsExiting(false); // Start fade-in animation
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [currentMessage, message]);

  return (
    <LoaderWrapper>
      {/* Progress Dial */}
      <DialWrapper>
        <ProgressDial
          infinite={infinite}
          progress={progress}
          size="100px"
          label={progress ? `${progress}%` : ""}
        />
      </DialWrapper>

      {/* Title */}
      <Text typographyStyle={typography.body.regular} color={colors.text.dark}>
        {title}
      </Text>

      {/* Fading Message */}
      <MessageWrapper>
        <FadingText
          typographyStyle={typography.body.regular}
          isExiting={isExiting}
        >
          {message}
        </FadingText>
      </MessageWrapper>
    </LoaderWrapper>
  );
};

SectionLoader.propTypes = {
  title: PropTypes.string, // Loader title text
  progress: PropTypes.number.isRequired, // Progress value (percentage)
  currentMessage: PropTypes.string, // Message displayed under "Please wait..."
};

export default SectionLoader;
