import { SET_DARK_MODE } from "constants/actions";

const initialState = {
  darkMode: false,
};

export default function Theme(state = initialState, action) {
  switch (action.type) {
    case SET_DARK_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
}
