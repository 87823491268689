import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "styled-components";
import Chart from "react-apexcharts";
import { injectDownloadOptions } from "constants/chartOptions"; // Adjust or remove as needed

// Updated numeric mappings for sentiment (2 to -2)
const sentimentMapping = {
  "Very Positive": 2,
  "Positive": 1,
  "Neutral": 0,
  "Negative": -1,
  "Very Negative": -2,
};

// Updated numeric mappings for action orientation (-1 to 1)
const actionOrientationMapping = {
  action_oriented: 1,
  not_action_oriented: -1,
};

/**
 * Build data for the comparison chart.
 *
 * @param {Array} feedback - The feedback array (each item has `.nlp` and `.categories`)
 * @param {Array} categories - Your categories array (the large parent/child structure)
 * @param {string} anchorCategory - The ID of the parent category to compare by
 * @param {string} metric - "sentiment" or "action_orientation"
 * @returns {Object} Chart data with { categories: [], series: [] } or null if invalid
 */
function calculateNlpSummary(feedback, categories, anchorCategory, metric) {
  if (!feedback || !categories || !anchorCategory) return null;

  // Find the "anchor" category object by its ID
  const anchorObj = categories.find((cat) => cat.id === anchorCategory);
  if (!anchorObj || !anchorObj.options) {
    console.warn(`No matching category object found for ID: ${anchorCategory}`);
    return null;
  }

  // Build a map from option ID -> option name
  const optionIdToNameMap = {};
  for (const option of anchorObj.options) {
    optionIdToNameMap[option.id] = option.name;
  }

  // Group the feedback by each anchor option (based on the name)
  const grouped = {};

  for (const fb of feedback) {
    // Look for a category record in fb.categories that matches anchorCategory
    const anchorResponse = fb.categories.find((cat) => cat.id === anchorCategory);
    if (!anchorResponse || !anchorResponse.response) continue;

    const optionId = anchorResponse.response;
    const categoryName = optionIdToNameMap[optionId];
    if (!categoryName) continue; // Skip if no matching name

    // Convert the feedback's NLP metric to numeric
    let value = null;
    if (metric === "sentiment") {
      const sentimentVal = fb.nlp?.sentiment;
      if (sentimentVal && sentimentMapping[sentimentVal] !== undefined) {
        value = sentimentMapping[sentimentVal];
      }
    } else if (metric === "action_orientation") {
      const actionVal = fb.nlp?.action_orientation;
      if (actionVal && actionOrientationMapping[actionVal] !== undefined) {
        value = actionOrientationMapping[actionVal];
      }
    }

    // If we have a valid numeric value, store it
    if (value !== null) {
      if (!grouped[categoryName]) {
        grouped[categoryName] = [];
      }
      grouped[categoryName].push(value);
    }
  }

  // Convert grouped data into (categoryName, average) pairs
  const dataPairs = Object.entries(grouped).map(([catName, vals]) => {
    const avg = vals.reduce((a, b) => a + b, 0) / vals.length;
    return { category: catName, average: avg };
  });

  // Sort descending by average
  dataPairs.sort((a, b) => b.average - a.average);

  return {
    categories: dataPairs.map((d) => d.category),
    series: [
      {
        name: `${metric.charAt(0).toUpperCase() + metric.slice(1)} Average`,
        data: dataPairs.map((d) => parseFloat(d.average.toFixed(2))),
      },
    ],
  };
}

const SentimentSummaryChart = ({ feedback, categories, anchorCategory }) => {
  const [loading, setLoading] = useState(true);
  const [selectedMetric, setSelectedMetric] = useState("sentiment");
  const chartRef = useRef(null);

  useEffect(() => {
    // Simulate loading
    const timer = setTimeout(() => setLoading(false), 500);
    return () => clearTimeout(timer);
  }, []);

  // Recompute chart data when dependencies change
  const chartData = useMemo(() => {
    return calculateNlpSummary(feedback, categories, anchorCategory, selectedMetric);
  }, [feedback, categories, anchorCategory, selectedMetric]);

  const handleMetricChange = (event) => {
    setSelectedMetric(event.target.value);
  };

  // For sentiment, range: [-2, 2]. For action_orientation, range: [-1, 1].
  let [minValue, maxValue] = [0, 1];
  if (selectedMetric === "sentiment") {
    minValue = -2;
    maxValue = 2;
  } else if (selectedMetric === "action_orientation") {
    minValue = -1;
    maxValue = 1;
  }

  // ApexCharts options
  const options = {
    chart: {
      height: 400,
      type: "bar",
      fontFamily: "Raleway, sans-serif",
      foreColor: "#7E7E7E",
      zoom: { enabled: false },
      toolbar: { show: false },
    },
    colors: ["#2D70E2"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 5,
        barHeight: "70%",
      },
    },
    legend: { show: false },
    fill: { type: "solid" },
    grid: {
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
    xaxis: {
      categories: chartData ? chartData.categories : [],
      title: { text: `Average ${selectedMetric}` },
      labels: {
        style: { fontFamily: "Raleway, sans-serif" },
      },
    },
    yaxis: {
      labels: {
        style: { fontFamily: "Raleway, sans-serif" },
        formatter: (val) => val.toFixed(2),
      },
      min: minValue,
      max: maxValue,
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => val.toFixed(2),
      style: {
        fontSize: "14px",
        fontFamily: "Raleway, sans-serif",
        colors: ["#FFFFFF"],
      },
      offsetY: 0,
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.3,
      },
    },
    tooltip: {
      enabled: true,
      theme: "light",
      y: {
        formatter: (val) => val.toFixed(2),
      },
    },
    title: {
      text: `Comparison by ${selectedMetric.charAt(0).toUpperCase() + selectedMetric.slice(1)}`,
      align: "center",
      style: { fontFamily: "Raleway, sans-serif" },
    },
  };

  return (
    <Container>
      {/* Metric selection dropdown */}
      <div style={{ marginBottom: "20px" }}>
        <select
          value={selectedMetric}
          onChange={handleMetricChange}
          style={{ width: "100%", padding: "8px", fontFamily: "Raleway, sans-serif" }}
        >
          <option value="sentiment">Sentiment</option>
          <option value="action_orientation">Action Orientation</option>
        </select>
      </div>

      {/* Chart container */}
      {!loading && chartData && chartData.series[0]?.data?.length > 0 && (
        <Chart
          options={injectDownloadOptions(options, true)}
          series={chartData.series}
          type="bar"
          height={400}
          ref={chartRef}
        />
      )}

      {/* If there's no data but not loading, show fallback */}
      {(!chartData || chartData.series[0]?.data?.length === 0) && !loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          No Data Available
        </div>
      )}

      {/* Loading state */}
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 400,
          }}
        >
          Loading...
        </div>
      )}
    </Container>
  );
};

export default SentimentSummaryChart;

const Container = styled.div`
  margin-top: 20px;
`;
