// Input.tsx
import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

/** STYLES **/
const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  color: ${colors.text.dark};
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  border: 1px solid ${colors.neutral[500]};
  border-radius: 0.375rem;
  background-color: ${colors.background.white};
  color: ${colors.text.dark};
  transition: border-color 0.2s ease, box-shadow 0.2s ease;

  &::placeholder {
    color: ${colors.neutral[600]};
  }

  &:focus {
    border-color: ${colors.primary[500]};
    box-shadow: 0 0 0 2px rgba(45, 112, 226, 0.4);
    outline: none;
  }

  &.error {
    border-color: ${colors.auxiliary.red[500]};
    box-shadow: 0 0 0 2px rgba(241, 80, 98, 0.4);
  }

  &.disabled {
    background-color: ${colors.neutral[200]};
    color: ${colors.neutral[600]};
    cursor: not-allowed;
  }

  /* Allow parent overrides */
  &.no-focus {
    border: none;
    box-shadow: none;
    background-color: transparent;
    &:focus {
      border: none;
      box-shadow: none;
      outline: none;
    }
  }
`;

const ErrorMessage = styled.span`
  font-size: 0.75rem;
  color: ${colors.auxiliary.red[500]};
`;

const Input = React.forwardRef(
  ({ label, id, placeholder, error, disabled, className, ...props }, ref) => (
    <InputWrapper>
      {label && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      <StyledInput
        id={id}
        ref={ref}
        placeholder={placeholder}
        className={`${error ? "error" : ""} ${
          disabled ? "disabled" : ""
        } ${className}`}
        disabled={disabled}
        {...props}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  )
);

Input.displayName = "Input";

export default Input;