import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Import your existing components and styles
import Text from "new_components/atoms/Text";
import Icon from "new_components/atoms/Icon"; // Ensure path matches your project structure
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import { applyTypography } from "styles/typography";
import typography from "styles/typography";
import colors from "styles/colors";

/** ------------------------
 *  STYLED COMPONENTS
 *  ------------------------ */

// Fullscreen backdrop for the modal
const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

// The modal’s main container
const ModalContainer = styled.div`
  background-color: ${colors.background.white};
  border-radius: 8px;
  width: ${({ width }) => width};
  max-width: 90%;
  padding: 24px;
  max-height: 90%;
  position: relative;
  // allow scroll but hide the overflow
  overflow-y: auto;
`;

// Header container with close icon on the top-right
const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

// Title text styling, or just reuse your <Text> component with appropriate props
const ModalTitle = styled(Text)`
  ${applyTypography(typography.subhead.semibold)}
  color: ${colors.text.dark};
`;

// Button (or Icon) to close the modal
const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;

  &:hover {
    opacity: 0.8;
  }
`;

// Body area for the main content
const ModalBody = styled.div`
  margin-bottom: 24px;
`;

// Footer for actions (OK button, etc.)
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function Modal({
  isOpen,
  onClose,
  modalHeading,
  modalBody,
  modalFooter,
  modalWidth = "480px",
  modalHeight = "480px",
}) {
  if (!isOpen) return null;

  const handleBackdropClick = (e) => {
    // Close the modal only if the click happened on the backdrop, not the container
    e.stopPropagation();
    onClose();
  };
  const handleContainerClick = (e) => {
    // Prevent click propagation to the backdrop
    e.stopPropagation();
  };

  return (
    <ModalBackdrop onClick={handleBackdropClick}>
      <ModalContainer
        width={modalWidth}
        height={modalHeight}
        onClick={handleContainerClick}
      >
        {/* HEADER */}
        <ModalHeader>
          <ModalTitle>{modalHeading}</ModalTitle>
          <CloseButton onClick={onClose}>
            <Icon name={ICON_SET.close} size={24} color={colors.text.gray} />
          </CloseButton>
        </ModalHeader>

        {/* BODY */}
        <ModalBody>{modalBody}</ModalBody>

        {/* FOOTER */}
        <ModalFooter>{modalFooter}</ModalFooter>
      </ModalContainer>
    </ModalBackdrop>
  );
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  currentClosingTime: PropTypes.string,
};

export default Modal;
