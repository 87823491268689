import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";

// Components
import SearchDropdown from "new_components/organisms/SearchDropdown";
import Table from "new_components/organisms/Table";
import ImageButton from "new_components/molecules/ImageButton";

// Constants
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
import Text from "new_components/atoms/Text";
import typography from "styles/typography";

// Styles
const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  filter: ${({ isLoading }) => (isLoading ? "blur(4px)" : "none")};
  pointer-events: ${({ isLoading }) => (isLoading ? "none" : "auto")};
  transition: filter 0.3s ease, pointer-events 0.3s ease;
`;

const TableWrapper = styled.div`
  margin-top: 16px;
`;

// Helper function to map users
const userMap = (usersList, employeeList) => {
  if (!usersList || !employeeList) return [];
  const list = usersList?.map((user) => {
    const employeeObj = employeeList?.employees?.find(
      (emp) => emp.id === user?.employee_id
    );

    return {
      key: user?.employee_id,
      value: user?.employee_id,
      text: `${employeeObj?.first_name} ${employeeObj?.last_name}`,
      id: user?.id,
    };
  });

  const empList = employeeList?.employees;
  const inactiveList = empList?.filter(
    (emp) => emp.status === 99 || emp.status === 98 || emp.status === 108
  );

  const enabledLogins = empList?.filter((emp) => emp.enable_login === true);

  const filteredList = list
    .filter((emp) => !inactiveList.find((inactive) => inactive.id === emp.key))
    ?.filter((emp) => enabledLogins.find((enabled) => enabled.id === emp.key));

  // Add an option with a -1 value to prevent users from being added when clicking away from dropdown.
  filteredList.unshift({ key: -1, value: -1, text: "Select User" });
  return filteredList;
};

const AssignSection = ({ formData, onUpdate }) => {
  const { get_employee_org_category, get_employees } = useSelector(
    (state) => ({
      get_employee_org_category: state.employee_category.employee_org_category,
      get_employees: state.employees,
    }),
    shallowEqual
  );

  const [selectedUsers, setSelectedUsers] = useState(
    Array.isArray(formData.assignees) ? formData.assignees : []
  );

  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (get_employees?.employees?.length > 0) {
      setLoadingData(false);
    }
  }, [get_employee_org_category, get_employees]);

  useEffect(() => {
    setSelectedUsers(formData.draft || []);
  }, [formData.draft]);

  if (loadingData) {
    return (
      <SectionWrapper isLoading={loadingData}>
        <p>Loading data, please wait...</p>
      </SectionWrapper>
    );
  }

  // Handle adding a user
  const handleAddUser = (user) => {
    if (
      user.value !== -1 &&
      !selectedUsers.some((u) => u.value === user.value)
    ) {
      const updatedUsers = [...selectedUsers, user];
      setSelectedUsers(updatedUsers);
      onUpdate(updatedUsers); // Pass the array directly
    }
  };

  // Handle removing a user
  const handleRemoveUser = (userId) => {
    const updatedUsers = selectedUsers.filter((user) => user.value !== userId);
    setSelectedUsers(updatedUsers);
    onUpdate(updatedUsers); // Pass the array directly
  };;

  const userList = userMap(get_employee_org_category, get_employees);

  return (
    <SectionWrapper isLoading={loadingData}>
      {/* Search Dropdown */}
      <SearchDropdown
        data={userList}
        onSearch={(item, searchTerm) =>
          item.text.toLowerCase().includes(searchTerm.toLowerCase())
        }
        placeholder="Search User"
        onSelect={handleAddUser}
      />

      {/* Table of Assigned Users */}
      <TableWrapper>
        <Table
          columns={[
            { key: "user", label: "User" },
            { key: "action", label: "Action" },
          ]}
          data={selectedUsers.map((user) => ({
            user: user.text,
            action: (
              <ImageButton
                text="Remove"
                icon={ICON_SET.delete}
                textStyle
                color={colors.auxiliary.red[500]}
                onClick={() => handleRemoveUser(user.value)}
              />
            ),
          }))}
        />

        {/* Display a message if no users are assigned */}
        {selectedUsers.length === 0 && (
          <Text
            color={colors.text.gray}
            typographyStyle={typography.body.semibold}
          >
            No users assigned
          </Text>
        )}
      </TableWrapper>
    </SectionWrapper>
  );
};

AssignSection.propTypes = {
  formData: PropTypes.shape({
    assignees: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    draft: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        text: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
  onUpdate: PropTypes.func.isRequired, // Callback to update form data
};

export default AssignSection;
