/**
 * TextEditor Component Usage:
 *
 * Props:
 * 1. `value` (string, required):
 *    - This should be a raw HTML string.
 *    - It is used only to initialize the editor’s state.
 *    - After the editor initializes, changes to `value` will not affect the editor state.
 *
 * 2. `onChange` (function, required):
 *    - This function will receive the updated content (in HTML format) whenever the editor's content changes.
 *    - It is the responsibility of the parent component to update the `updatedContent` or equivalent variable.
 *
 * Important Notes:
 * - Do NOT pass a dynamic `value` prop after initialization as it will cause re-rendering and lead to an infinite loop.
 * - Ensure that `onChange` is provided; without it, the updated content won't be passed back to the parent.
 * - Ensure that the parent component is handling the updated content properly.
 *
 * Error Handling:
 * - If an invalid HTML string is passed to `value`, an error message will appear in the console, advising the developer to check the provided documentation.
 * - If `onChange` is not passed, an error message will be logged in the console.
 *
 */
import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import styled from "styled-components";

const TextEditor = ({ value, onChange, hasError }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  // Effect to initialize the editor state when the value prop is present

  // Effect to sync the editor state with the `value` prop
  useEffect(() => {
    const currentContent = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (value && currentContent !== value) {
      const blocksFromHtml = htmlToDraft(value);
      if (blocksFromHtml) {
        const contentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks
        );
        const updatedState = EditorState.createWithContent(contentState);
        setEditorState(updatedState);
      }
    }
  }, [value]);

  // Handle editor changes and update parent via onChange
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
    if (onChange) {
      const updatedContent = draftToHtml(
        convertToRaw(newEditorState.getCurrentContent())
      );
      onChange(updatedContent);
    } else {
      console.error(
        "Error: `onChange` prop is required for TextEditor. Please refer to the documentation in TextEditor/index.js."
      );
    }
  };

  return (
    <EditorWrapper hasError={hasError}>
      <Editor
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
      />
    </EditorWrapper>
  );
};

export default TextEditor;

const EditorWrapper = styled.div`
  border: 0.5px solid ${(props) => (props.hasError ? "#f00" : "#ccc")};
  border-radius: 8px;
  padding: 0px;
  min-height: 400px;
  margin-top: 15px;
  background-color: #fff;

  .editorClassName {
    min-height: 150px;
    padding: 10px;
  }

  .toolbarClassName {
    border-bottom: 1px solid #ccc;
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    margin-bottom: 10px;
  }

  .wrapperClassName {
    min-height: 180px;
  }
`;
