import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import { Checkbox, Icon } from "semantic-ui-react";
import { useSelector, shallowEqual } from "react-redux";

const ROIReportOptions = ({ next, goBack, selectedCategory: preSelected }) => {
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(preSelected);

  const { coreData } = useSelector(
    (state) => ({
      coreData: state.audit?.core_data,
    }),
    shallowEqual
  );

  const handleCategorySelect = (category) => {
    if (selectedCategory?.id === category?.id) {
      setSelectedCategory();
    } else {
      setSelectedCategory(category);
    }
  };

  useEffect(() => {
    if (coreData?.surveyQuestions && coreData?.Whitelabel) {
      const outcomesSortOrder = coreData?.Whitelabel?.survey_sequence?.find(
        (s) => s.value === "outcome_question"
      )?.question?.sort_order;
      const outcomesQuestions = coreData?.surveyQuestions?.find(
        (s) => s.sort_order === outcomesSortOrder
      );

      if (outcomesQuestions) {
        const options = outcomesQuestions?.questions?.map((q) => {
          return {
            id: q.id,
            name: q.name,
            value: q.id,
          };
        });
        setCategoryOptions(options);
      }
    }
  }, [coreData]);

  return (
    <Container>
      <BackButton onClick={() => goBack()}>
        <Icon name="chevron left" style={{ marginTop: -7 }} />
        Back
      </BackButton>
      <T1>Select the report target</T1>
      <Description>Please select an outcome for your report.</Description>
      <SelectArea>
        {categoryOptions.map((category) => {
          return (
            <Option
              checked={selectedCategory?.id === category.id}
              disabled={
                (selectedCategory && selectedCategory?.id !== category.id) ||
                false
              }
              key={category.id}
              id={category.id}
              title={category.name}
              onSelect={() => handleCategorySelect(category)}
            />
          );
        })}
      </SelectArea>
      <ButtonContainer>
        <Button
          disabled={!selectedCategory}
          onClick={() => {
            if (!selectedCategory) {
              return;
            }
            next(selectedCategory);
          }}
        >
          Next
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Option = ({ title, disabled, onSelect, checked, id }) => {
  return (
    <OptionContainer active={checked} disabled={disabled}>
      <Checkbox
        disabled={disabled}
        checked={checked}
        onChange={() => onSelect(id)}
        style={{ marginRight: 10 }}
      />{" "}
      {title}
    </OptionContainer>
  );
};

const fadeIn = keyframes`
  
      from {
          opacity: 0;
          transform: translateY(-20px);
      }
      to {
          opacity: 1;
          transform: translateY(0);
      }
  `;

const Container = styled.div``;

const T1 = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: "Raleway";
  color: #2a3039;
  line-height: 1.5;
  margin-top: 20px;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  letter-spacing: -0.35px;
`;

const Description = styled.div`
  font-size: 16px;
  color: #2a3039;
  margin-bottom: 20px;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 0.5s;
`;

const BackButton = styled.div`
  font-size: 14px;
  color: #b3b3b3;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
`;

const SelectArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

const OptionContainer = styled.div`
  width: calc(33% - 5px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Raleway";
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 5px;
  margin-right: 2px;
  margin-left: 2px;

  background-color: ${({ active }) => (active ? "#D5E2F9" : "#fff")};
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
  animation-delay: 1s;

  color: ${({ disabled }) => (disabled ? "#ccc" : "#2a3039")};
`;

const ButtonContainer = styled.div`
      display:flex;
      margin-top:20px;
      justify-content:flex-end;
      align-items:center
      width:100%;
  
      opacity:0;
  
      animation: ${fadeIn} 1s ease-out forwards;
      animation-delay: 1s;
  
  `;

const Button = styled.div`
  color: #2d70e2;
  border: 1px solid #2d70e2;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  font-family: "Raleway";
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default ROIReportOptions;
