import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";

import Side from "./Side";
import { Popup } from "semantic-ui-react";
import Change from "./Change";
import Benchmark from "./Benchmark";
// import Copilot from "./Copilot";
import Navigation from "./Navigation";
import Trends from "./Trends";
import Feedback from "./Feedback";
import Dashboard from "./Dashboard";

import Relationships from "./ROIInsights";
import SentimentAnalysis from "./SentimentAnalysis";

import { process_feedback_data } from "../calculations";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Insights from "./insights";
import Kindred from "./kindred";
import Factor from "./factor";

import {
  SET_FILTERED_DATA,
  SG_GET_DEBRIEF_SCHEDULES,
  SET_CORE_DATA,
  SET_NEW_FILTERS
} from "constants/actions";

import { filter_data } from "reports/Tag/calculations";
import Heatmap from "./Heatmap/index";
import DataTypeDropdown from "./DataTypeDropdown";

import { useLocation } from "react-router-dom";

const SimpleAnalytics = ({ stage: stageProp }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [stage, setStage] = useState(stageProp || 1);
  const [copilot, setCopilot] = useState(false);
  const [outcomeQ, setOutcomeQ] = useState(null);
  const [outcomeStandards, setOutcomeStandards] = useState(null);
  const [originalData, setOriginalData] = useState(null);
  const [organizationData, setOrganizationData] = useState(null);
  const [showCopilot, setShowCopilot] = useState(false);
  const [rule, setRule] = useState(2);

  const raw_data =  useSelector(
    (state) => state.audit?.raw_data,
    shallowEqual
  );

  useEffect(()=>{
    return ()=>{
      dispatch({
        type: SET_NEW_FILTERS,
        payload: []
      });
      dispatch({
        type:SET_FILTERED_DATA,
        payload:raw_data
      })
    
    }
  },[])

  // Bring in the data from the redux store
  // I assume this has been fetched already
  useEffect(() => {
    let pathname = location.pathname.split("/");

    switch (pathname[pathname.length - 1]) {
      case "dashboard":
        setStage(1);
        break;
      case "benchmarks":
        setStage(2);
        break;
      case "change":
        setStage(3);
        break;
      case "feedback":
        setStage(4);
        break;
      case "insights":
        setStage(5);
        break;
      case "relationships":
        setStage(6);
        break;
      case "heatmap":
        setStage(7);
        break;
      case "kindred":
        setStage(8);
        break;
      case "factor":
        setStage(9);
        break;
      default:
        setStage(1);
    }
  }, [location.pathname]);

  const filtered_data = useSelector(
    (state) => state.audit?.filtered_data,
    shallowEqual
  );

  const filter_last_data = useSelector(
    (state) => state.audit?.filtered_last_data,
    shallowEqual
  );

  const get_organizations = useSelector((state) => state.organizations);

  const get_survey_questions = useSelector((state) => state.surveyquestions);
  const get_auth = useSelector((state) => state.auth);

  const core_data = useSelector(
    (state) => state.audit?.core_data,
    shallowEqual
  );

  


  useEffect(() => {
    if (core_data?.Whitelabel) {
      const orgRule = !!core_data?.Whitelabel?.rule
        ? Number(core_data?.Whitelabel?.rule)
        : 2;
      setRule(orgRule);
    }
  }, [core_data?.Whitelabel]);

  // These are the dropdown options selected, this will
  // dictate how we filter the data and display
  // the appropriate anchors, and charts
  const navigation = useSelector(
    (state) => state.audit?.new_navigation,
    shallowEqual
  );

  const selectedFilters = useSelector(
    (state) => state.audit?.new_filters,
    shallowEqual
  );

  useEffect(() => {
    if (get_organizations) {
      setOrganizationData(
        get_organizations[
          get_auth?.organization_id || get_organizations?.organization?.id
        ]
      );
    }
  }, [get_organizations]);

  useEffect(() => {
    if (get_auth?.organization_id) {
      dispatch({
        type: SG_GET_DEBRIEF_SCHEDULES,
        payload: `page_size=1000&type_of=3&organization=${get_auth?.organization_id}`,
      });
    }
  }, [get_auth?.organization_id]);

  useEffect(() => {
    // when selectedFilters, or navigation changes,
    // we need to update the feedback data within the core_data.
    if (core_data) {
        const filteredData = filter_data(raw_data[0].responses, selectedFilters);

        dispatch({
          type: SET_FILTERED_DATA,
          payload: filteredData,
        });

      if (
        (Array.isArray(filteredData[0]) && filteredData[0].length > 0) ||
        (!Array.isArray(filteredData[0]) && filteredData.length > 0)
      ) {
        const feedback = process_feedback_data(filteredData, navigation);
        dispatch({
          type: SET_CORE_DATA,
          payload: {
            ...core_data,
            feedback,
          },
        });
      }
    }
  }, [selectedFilters, navigation]);

  useEffect(() => {
    if (organizationData) {
      // We need to get the outcome question id
      // Then parse the outcome question list for the proper version
      const sortOrder = organizationData?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;

      if (sortOrder) {
        const outcomeQ = get_survey_questions?.outcome_questions.find(
          (item) => item.sort_order === sortOrder
        );
        setOutcomeQ(outcomeQ);
      }
    }
  }, [core_data, organizationData]);

  useEffect(() => {
    if (outcomeQ) {
      setOutcomeStandards(
        core_data?.get_standards.find((f) =>
          f.question_sort_order.includes(outcomeQ.sort_order)
        )?.response
      );
    }
  }, [outcomeQ]);


  const renderContent = () => {
    switch (stage) {
      case 1:
        return <Dashboard />;
      case 2:
        return (
          <Benchmark
            data={filtered_data}
            standards={core_data?.standards}
            categories={core_data?.categories?.[0]}
            questions={core_data?.questions}
            outcomeStandards={outcomeStandards}
            navigation={navigation}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
            outcomeQ={outcomeQ}
            core_data={core_data}
          />
        );

      case 3:
        return (
          <Change
            navigation={navigation}
            outcomeQ={outcomeQ}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
          />
        );
      case 4:
        return (
          <Feedback
            core_data={core_data}
            outcomeQ={outcomeQ}
            outcomeStandards={outcomeStandards}
            data={filtered_data}
            navigation={navigation}
            originalData={filtered_data?.[1]}
          />
        );
      case 5:
        return <Insights />;
      case 6:
        return (
          <Relationships
            core_data={core_data}
            outcomeQ={outcomeQ}
            data={filtered_data}
            outcomeStandards={outcomeStandards}
            navigation={navigation}
            selectedFilters={selectedFilters}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
          />
        );
      case 7:
        return (
          <Heatmap
            core_data={core_data}
            outcomeQ={outcomeQ}
            data={filtered_data}
            outcomeStandards={outcomeStandards}
            navigation={navigation}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
          />
        );

      case 8:
        return (
          <Kindred
            core_data={core_data}
            outcomeQ={outcomeQ}
            data={filtered_data}
            outcomeStandards={outcomeStandards}
            navigation={navigation}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
          />
        );
      case 9:
        return (
          <Factor
            core_data={core_data}
            outcomeQ={outcomeQ}
            data={filtered_data}
            lastData={filter_last_data}
            outcomeStandards={outcomeStandards}
            navigation={navigation}
            rule={
              core_data?.Whitelabel?.rule ? core_data?.Whitelabel?.rule : rule
            }
          />
        );
      default:
        return <Dashboard />;
    }
  };

  useEffect(() => {
    if (copilot) {
      setOpen(false);
    }
  }, [copilot]);

  useEffect(() => {
    if (open) {
      return setCopilot(false);
    }
  }, [open]);

  const FULL_WIDTH_STAGES = [1, 7, 6, 8, 9, 4];

  return (
    <Container open={copilot}>
      <Side open={open} setOpen={setOpen} stage={stage} setStage={setStage} />

      <Center isFullWidth={FULL_WIDTH_STAGES.includes(stage)}>
        <ActionContainer visible={stage !== 1}>
          {/* <Navigation outcomes={outcomeQ} core_data={core_data} /> */}
          {/* <Filters /> */}
        </ActionContainer>

        {(raw_data?.length > 0 || core_data?.noData) && renderContent()}
      </Center>
    </Container>
  );
};

export default SimpleAnalytics;

const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const ActionContainer = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: ${(props) => (props.open ? "-190px" : 0)};
  padding: 20px;
  font-family: "Raleway";

  transition: all 0.3s ease-in-out;
`;

const Center = styled.div`
  width: ${(props) => (props.isFullWidth ? "calc(100vw - 300px)" : "800px")};
  padding-top: 1%;
  padding-left: ${(props) => (props.open ? "200px" : "0px")};
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  position: relative;

  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
`;

const FeedbackActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
`;

const Chat = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: white;
  background-color: #2d70e2;
  font-size: 16px;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-family: "Raleway";
`;

export function AiIcon(fill) {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1389 18.7668C18.9017 18.7668 18.7021 18.6829 18.5398 18.5151C18.3776 18.3473 18.2965 18.1409 18.2965 17.8956V9.59098C18.2965 9.34577 18.3776 9.13929 18.5398 8.97152C18.7021 8.79084 18.9017 8.7005 19.1389 8.7005C19.3885 8.7005 19.5944 8.78439 19.7566 8.95216C19.9189 9.11993 20 9.33287 20 9.59098V17.8956C20 18.1409 19.9126 18.3473 19.7379 18.5151C19.5757 18.6829 19.376 18.7668 19.1389 18.7668Z"
        fill="white"
      />
      <path
        d="M18.5331 6.71753C18.6908 6.88064 18.8801 6.9622 19.1009 6.9622H19.1956C19.4164 6.9622 19.6057 6.88064 19.7634 6.71753C19.9211 6.55442 20 6.35868 20 6.13033V6.06508C20 5.83672 19.9211 5.64099 19.7634 5.47787C19.6057 5.31476 19.4164 5.23321 19.1956 5.23321H19.1009C18.8801 5.23321 18.6908 5.31476 18.5331 5.47787C18.3753 5.64099 18.2965 5.83672 18.2965 6.06508V6.13033C18.2965 6.35868 18.3753 6.55442 18.5331 6.71753Z"
        fill="white"
      />
      <path
        d="M0.861115 5.2374C1.09823 5.2374 1.29791 5.32122 1.46015 5.48888C1.62239 5.65653 1.70351 5.86287 1.70351 6.1079V14.4067C1.70351 14.6517 1.62239 14.8581 1.46015 15.0257C1.29791 15.2063 1.09823 15.2965 0.861115 15.2965C0.611516 15.2965 0.405596 15.2127 0.243357 15.0451C0.0811174 14.8774 0 14.6646 0 14.4067V6.1079C0 5.86287 0.0873585 5.65653 0.262078 5.48888C0.424317 5.32122 0.623996 5.2374 0.861115 5.2374Z"
        fill="white"
      />
      <path
        d="M1.46693 17.2792C1.3092 17.1162 1.11992 17.0347 0.89909 17.0347H0.804451C0.583623 17.0347 0.394341 17.1162 0.236607 17.2792C0.0788722 17.4422 5.03191e-06 17.6378 5.03191e-06 17.866V17.9312C5.03191e-06 18.1594 0.0788722 18.355 0.236607 18.518C0.394341 18.681 0.583623 18.7625 0.804451 18.7625H0.89909C1.11992 18.7625 1.3092 18.681 1.46693 18.518C1.62467 18.355 1.70354 18.1594 1.70354 17.9312V17.866C1.70354 17.6378 1.62467 17.4422 1.46693 17.2792Z"
        fill="white"
      />
      <path
        d="M13.7232 2.62093C13.7232 2.13448 14.1046 1.74013 14.575 1.74013C15.0454 1.74013 15.4267 2.13448 15.4267 2.62093V21.379C15.4267 21.8655 15.0454 22.2598 14.575 22.2598C14.1046 22.2598 13.7232 21.8655 13.7232 21.379V2.62093Z"
        fill="white"
      />
      <path
        d="M4.57663 2.62093C4.57663 2.13448 4.95798 1.74013 5.42839 1.74013C5.8988 1.74013 6.28015 2.13448 6.28015 2.62093V21.379C6.28015 21.8655 5.8988 22.2598 5.42839 22.2598C4.95798 22.2598 4.57663 21.8655 4.57663 21.379V2.62093Z"
        fill="white"
      />
      <path
        d="M9.1499 0.880798C9.1499 0.394347 9.53125 0 10.0017 0C10.4721 0 10.8534 0.394347 10.8534 0.880799V10.2502C10.8534 10.7366 10.4721 11.131 10.0017 11.131C9.53125 11.131 9.1499 10.7366 9.1499 10.2502V0.880798Z"
        fill="white"
      />
      <path
        d="M9.1499 13.7498C9.1499 13.2634 9.53125 12.869 10.0017 12.869C10.4721 12.869 10.8534 13.2634 10.8534 13.7498V23.1192C10.8534 23.6056 10.4721 24 10.0017 24C9.53125 24 9.1499 23.6056 9.1499 23.1192V13.7498Z"
        fill="white"
      />
    </svg>
  );
}
