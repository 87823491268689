import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";
import colors from "styles/colors";
import Text from "new_components/atoms/Text";

// Keyframes for infinite spinner (fills instead of empties)
const fillAnimation = keyframes`
  0% {
    stroke-dashoffset: 314; /* Full circle */
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

// Styled Components
const DialWrapper = styled.div`
  position: relative;
  width: ${({ size }) => size || "80px"};
  height: ${({ size }) => size || "80px"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Svg = styled.svg`
  transform: rotate(-90deg);
`;

const Circle = styled.circle`
  fill: none;
  stroke-width: ${({ strokeWidth }) => strokeWidth};
  stroke-linecap: ${({ rounded }) => (rounded ? "round" : "butt")};
  ${({ infinite }) =>
    infinite &&
    css`
      animation: ${fillAnimation} 1.5s linear infinite;
    `}
  transition: ${({ infinite }) =>
    infinite ? "none" : "stroke-dashoffset 0.5s ease-out"};
`;

const CenterLabel = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Dial = ({
  progress,
  label,
  size = "80px",
  strokeWidth = "8",
  rounded = true,
  type = "filled",
  mainColor = colors.primary[500],
  secondaryColor = colors.neutral[200],
  infinite = false,
}) => {
  const radius = 50 - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  const progressOffset = infinite
    ? circumference // Start with an empty circle
    : circumference - (progress / 100) * circumference;

  return (
    <DialWrapper size={size}>
      <Svg width="100%" height="100%" viewBox="0 0 100 100">
        {/* Background Circle */}
        <Circle
          cx="50"
          cy="50"
          r={radius}
          stroke={secondaryColor}
          strokeWidth={strokeWidth}
        />
        {/* Progress Circle */}
        <Circle
          cx="50"
          cy="50"
          r={radius}
          stroke={mainColor}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          rounded={rounded}
          infinite={infinite}
        />
      </Svg>
      {/* Center Label */}
      {!infinite && (
        <CenterLabel>
          {type === "filled" ? (
            <Text typographyStyle="body.bold">{`${progress}%`}</Text>
          ) : (
            <>
              <Text typographyStyle="body.bold">{`${progress}%`}</Text>
              <Text typographyStyle="caption.regular">{label}</Text>
            </>
          )}
        </CenterLabel>
      )}
    </DialWrapper>
  );
};

Dial.propTypes = {
  progress: PropTypes.number, // Progress value (0-100)
  label: PropTypes.string, // Label for non-filled dials
  size: PropTypes.string, // Size of the dial
  strokeWidth: PropTypes.string, // Stroke width of the dial
  rounded: PropTypes.bool, // Whether the dial edges are rounded
  type: PropTypes.oneOf(["filled", "non-filled"]), // Type of dial
  mainColor: PropTypes.string, // Primary color for the progress
  secondaryColor: PropTypes.string, // Secondary background color
  infinite: PropTypes.bool, // If true, enables infinite spinner mode
};

Dial.defaultProps = {
  progress: 0,
  infinite: false,
};

export default Dial;
