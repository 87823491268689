import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";

// Components
import ActionMenuLayout from "new_components/templates/SubContentTemplate";
import Button from "new_components/atoms/Button";
import Questions from "./SurveyFormCategories/Questions";
import LibraryHeader from "./components/LibraryHeader";

// Libraries
import culture_factor_pulses from "./lib/culture_factor_pulses.json";

// Styles
const ActionsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: right;
  flex-direction: row;
`;

const TemplateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

// Add all libraries here
const LIBRARIES = [culture_factor_pulses];

// This should return the structure that will create the menuItems array.
const parseLibraryData = (library) => {
  const { groupTitle, surveys } = library;

  const items = surveys.map((survey) => ({
    id: survey.id,
    categoryTitle: survey.title,
    categoryContent: (
      <Template
        surveyTitle={survey.title}
        surveyDescription={survey.description}
        questions={survey.questions}
      />
    ),
    serializableData: {
      id: survey.id,
      name: survey.title,
      description: survey.description,
      questions: survey.questions,
    }, // Add only serializable data
  }));

  return {
    groupTitle: groupTitle,
    items: items,
  };
};

const Template = ({ surveyTitle, surveyDescription, questions, onSelect }) => {
  return (
    <TemplateContainer onClick={onSelect}>
      <LibraryHeader description={surveyDescription} title={surveyTitle} />
      <Questions questions={questions} editable={false} />
    </TemplateContainer>
  );
};

const PulseLibraryPage = ({ navigateToForm }) => {
  const [selectedSurvey, setSelectedSurvey] = useState(null);

  const handleUseSurvey = () => {
    if (selectedSurvey) {
      navigateToForm(selectedSurvey.serializableData); // Pass only serializable data
    } else {
      const firstSurvey = LIBRARIES[0].surveys[0];
      navigateToForm({
        id: firstSurvey.id,
        name: firstSurvey.title,
        feedbackTipsEnabled: true,
        description: firstSurvey.description,
        questions: firstSurvey.questions,
      });
    }
  };

  return (
    <ActionMenuLayout
      heading="Pulse AI Surveys"
      pageTitle="Survey Library"
      backAction={() => window.history.back()}
      searchable
      type="single"
      onItemSelect={(item) => setSelectedSurvey(item)}
      menuItems={LIBRARIES.map((library) => parseLibraryData(library))}
      actionRowComponent={
        <ActionsContainer>
          <Button onClick={handleUseSurvey}>Use this survey</Button>
        </ActionsContainer>
      }
    />
  );
};

export default PulseLibraryPage;
