import styled, { keyframes } from "styled-components";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { parseMarkdown } from "./MarkdownParser";
import make_call from "./Request";
import Loading from "./Loading";

const ThematicSlide = ({
  slideData,
  updateSlideData,
  outcomeQ,
  componentLoading,
}) => {
  const [factorDropdown, setFactorDropdown] = useState([]);
  const [selectedFactor, setSelectedFactor] = useState(null);
  const [feedbackData, setFeedbackData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const coreData = useSelector((state) => state.audit?.core_data, shallowEqual);

  const data = useSelector((state) => state.audit?.filtered_data, shallowEqual);

  useEffect(() => {
    if (slideData && slideData.selectedFactor) {
      if (slideData.selectedFactor?.title !== selectedFactor?.title) {
        setSelectedFactor(slideData.selectedFactor);
      }
      if (slideData.message) {
        setMessage(slideData.message);
      }
    } else {
      setSelectedFactor(null);
      setMessage("");
    }
  }, [slideData]);

  useEffect(() => {
    if (message && selectedFactor && !loading) {
      const newSlideData = {
        ...slideData,
        slide_template_id: 2,
        title: `${
          selectedFactor == "All Factors"
            ? "Culture Factor"
            : selectedFactor?.title
        } Thematic Analysis`,
        message,
        selectedFactor,
      };

      // Only update if newSlideData is different from slideData
      if (JSON.stringify(newSlideData) !== JSON.stringify(slideData)) {
        updateSlideData(newSlideData);
      }
    }
    // Exclude slideData and updateSlideData from dependencies to prevent loops
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFactor, message, loading]);

  const handleFactorChange = (e, { value }) => {
    setSelectedFactor(value);
  };

  useEffect(() => {
    if (coreData) {
      let options = [{ key: 0, text: "All Factors", value: "All Factors" }];
      coreData.questions.dimensions.flatMap((dimension, i) =>
        dimension.factors.map((factor) => {
          options.push({
            key: factor.id + factor.title,
            text: factor.title,
            value: { ...factor, dimension: i, type: "factor" },
          });
        })
      );

      //Add the outcome factors here
      outcomeQ.questions.map((q) => {
        options.push({
          key: q.id + q.title,
          text: q.name,
          value: { ...q, type: "outcome" },
        });
      });

      setFactorDropdown(options);

      if (!slideData?.selectedFactor) {
        setSelectedFactor(options[0].value);
      }
    }
  }, [coreData]);

  const make_gpt_call = async (userFeedback = "") => {
    if(loading){
      return;
    }


    setLoading(true);
    setMessage("");

    let prompt = `Create a summary of the themes found in the feedback from the culture survey:

        ${
          selectedFactor?.title
            ? `This feedback is associated with the follow culture factor: ${selectedFactor?.title}`
            : ""
        }

        ${feedbackData}
        
        Format your output using markdown syntax. Generate 3 main feedback themes, and provide a brief description of each. Make sure the themes are connected to the organizations culture, and not simply complaints.
        Each theme should only have a total of 3 sentences. Do not add a title to the output. Only the 3 key themes and descriptions. Use the following format for each theme:

        1. **Theme 1 title:** Description of the theme.
        2. **Theme 2 title:** Description of the theme.
        3. **Theme 3 title:** Description of the theme.


        `;

    const assistantResponse = await make_call(prompt, (chunk) =>
      setMessage((prev) => prev + chunk)
    );

    setLoading(false);
  };

  useEffect(() => {
    if (data) {
      let correctedData = data;
      if (data[0][0]) {
        correctedData = data[0];
      }


      let _data = [];
      let _feedback = "";
      if (selectedFactor?.type == "outcome") {
        correctedData
          .filter((f) => "feedback_builder" in f)
          .filter((f) => "responses" in f.feedback_builder)
          .map((item) => {
            item.feedback_builder.responses
              .filter((f) => f.q == parseInt(selectedFactor.id))
              .map((f) => {
                _data.push({ feedback: f, categories: item.categories });
              });
          });

        _data.map((item) => {
          if (item.feedback.response) {
            _feedback += `## Question:\n${item.feedback.question}\n`;
            _feedback += `## Feedback:\n${item.feedback.response}\n\n`;
          }
        });
        return setFeedbackData(_feedback);
      }

      correctedData
        ?.filter((f) => "feedback" in f)
        ?.filter((f) => f?.feedback?.length > 0)
        ?.map((item) => {
          item?.feedback?.map((f) => {
            if (selectedFactor == "All Factors") {
              _data.push({ feedback: f, categories: item.categories });
            } else {
              if (
                f.id == selectedFactor?.dimension &&
                f.factor == selectedFactor?.id - 1
              ) {
                _data.push({ feedback: f, categories: item.categories });
              }
            }
          });
        });

      _data.map((item) => {
        const isFollowUp = item.feedback?.prompt_feedback;
        if (isFollowUp) {
          _feedback += `## Question:\n${item.feedback.prompt_question}\n`;
          _feedback += `## Follow-up Feedback:\n${isFollowUp}\n\n`;
        }
        if (item.feedback.feedback) {
          _feedback += `## Question:\n${item.feedback.question}\n`;
          _feedback += `## Feedback:\n${item.feedback.feedback}\n\n`;
        }
      });

      setFeedbackData(_feedback);
    }
  }, [data, selectedFactor]);

  if (componentLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <T1>Feedback Thematic Analysis Slide</T1>
      <SubTitle>Slide Options</SubTitle>
      <OptionArea>
        <div style={{ marginRight: 20 }}>
          <Dropdown
            placeholder="Select Factor"
            selection
            options={factorDropdown}
            onChange={handleFactorChange}
            value={selectedFactor}
          />
        </div>

        <div>
          <Button onClick={() => make_gpt_call()}>Generate Thematic</Button>
        </div>
      </OptionArea>
      <SlidePreview>
        <SlideTitle>
          {selectedFactor == "All Factors"
            ? "Culture Factor"
            : selectedFactor?.title}{" "}
          Thematic Analysis
        </SlideTitle>
        <SlideDescription>
          Based on the feedback provided, the top three themes that emerge are:
        </SlideDescription>

        {loading && message.length == 0? <Loading/> :""}

        {parseMarkdown(message)}

        <SlideFooter>innerlogic</SlideFooter>
      </SlidePreview>
    </Container>
  );
};

export default ThematicSlide;

const fadeIn = keyframes`
from {
  opacity: 0;
  transform: translateY(-20px);
}
to {
  opacity: 1;
  transform: translateY(0);
}
`;

const Container = styled.div`
  opacity: 0;

  animation: ${fadeIn} 1s ease-out forwards;
`;

const SlidePreview = styled.div`
  width: 100%;
  height: 550px;
  padding: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  margin-top: 20px;

  position: relative;
`;
const SlideTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Raleway";
  color: #333333;
`;

const SlideDescription = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

const OptionArea = styled.div`
  margin-bottom: 20px;
  display: flex;
  padding: 20px;
  background-color: #f9fbfd;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const OptionTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
`;
const SubTitle = styled.div`
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 600;
`;

const T1 = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #2a3039;
`;

const Button = styled.div`
  background-color: #2d70e2;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  font-weight: bold;
`;

const SlideFooter = styled.div`
  position: absolute;
  bottom: 0;
  font-size: 12px;
  width: calc(100% - 60px);
  padding: 10px 0px;
  border-top: 1px solid #e0e0e0;
  display: flex;
`;
