import React, { useState } from "react";
import styled from "styled-components";

// Data
import { useSurveyDataContext } from "../Data/SurveyViewContext";

// Components
import SubMenuTemplate from "new_components/templates/SubMenuTemplate";
import Text from "new_components/atoms/Text";
import SummaryChart from "./SummaryChart";
import SectionHeader from "./SectionHeader";

// Constants
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
import typography from "styles/typography";

const ResponsesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ResponseContainer = styled.div`
  margin-top: 10px;
`;

const ResponseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 10px 40px;

  border-bottom: 1px solid ${colors.neutral[200]};
`;

const UserWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  &:hover {
    text-decoration: ${(props) => (props.onClick ? "underline" : "none")};
  }
`;

const FollowupSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding: 10px;
  background-color: ${colors.neutral[200]};
  border-radius: 8px;
`;

const removeFeedback = (question) => {
  return question.replace(/\*\*Feedback:\*\*.*$/, "").trim();
};

const QuestionResponse = ({
  responses,
  handleUserSelect,
  questionData,
  surveyIsAnonymous,
}) => {
  if (questionData.type === "scale") {
    return (
      <ResponsesWrapper>
        {/* RENDER THE CHART IF IT IS A SCALE QUESTION */}
        {questionData.type === "scale" && (
          <div style={{ margin: "20px 0" }}>
            <SummaryChart questionData={questionData} responses={responses} />
          </div>
        )}
      </ResponsesWrapper>
    );
  }

  return (
    <ResponsesWrapper>
      <Text typographyStyle={typography.subhead.semibold}>Responses</Text>
      {responses.map((response, index) => (
        <ResponseWrapper key={index}>
          {!surveyIsAnonymous ? (
            <UserWrapper onClick={() => handleUserSelect(response)}>
              <Text typographyStyle={typography.smallText.semibold}>
                {response.participant_name}
              </Text>
            </UserWrapper>
          ) : (
            <UserWrapper>
              <Text typographyStyle={typography.smallText.semibold}>
                {response.participant_name}
              </Text>
            </UserWrapper>
          )}
          <Text typographyStyle={typography.body.regular}>
            {response?.task_list?.[0]?.response}
          </Text>
          {response?.task_list?.[0]?.follow_up && (
            <FollowupSection key={index}>
              <Text
                typographyStyle={typography.caption.semibold}
                color={colors.primary[500]}
              >
                AI Follow up
              </Text>
              <Text typographyStyle={typography.caption.semibold}>
                {removeFeedback(response?.task_list?.[0]?.follow_up?.question)}
              </Text>
              <ResponseContainer>
                <Text typographyStyle={typography.caption.regular}>
                  {response?.task_list?.[0]?.follow_up?.response}
                </Text>
              </ResponseContainer>
            </FollowupSection>
          )}
        </ResponseWrapper>
      ))}
    </ResponsesWrapper>
  );
};

const QuestionsView = ({
  questions,
  responses,
  handleUserSelect,
  participants,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const responseData = responses.map((rGroup) => rGroup?.task_response);

  const { surveyIsAnonymous } = useSurveyDataContext();

  const menuItems = questions.map((question, index) => {
    // within each responseData object, loop through task_list and
    // remove every task_list that does not match the current index
    // then return the task_response object
    const response = responseData.map((rGroup) => {
      return {
        ...rGroup,
        participant_name:
          surveyIsAnonymous === true
            ? "Anonymous Participant"
            : participants.find(
                (participant) => participant.id === rGroup.employee_id
              )?.employee_full_name,
        task_list: rGroup.task_list.filter((task, i) => i === index),
      };
    });

    return {
      id: question.id,
      categoryTitle: question.question,
      categoryContent: (
        <QuestionResponse
          question={question.question}
          responses={response}
          handleUserSelect={handleUserSelect}
          questionData={question}
          surveyIsAnonymous={surveyIsAnonymous}
        />
      ),
    };
  });

  return (
    <SubMenuTemplate
      onItemSelect={setSelectedQuestion}
      menuItems={menuItems}
      type="single"
      searchable
      actionRow={<SectionHeader text="Questions" icon={ICON_SET.question} />}
    />
  );
};

export default QuestionsView;
