import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import BaseTabbedTemplate from "new_components/templates/BaseTabbedTemplate";
import { useNavigate } from "react-router-dom";
import {
  useSurveyDataContext,
  SurveyDataProvider,
} from "./Data/SurveyViewContext";

// Components
import Text from "new_components/atoms/Text";
import colors from "styles/colors";
import typography from "styles/typography";

// View Components
import SummaryView from "./ViewComponents/SummaryView";
import QuestionsView from "./ViewComponents/QuestionsView";
import IndividualView from "./ViewComponents/IndividualView";
import ReportsView from "./ViewComponents/ReportsView";
import EmployeeAccessPage from "./ViewComponents/EmployeeAccess";

const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 25px;
  border-radius: 8px;
  width: 100%;
  background-color: ${colors.background.lightBlue};
`;

const InfoTopRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

const InfoBottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-top: 35px;
  flex-direction: row;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 50%;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 50%;
  justify-content: flex-end;
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const InfoCard = ({
  title,
  description,
  createdBy,
  createdDate,
  updatedDate,
}) => {
  return (
    <InfoCardContainer>
      <InfoTopRow>
        <Heading>
          <Text
            typographyStyle={typography.smallText.semibold}
            color={colors.primary[500]}
          >
            Name
          </Text>
          <Text
            typographyStyle={typography.subhead.semibold}
            color={colors.text.dark}
          >
            {title}
          </Text>
        </Heading>
      </InfoTopRow>
      <InfoBottomRow>
        <LeftSection>
          <Text
            typographyStyle={typography.smallText.semibold}
            color={colors.primary[500]}
          >
            Description
          </Text>
          <Text
            typographyStyle={typography.smallText.regular}
            color={colors.text.gray}
          >
            {description}
          </Text>
        </LeftSection>
        <RightSection>
          <RightContent>
            <Heading>
              <Text
                typographyStyle={typography.smallText.semibold}
                color={colors.primary[500]}
              >
                Created By
              </Text>
              <Text
                typographyStyle={typography.caption.semibold}
                color={colors.text.dark}
              >
                {createdBy}
              </Text>
            </Heading>
            <Heading>
              <Text
                typographyStyle={typography.smallText.semibold}
                color={colors.primary[500]}
              >
                Created Date
              </Text>
              <Text
                typographyStyle={typography.caption.semibold}
                color={colors.text.dark}
              >
                {createdDate}
              </Text>
            </Heading>
            <Heading>
              <Text
                typographyStyle={typography.smallText.semibold}
                color={colors.primary[500]}
              >
                Updated Date
              </Text>
              <Text
                typographyStyle={typography.caption.semibold}
                color={colors.text.dark}
              >
                {updatedDate}
              </Text>
            </Heading>
          </RightContent>
        </RightSection>
      </InfoBottomRow>
    </InfoCardContainer>
  );
};

const PulseDataViewContent = ({ surveyId }) => {
  const navigate = useNavigate();
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const {
    surveyInfo,
    fetchSurvey,
    surveyParticipants,
    responses,
    questions,
    downloadableReports,
    deleteEmployeeFromSurvey,
    addEmployeeToSurvey,
    surveyIsAnonymous,
    handleEmailChange,
    saveChangesAndAssignUsers,
    handleEmailUpdate,
    addEmployeeReportAccess,
    removeEmployeeReportAccess,
  } = useSurveyDataContext();

  useEffect(() => {
    if (surveyId) {
      fetchSurvey(surveyId);
    }
  }, []);

  const getTabs = (isAnon) => {
    if (isAnon) {
      return [
        {
          label: "Summary",
          content: (
            <SummaryView
              removeEmployee={deleteEmployeeFromSurvey}
              assignEmployees={saveChangesAndAssignUsers}
              assignees={surveyParticipants}
              id={surveyId}
              handleEmailChange={handleEmailUpdate}
            />
          ),
        },
        {
          label: "Questions",
          content: (
            <QuestionsView
              participants={surveyParticipants}
              responses={responses}
              questions={questions}
              handleUserSelect={(userId) => setSelectedParticipant(userId)}
            />
          ),
        },
        {
          label: "Access",
          content: (
            <EmployeeAccessPage
              removeEmployee={removeEmployeeReportAccess}
              addAccess={addEmployeeReportAccess}
            />
          ),
        },
      ];
    }

    return [
      {
        label: "Summary",
        content: (
          <SummaryView
            removeEmployee={deleteEmployeeFromSurvey}
            assignEmployees={addEmployeeToSurvey}
            assignees={surveyParticipants}
          />
        ),
      },
      {
        label: "Questions",
        content: (
          <QuestionsView
            participants={surveyParticipants}
            responses={responses}
            questions={questions}
            handleUserSelect={(userId) => setSelectedParticipant(userId)}
          />
        ),
      },
      {
        label: "Individual",
        content: (
          <IndividualView
            participants={surveyParticipants}
            responses={responses}
            questions={questions}
            selectedParticipant={selectedParticipant}
            clearUser={() => setSelectedParticipant(null)}
          />
        ),
      },
      // {
      //   label: "Reports",
      //   content: (
      //     <ReportsView
      //       reports={downloadableReports}
      //       id={surveyId}
      //       responses={responses}
      //     />
      //   ),
      // },
    ];
  };

  return (
    <BaseTabbedTemplate
      heading="AI Toolkit"
      pageTitle="Pulse AI Surveys"
      tabTrigger={selectedParticipant && 2} // 2 is the index of the "Individual" tab
      belowTabsSection={<InfoCard {...surveyInfo} />}
      tabs={getTabs(surveyIsAnonymous)}
      backAction={() => {
        navigate("/leader/audit/AI/pulse");
      }}
    />
  );
};

const PulseDataViewPage = ({ surveyId }) => {
  return (
    <SurveyDataProvider>
      <PulseDataViewContent surveyId={surveyId} />
    </SurveyDataProvider>
  );
};

PulseDataViewPage.propTypes = {
  surveyId: PropTypes.string.isRequired,
};

export default PulseDataViewPage;
