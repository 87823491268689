import React from "react";
import styled, { css } from "styled-components";
import colors from "styles/colors"; // Assuming you have a colors.js for your color palette
import typography from "styles/typography"; // Assuming you have typography.js

const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: ${typography.fontFamily};
  font-weight: ${typography.caption.semibold.fontWeight};
  font-size: ${typography.caption.semibold.fontSize};
  line-height: ${typography.caption.semibold.lineHeight};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  border: 1px solid transparent;

  ${({ variant }) =>
    variant === "primary" &&
    css`
      background-color: ${colors.primary[500]};
      color: ${colors.background.white};

      &:hover {
        background-color: ${colors.primary[600]};
      }

      &:disabled {
        background-color: ${colors.neutral[500]};
        color: ${colors.neutral[600]};
        cursor: not-allowed;
      }
    `}

  ${({ variant }) =>
    variant === "secondary" &&
    css`
      background-color: ${colors.primary[50]};
      color: ${colors.primary[500]};
      border: 1px solid ${colors.primary[500]};

      &:hover {
        background-color: ${colors.primary[100]};
      }

      &:disabled {
        background-color: ${colors.neutral[200]};
        color: ${colors.neutral[600]};
        cursor: not-allowed;
      }
    `}
`;

const Button = ({
  children,
  variant = "primary",
  disabled = false,
  ...props
}) => {
  return (
    <StyledButton variant={variant} disabled={disabled} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
