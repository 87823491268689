// SearchBar.js
import React from "react";
import styled from "styled-components";
import Input from "new_components/atoms/Input"; // Reuse the Input component
import Icon from "new_components/atoms/Icon"; // Reuse the Icon component
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors"; // Import colors.js

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.background.lightBlue};
  border-radius: 0.5rem; /* Larger rounded corners */
  padding: 0.1rem 0.75rem;
  gap: 0.5rem;
  transition: border-color 0.2s ease;
`;

const StyledInput = styled(Input)`
  &.no-focus {
    background-color: transparent;
    border: none;
    box-shadow: none;

    &::placeholder {
      color: ${colors.neutral[600]};
    }

    &:focus {
      outline: none;
    }
  }
`;

const IconButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
  color: ${colors.neutral[600]};
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: ${colors.neutral[200]};
    color: ${colors.text.dark};
  }
`;

const SearchBar = ({
  value,
  onChange,
  onClear,
  placeholder = "Search",
  ...props
}) => (
  <SearchBarWrapper>
    <StyledInput
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className="no-focus" // Pass the no-focus class
      {...props}
    />
    {!value && (
      <Icon name={ICON_SET.search} size={20} stroke={colors.text.gray} />
    )}

    {value && (
      <IconButton onClick={onClear} aria-label="Clear search">
        <Icon name={ICON_SET.clear} size={20} color={colors.neutral[600]} />
      </IconButton>
    )}
  </SearchBarWrapper>
);

export default SearchBar;
