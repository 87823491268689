import { css } from "styled-components";

export const applyTypography = (typographyStyle) => css`
  font-family: ${typography.fontFamily};
  font-size: ${typographyStyle.fontSize};
  font-weight: ${typographyStyle.fontWeight};
  line-height: ${typographyStyle.lineHeight};
  font-feature-settings: ${typographyStyle.fontFeatureSettings};
`;

const typography = {
  fontFamily: "'Raleway', sans-serif",
  fontFeatureSettings:
    "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
  display: {
    bold: {
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: "150%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "40px",
      lineHeight: "150%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  head: {
    bold: {
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: "128%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    semibold: {
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "128%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  subhead: {
    semibold: {
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: "128%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "128%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  body: {
    semibold: {
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "150%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "150%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  caption: {
    semibold: {
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "140%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "140%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  smallText: {
    semibold: {
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: "125%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "125%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
  promptText: {
    semibold: {
      fontWeight: 600,
      fontSize: "10px",
      lineHeight: "140%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
    regular: {
      fontWeight: 400,
      fontSize: "10px",
      lineHeight: "140%",
      fontFeatureSettings:
        "'pnum' on, 'lnum' on, 'ss05' on, 'ss07' on, 'ss08' on, 'salt' on, 'ss02' on, 'ss01' on, 'ss09' on, 'liga' off, 'kern' off",
    },
  },
};

export default typography;
