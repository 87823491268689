import React from "react";
import styled from "styled-components";

// Components
import Text from "new_components/atoms/Text";

// Constants
import colors from "styles/colors";
import typography from "styles/typography";

// Styled Components
const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.primary[50]};
  padding: 24px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const Title = styled(Text)`
  margin-bottom: 8px;
`;

const ItemLabel = styled(Text)`
  margin-bottom: 8px;
`;

const SurveyName = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;

// Header Component
const Header = ({ title, description }) => {
  return (
    <HeaderWrapper>
      <SurveyName>
        <ItemLabel
          typographyStyle={typography.smallText.semibold}
          color={colors.primary[500]}
        >
          Name
        </ItemLabel>
        <Title
          typographyStyle={typography.subhead.semibold}
          color={colors.text.dark}
        >
          {title}
        </Title>
      </SurveyName>

      <ItemLabel
        typographyStyle={typography.smallText.semibold}
        color={colors.primary[500]}
      >
        Description
      </ItemLabel>
      <Text
        typographyStyle={typography.smallText.semibold}
        color={colors.text.gray}
      >
        {description}
      </Text>
    </HeaderWrapper>
  );
};

export default Header;
