import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  SG_ADD_TASK_TEMPLATE,
  SG_CREATE_TASK_SCHEDULE,
  CLEAR_TASK_TEMPLATE,
  SG_FETCH_TASK_TEMPLATE,
  SG_UPDATE_TASK_TEMPLATE,
  SG_ADD_EMAIL_TEMPLATE,
  SG_ADD_USER_TASK,
} from "constants/actions";
import { useNavigate } from "react-router";
import { useToasts } from "react-toast-notifications";
import { v4 as uuidv4 } from "uuid";

// Create the context
const SurveyContext = createContext();

// Custom hook to use the context
export const useSurveyContext = () => useContext(SurveyContext);

// Provider component
export const SurveyProvider = ({
  children,
  initialFormData,
  surveyId,
  mode,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();

  // State management
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    feedbackTipsEnabled: false,
    questions:
      mode === "edit"
        ? []
        : [
            {
              id: uuidv4(),
              question: "",
              aiEnhanced: true,
              task_type: "question",
              type: { value: "text", label: "Text" },
            },
          ],
    assignedUsersList: [],
    user_tasks: [
      {
        task_type: "survey_is_anonymous",
        value: true,
      },
    ],
    emailSubject: "",
    emailBody: "",
    ...initialFormData, // Merge initialFormData
  });

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [error, setError] = useState(null);

  // Validation function
  const validateFormData = () => {
    const validationErrors = {};

    if (!formData.name.trim()) {
      validationErrors.surveyNameValidation = "Survey name cannot be empty";
    }

    if (!formData.description.trim()) {
      validationErrors.surveyDescriptionValidation =
        "Survey must have a description";
    }

    if (formData.questions.some((q) => !q.question.trim())) {
      validationErrors.surveyQuestionValidation = "Question cannot be empty";
    }

    // Check for email subject and body
    if (!formData.emailSubject.trim()) {
      validationErrors.emailSubjectValidation = "Email subject cannot be empty";
    }

    if (!formData.emailBody.trim()) {
      validationErrors.emailBodyValidation = "Email body cannot be empty";
    }

    if (Object.keys(validationErrors).length > 0) {
      addToast("Unable to save survey. Please check the form for errors", {
        appearance: "error",
      });
    }

    return validationErrors;
  };

  // Redux selectors
  const {
    get_auth,
    createdTemplate,
    createdScheduledTemplate,
    fetchedTemplate,
    updatedTemplate,
    createdEmailTemplate,
    assignedUserTasks,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      createdTemplate: state.task_templates?.created,
      createdScheduledTemplate: state.task_templates?.created_scheduled_task,
      fetchedTemplate: state.task_templates?.fetched_template,
      updatedTemplate: state.task_templates?.updated_template,
      createdEmailTemplate: state.email_templates?.created_email_template,
      updatedEmailTemplate: state.email_templates?.updated_email_template,
      assignedUserTasks: state.task_templates?.assigned_user_tasks,
    }),
    shallowEqual
  );

  // Fetch survey data when in edit mode
  useEffect(() => {
    if (mode === "edit" && surveyId) {
      dispatch({ type: SG_FETCH_TASK_TEMPLATE, payload: surveyId });
    }
  }, [mode, surveyId, dispatch]);

  // Update form data when fetchedTemplate changes
  useEffect(() => {
    if (fetchedTemplate && fetchedTemplate.id) {
      const assigneesList =
        fetchedTemplate.user_tasks.find(
          (task) => task.task_type === "assignee_draft"
        )?.assignees || [];

      const initialFormData = {
        name: fetchedTemplate.name || "",
        draftData: {
          name: fetchedTemplate.name || "",
          description:
            fetchedTemplate.user_tasks.find(
              (task) => task.task_type === "description"
            )?.text || "",
          feedbackTipsEnabled: fetchedTemplate.feedbackTipsEnabled || false,
          emailBody: fetchedTemplate.email_content || "",
          emailSubject: fetchedTemplate.email_subject || "",
          survey_is_anonymous: fetchedTemplate.user_tasks.find(
            (task) => task.task_type === "survey_is_anonymous"
          )?.value,
        },
        feedbackTipsEnabled: fetchedTemplate.feedbackTipsEnabled || false,
        questions: fetchedTemplate?.user_tasks
          ?.filter((task) => task.task_type === "question")
          ?.map((task) => ({
            id: task.id,
            question: task.question,
            aiEnhanced: task.aiEnhanced,
            task_type: "question",
          })),
        assignedUsersList: [],
        assignedUsersDraft: assigneesList.length > 0 ? [...assigneesList] : [],
        emailSubject:
          fetchedTemplate.user_tasks.find(
            (task) => task.task_type === "email_draft"
          )?.subject || "",
        emailBody:
          fetchedTemplate.user_tasks.find(
            (task) => task.task_type === "email_draft"
          )?.body || "",
        user_tasks: fetchedTemplate.user_tasks || [],
      };

      setFormData(initialFormData);
    }
  }, [fetchedTemplate]);

  // Update form data function
  const updateFormData = useCallback((updatedSection) => {
    setFormData((prev) => ({ ...prev, ...updatedSection }));
  }, []);

  const saveDraft = () => {
    setLoading(true);
    setError(null);

    const validationErrors = validateFormData();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      setLoading(false);
      return;
    }

    const { assignedUsersList, emailBody, emailSubject, ...restOfFormData } =
      formData;

    // Common objects
    const emailDraft = {
      task_type: "email_draft",
      subject: restOfFormData.emailSubject || emailSubject,
      body: restOfFormData.emailBody || emailBody,
    };

    const existingAssigneeDraft = restOfFormData.user_tasks.find(
      (task) => task.task_type === "assignee_draft"
    );

    // Concatenate the assignees list
    const assigneesList = [
      ...(existingAssigneeDraft?.assignees || []),
      ...assignedUsersList,
    ];

    const assigneeDraft = {
      task_type: "assignee_draft",
      assignees: assigneesList,
    };

    const surveyIsAnonymous = restOfFormData.user_tasks.find(
      (task) => task.task_type === "survey_is_anonymous"
    );

    if (mode === "edit") {
      // On edit, we must ensure the updated user_tasks array includes the assignee_draft
      const updatedUserTasks = (restOfFormData.user_tasks || [])
        // Remove any existing assignee_draft tasks
        .filter((task) => task.task_type !== "assignee_draft")
        // Add the new assignee_draft object
        .concat(assigneeDraft);

      const payloadData = {
        ...restOfFormData,
        id: surveyId,
        user_tasks: updatedUserTasks,
      };

      dispatch({
        type: SG_UPDATE_TASK_TEMPLATE,
        payload: payloadData,
      });
    } else {
      // Create scenario: Build "created_by"
      const userObj = {
        user_id: Number(get_auth?.employee_id),
        name: `${get_auth?.employee?.first_name} ${get_auth?.employee?.last_name}`,
        account_type: get_auth?.employee?.account_type,
        email: get_auth?.employee?.email,
        organization_id: get_auth?.employee?.organization_id,
        task_type: "created_by",
      };

      const baseUserTasks =
        restOfFormData?.user_tasks?.length > 0 ? restOfFormData.user_tasks : [];

      const payloadData = {
        ...restOfFormData, // assignedUsersList is no longer included
        user_tasks: [
          ...baseUserTasks,
          userObj,
          emailDraft,
          assigneeDraft,
          surveyIsAnonymous,
          ...restOfFormData?.questions.map((q) => ({
            ...q,
            task_type: "question",
            id: uuidv4(),
          })),
          { text: restOfFormData?.description, task_type: "description" },
        ],
      };

      dispatch({
        type: SG_ADD_TASK_TEMPLATE,
        payload: payloadData,
      });
    }

    addToast("Draft saved successfully", { appearance: "success" });
    navigate(`/leader/audit/AI/pulse`);
    setLoading(false);

    dispatch({ type: CLEAR_TASK_TEMPLATE });
  };

  // Handle submit function
  const handleSubmit = () => {
    setLoading(true);
    setError(null);

    const validationErrors = validateFormData();
    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      setLoading(false);
      return;
    }

    if (mode === "edit") {
      // Dispatch update action
      const payloadData = {
        ...formData,
        id: surveyId,
      };

      dispatch({
        type: SG_UPDATE_TASK_TEMPLATE,
        payload: payloadData,
      });
    } else {
      // Build the user object
      const userObj = {
        user_id: Number(get_auth?.employee_id),
        name: `${get_auth?.employee?.first_name} ${get_auth?.employee?.last_name}`,
        account_type: get_auth?.employee?.account_type,
        email: get_auth?.employee?.email,
        organization_id: get_auth?.employee?.organization_id,
        task_type: "created_by",
      };
      setLoadingMessage("Creating survey template...");

      // Prepare payload for survey creation
      const payloadData = {
        ...formData,
        user_tasks:
          formData.user_tasks?.length > 0
            ? [
                ...formData.user_tasks,
                userObj,
                ...formData?.questions.map((q) => ({
                  ...q,
                  task_type: "question",
                  id: uuidv4(),
                })),
                {
                  text: formData?.description,
                  task_type: "description",
                },
              ]
            : [
                userObj,
                ...formData?.questions.map((q) => ({
                  ...q,
                  task_type: "question",
                  id: uuidv4(),
                })),
                {
                  text: formData?.description,
                  task_type: "description",
                },
              ],
      };

      // Add a timeout to simulate clean loading state
      // Dispatch action to create the survey/template
      setTimeout(() => {
        dispatch({
          type: SG_ADD_TASK_TEMPLATE,
          payload: payloadData,
        });
      }, 500);
    }
  };

  // Effect to watch for survey creation
  useEffect(() => {
    if (createdTemplate?.id && mode !== "edit") {
      // Survey created, now schedule it
      const payloadObj = {
        task_template_user_tasks: createdTemplate.id,
        name: formData.name,
        assigned_employees: [],
      };

      setLoadingMessage("Sending survey to assignees list...");

      setTimeout(() => {
        dispatch({
          type: SG_CREATE_TASK_SCHEDULE,
          payload: payloadObj,
        });
      }, 500);
    }
  }, [createdTemplate, mode]);

  // Effect to watch for scheduling completion
  useEffect(() => {
    if (createdScheduledTemplate?.id && mode !== "edit") {
      // Create or update the email payload
      const emailPayload = {
        email_subject: formData.emailSubject,
        email_content: formData.emailBody,
        task_template: createdScheduledTemplate.id,
      };

      setLoadingMessage("Creating email template...");

      setTimeout(() => {
        dispatch({
          type: SG_ADD_EMAIL_TEMPLATE,
          payload: emailPayload,
        });
      }, 250);
    }
  }, [createdScheduledTemplate, mode]);

  // Effect to watch for email template creation
  useEffect(() => {
    if (createdEmailTemplate?.id && mode !== "edit") {
      // Assign users to the scheduled task
      const userTaskPayload = {
        task_id: createdScheduledTemplate?.id,
        employee_id_list: formData.assignedUsersList.map((user) => user.key),
      };

      setLoadingMessage("Assigning users to the survey...");

      dispatch({
        type: SG_ADD_USER_TASK,
        payload: userTaskPayload,
      });
    }
  }, [createdEmailTemplate, mode]);

  // Effect to watch for user task assignment
  useEffect(() => {
    if (
      assignedUserTasks.includes(fetchedTemplate?.id) ||
      assignedUserTasks?.includes(createdTemplate?.id)
    ) {
      setLoading(false);
      navigate(`/leader/audit/AI/pulse`);
      dispatch({ type: CLEAR_TASK_TEMPLATE });
      addToast("Survey created successfully", { appearance: "success" });
    }
  }, [assignedUserTasks]);

  // Effect to watch for survey update
  useEffect(() => {
    if (updatedTemplate?.id && mode === "edit") {
      setLoading(false);
      navigate(`/leader/audit/AI/pulse`);
      addToast("Survey updated successfully", { appearance: "success" });
    }
  }, [updatedTemplate, mode]);

  return (
    <SurveyContext.Provider
      value={{
        formData,
        updateFormData,
        handleSubmit,
        saveDraft,
        loading,
        loadingMessage,
        error,
      }}
    >
      {children}
    </SurveyContext.Provider>
  );
};