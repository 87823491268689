import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";

// Components
import TextInput from "new_components/molecules/TextInput";
import ToggleItem from "new_components/molecules/ToggleItem";
import ImageButton from "new_components/molecules/ImageButton";
import SelectBox from "new_components/molecules/SelectBox";
import Text from "new_components/atoms/Text"; // Import the Text component

// Constants
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import colors from "styles/colors";
import typography from "styles/typography";

const allowQuantitative = true;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: ${colors.background.lightBlue};
  padding: 16px;
  border-radius: 8px;
`;

const OptionsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
`;

const FieldRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
  margin-top: 20px;
`;

const QualifierInputWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Qualifier = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 25%;
`;

const TypeRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
`;

const Questions = ({ questions, editable, onUpdate, errors }) => {
  const handleQuestionChange = (index, field, value) => {
    if (!editable) return;
    const updatedQuestions = [...questions];
    // If the field is type, and its value is quantifier default the first option to "0-10"
    if (field === "type" && value === "scale") {
      updatedQuestions[index] = {
        ...updatedQuestions[index],
        [field]: value,
        scale: { value: "0-10", label: "0-10" },
      };
    } else {
      updatedQuestions[index] = { ...updatedQuestions[index], [field]: value };
    }
    onUpdate(updatedQuestions);
  };

  const handleQualifierChange = (index, position, value) => {
    if (!editable) return;
    const updatedQuestions = [...questions];
    const currentQualifiers = updatedQuestions[index].qualifier || [
      "Strongly Disagree",
      "Neutral",
      "Strongly Agree",
    ];
    const newQualifiers = [...currentQualifiers];
    // position: 0 for minimum, 1 for middle, 2 for maximum
    newQualifiers[position] = value;
    updatedQuestions[index].qualifier = newQualifiers;
    onUpdate(updatedQuestions);
  };

  const handleRemoveQuestion = (index) => {
    if (!editable) return;
    const updatedQuestions = questions.filter((_, i) => i !== index);
    onUpdate(updatedQuestions);
  };

  const handleAddQuestion = () => {
    if (!editable) return;
    const updatedQuestions = [
      ...questions,
      {
        id: uuidv4(),
        question: "",
        aiEnhanced: true, // default to true
        task_type: "question",
        type: { value: "text", label: "Text" }, // default to text
        scale: null,
        qualifier: null,
      },
    ];
    onUpdate(updatedQuestions);
  };

  return (
    <SectionWrapper>
      {questions.map((question, index) => (
        <QuestionWrapper key={question.id || index}>
          {/* Question Text */}
          <TextInput
            label={`Question #${index + 1}`}
            value={question.question}
            onChange={(e) => handleQuestionChange(index, "question", e)}
            placeholder="Enter your question"
            readOnly={!editable}
            error={errors?.surveyQuestionValidation && question.question === ""}
          />

          <TypeRow>
            {allowQuantitative && (
              <FieldRow>
                <SelectBox
                  width={"200px"}
                  label="Question Type"
                  value={question.type}
                  onSelect={(value) =>
                    handleQuestionChange(index, "type", value)
                  }
                  disabled={!editable}
                  options={[
                    { value: "text", label: "Text" },
                    { value: "scale", label: "Quantitative" },
                  ]}
                  placeholder="Select type"
                />
              </FieldRow>
            )}

            {question.type === "scale" && (
              <FieldRow>
                <SelectBox
                  label="Scale"
                  width={"200px"}
                  options={["0-10", "0-5"].map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  placeholder="Select scale"
                  value={question.scale}
                  onSelect={(value) =>
                    handleQuestionChange(index, "scale", value)
                  }
                  disabled={!editable}
                />
              </FieldRow>
            )}
          </TypeRow>

          {question.type === "scale" && (
            <FieldRow>
              <Text
                typographyStyle={typography.caption.semibold}
                color={colors.text.dark}
              >
                Qualifiers:
              </Text>
              <QualifierInputWrapper>
                <Qualifier>
                  <TextInput
                    placeholder="Strongly Disagree"
                    value={
                      question.qualifier
                        ? question.qualifier[0]
                        : "Strongly Disagree"
                    }
                    onChange={(e) =>
                      handleQualifierChange(index, 0, e.target.value)
                    }
                    label="Minimum"
                    disabled={!editable}
                  />
                </Qualifier>
                <Qualifier>
                  <TextInput
                    placeholder="Neutral"
                    value={
                      question.qualifier ? question.qualifier[1] : "Neutral"
                    }
                    onChange={(e) =>
                      handleQualifierChange(index, 1, e.target.value)
                    }
                    label="Middle"
                    disabled={!editable}
                  />
                </Qualifier>
                <Qualifier>
                  <TextInput
                    placeholder="Strongly Agree"
                    value={
                      question.qualifier
                        ? question.qualifier[2]
                        : "Strongly Agree"
                    }
                    onChange={(e) =>
                      handleQualifierChange(index, 2, e.target.value)
                    }
                    label="End"
                    disabled={!editable}
                  />
                </Qualifier>
              </QualifierInputWrapper>
            </FieldRow>
          )}

          <OptionsRow>
            {/* AI Enhanced Toggle */}
            <ToggleItem
              isActive={question.aiEnhanced}
              onToggle={(value) =>
                handleQuestionChange(index, "aiEnhanced", value)
              }
              title="AI Enhanced"
              disabled={!editable}
            />
            {editable && (
              <ImageButton
                text="Remove question"
                icon={ICON_SET.delete}
                iconPosition="left"
                textStyle={true}
                color={colors.auxiliary.red[500]}
                onClick={() => handleRemoveQuestion(index)}
              />
            )}
          </OptionsRow>
        </QuestionWrapper>
      ))}

      {editable && (
        <ImageButton
          text="Add question"
          icon={ICON_SET.add}
          iconPosition="left"
          textStyle={true}
          onClick={handleAddQuestion}
          color={colors.primary[500]}
        />
      )}
    </SectionWrapper>
  );
};

Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      aiEnhanced: PropTypes.bool.isRequired,
      task_type: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["text", "scale"]),
      scale: PropTypes.oneOf(["0-10", "0-5"]),
      qualifier: PropTypes.arrayOf(PropTypes.string),
    })
  ).isRequired, // The current list of questions
  editable: PropTypes.bool, // Whether the component is editable
  onUpdate: PropTypes.func, // Callback to update the questions
};

Questions.defaultProps = {
  editable: true, // Default to editable
  onUpdate: () => {}, // Default no-op function
};

export default Questions;
