import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// Components
import Text from "new_components/atoms/Text";
import Table from "new_components/organisms/Table";
import Icon from "new_components/atoms/Icon";
import SectionHeader from "./SectionHeader";
import ReportFilters from "./ReportFilters";

// Constants
import colors from "styles/colors";
import { ICON_SET } from "new_components/atoms/Icon/IconSet";
import typography from "styles/typography";

const SectionWrapper = styled.section`
  margin-bottom: 64px; /* Space between sections */
  scroll-margin-top: 72px; /* Offset for sticky submenu */
`;

// Container should not extend the full width of the screen
const StatusContainer = styled.div`
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${({ background }) => background};
  display: inline-block;
`;

// Ensure the column takes up at least 70% of the width
const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  min-width: 70%;
`;

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 14px;
  flex-direction: row;
`;

const TableColumns = [
  {
    key: "name",
    label: "Name",
    isSortable: true,
    sortFunction: (a, b) => a.name.localeCompare(b.name),
  },
  {
    key: "status",
    label: "Status",
    isSortable: true,
    sortFunction: (a, b) => a.status.localeCompare(b.status),
  },
  {
    key: "actions",
    label: "Actions",
    isSortable: false,
  },
];

const STATUS_MAP = {
  21: {
    text: colors.auxiliary.yellow[500],
    background: colors.auxiliary.yellow[50],
  },
  51: {
    text: colors.secondary[500],
    background: colors.secondary[50],
  },
};

const Status = ({ statusText, statusId }) => {
  // capitalize the first letter
  let capText = statusText
    ? statusText.charAt(0).toUpperCase() + statusText.slice(1)
    : "Pending";
  let statusCode = statusId ? statusId : 21;
  return (
    <StatusContainer background={STATUS_MAP[statusCode]?.background}>
      <Text
        typographyStyle={typography.smallText.semibold}
        color={STATUS_MAP[statusCode]?.text}
      >
        {capText}
      </Text>
    </StatusContainer>
  );
};

const formatDate = (date) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(date).toLocaleDateString(undefined, options);
};

const getTableData = (reports, navigate) => {
  return reports?.map((report, index) => {
    return {
      name: (
        <NameContainer>
          <Text typographyStyle={typography.smallText.semibold}>
            Survey report generated {formatDate(report?.created_at)}
          </Text>
        </NameContainer>
      ),
      status: (
        <Status statusText={report?.status_text} statusId={report?.status} />
      ),
      actions: (
        <IconContainer
          onClick={() =>
            navigate(`/leader/audit/manage/task/report/${report?.id}/${index}`)
          }
        >
          <Icon name={ICON_SET.chevronRight} size={24} />
        </IconContainer>
      ),
    };
  });
};

const ReportsView = ({ reports, id, responses }) => {
  let navigate = useNavigate();

  return (
    <SectionWrapper>
      <TopSection>
        <SectionHeader icon={ICON_SET.file} text="Reports" />
        <ReportFilters id={id} responses={responses} />
      </TopSection>
      <Table columns={TableColumns} data={getTableData(reports, navigate)} />
    </SectionWrapper>
  );
};

ReportsView.propTypes = {
  /** Pre-existing assignees array (optional) */
  assignees: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
  /**
   * Callback to handle the final list of assignees
   * (e.g. store in parent or do something else with it)
   */
  addAssignees: PropTypes.func.isRequired,
  /**
   * Full list of user options for the SearchDropdown
   * Format: [{key, value, text}, ...]
   */
  userOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    })
  ),
};

export default ReportsView;
