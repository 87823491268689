// LandingPage.js
import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";

// Components
import FullPageContent from "new_components/templates/FullContentTemplate";
import SearchBar from "new_components/molecules/SearchBar";
import Button from "new_components/atoms/Button";
import Table from "new_components/organisms/Table";
import Pagination from "new_components/molecules/Pagination";
import DeleteModal from "new_components/organisms/DeleteModal";

// Context
import {
  usePulseSurveys,
  PulseSurveysProvider,
} from "./Data/LandingPageContext";

// Styles
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBarContainer = styled.div`
  width: 55%;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const ITEMS_PER_PAGE = 9;

const LandingPageContent = () => {
  const navigate = useNavigate();
  const {
    surveyData,
    searchValue,
    handleSearch,
    columns,
    isDeleteModalOpen,
    handleDelete,
    setDeleteModalOpen,
    filteredProperties,
  } = usePulseSurveys();

  // -----------------------------
  // PAGINATION SETUP
  // -----------------------------
  const [currentPage, setCurrentPage] = useState(1);

  // If we assume 10 items per page:
  const totalPages = Math.ceil(surveyData.length / ITEMS_PER_PAGE);

  // Slice the surveyData for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedData = surveyData.slice(startIndex, endIndex);

  // Whenever surveyData changes or you search, reset page if needed
  React.useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1); // or setCurrentPage(totalPages);
    }
  }, [surveyData, currentPage, totalPages]);

  if (!handleDelete) return null;

  return (
    <FullPageContent
      pagination={
        <Pagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={setCurrentPage}
        />
      }
      heading="AI Toolkit"
      pageTitle="Pulse AI Surveys"
    >
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
        itemName="this Pulse Survey"
      />
      <ActionContainer>
        <SearchBarContainer>
          <SearchBar
            placeholderText="Search"
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
            onClear={() => handleSearch("")}
          />
        </SearchBarContainer>
        <Button
          onClick={() => navigate("/leader/audit/AI/pulse/survey-selection")}
        >
          Create new
        </Button>
      </ActionContainer>
      <TableContainer>
        <Table
          columns={columns}
          data={paginatedData}
          filteredProperties={filteredProperties}
          onHoverRow
        />
      </TableContainer>
    </FullPageContent>
  );
};

const LandingPage = () => (
  <PulseSurveysProvider>
    <LandingPageContent />
  </PulseSurveysProvider>
);

export default LandingPage;
